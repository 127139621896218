<div class="transactions-page-summary">
  <mat-toolbar color="primary"> </mat-toolbar>
  <div class="wrapper">
    <mat-card>
      <div class="flex-row">
        <ng-content></ng-content>
      </div>
    </mat-card>
  </div>
</div>
