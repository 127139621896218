import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MonthSelectorComponent } from '../navigation/month-selector/month-selector.component';
import { TransactionListComponent } from '../transactions/transaction-list/transaction-list.component';
import { SplitTransactionComponent } from '../transactions/split-transaction/split-transaction.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { BucketProgressComponent } from './bucket-progress/bucket-progress.component';
import { LabelTypeaheadComponent } from '../labels/label-typeahead/label-typeahead.component';
import { AddTransactionComponent } from '../transactions/add-transaction/add-transaction.component';
import { TransferBudgetComponent } from '../bucket/transfer-budget/transfer-budget.component';
import { DataTokenComponent } from './data-token/data-token.component';
import { MinDateDirective } from './forms/min-date.directive';
import { EditTransactionsComponent } from '../transactions/edit-transactions/edit-transactions.component';
import { SelectedTransactionToolbarComponent } from '../transactions/selected-transaction-toolbar/selected-transaction-toolbar.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UpgradeCardComponent } from './upgrade-card/upgrade-card.component';
import { BalanceAmountComponent } from './balance-amount/balance-amount.component';
import { SelectBucketTypeaheadComponent } from './select-bucket-typeahead/select-bucket-typeahead.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { TransferBudgetBalanceOverlayComponent } from './transfer-budget-balance-overlay/transfer-budget-balance-overlay.component';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AutofocusDirective } from '../directives/autofocus.directive';
import { BucketSelectorComponent } from './bucket-selector/bucket-selector.component';
import { FuzzyPipe } from '../pipes';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { ChartLegendComponent } from './chart-legend/chart-legend.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    ScrollingModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
  declarations: [
    LabelTypeaheadComponent,
    EditTransactionsComponent,
    MonthSelectorComponent,
    TransactionListComponent,
    SplitTransactionComponent,
    PasswordInputComponent,
    BucketProgressComponent,
    AddTransactionComponent,
    TransferBudgetComponent,
    DataTokenComponent,
    MinDateDirective,
    SelectedTransactionToolbarComponent,
    UpgradeCardComponent,
    BalanceAmountComponent,
    SelectBucketTypeaheadComponent,
    DocumentationComponent,
    TransferBudgetBalanceOverlayComponent,
    ClickOutsideDirective,
    AutofocusDirective,
    BucketSelectorComponent,
    FuzzyPipe,
    DoughnutChartComponent,
    ChartLegendComponent,
  ],
  exports: [
    EditTransactionsComponent,
    FormsModule,
    MonthSelectorComponent,
    RouterModule,
    TransactionListComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    SplitTransactionComponent,
    PasswordInputComponent,
    BucketProgressComponent,
    LabelTypeaheadComponent,
    AddTransactionComponent,
    TransferBudgetComponent,
    DataTokenComponent,
    MinDateDirective,
    SelectedTransactionToolbarComponent,
    ScrollingModule,
    UpgradeCardComponent,
    BalanceAmountComponent,
    SelectBucketTypeaheadComponent,
    DocumentationComponent,
    TransferBudgetBalanceOverlayComponent,
    ClickOutsideDirective,
    DragDropModule,
    AutofocusDirective,
    BucketSelectorComponent,
    DoughnutChartComponent,
  ],
})
export class SharedModule {}
