import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy, OnInit, OnChanges } from '@angular/core';
import { CategoryPage } from '../../types';
import { DrawerService } from '../../services/drawer.service';
import { MonthService } from '../../services/month.service';
import { ChartService } from '../../services/chart.service';
import { SessionService } from '../../services/session.service';
import { SwUpdate } from '@angular/service-worker';
import { CategoryService } from 'src/app/services/category.service';
import { ChartDataInput } from 'src/app/shared/doughnut-chart/doughnut-chart.component';

@Component({
  selector: 'app-categories-page-summary',
  templateUrl: './categories-page-summary.component.html',
  styleUrls: ['./categories-page-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CategoriesPageSummaryComponent implements OnInit, OnChanges {
  @Input() categoryPage: CategoryPage;

  isSearching: boolean;

  categoryChartData: ChartDataInput[];

  get spent(): number {
    return this.round(this.categoryPage.balance.BUDGET * -1);
  }

  private round(num: number): number {
    return Math.round(num * 100) / 100;
  }

  get overBudget(): number {
    return this.round(this.categoryPage.budget - this.categoryPage.budgeted.INCOME);
  }

  constructor(
    public categoryService: CategoryService,
    public drawerService: DrawerService,
    public month: MonthService,
    private chartService: ChartService,
    public sessionService: SessionService,
    public update: SwUpdate,
  ) {}

  ngOnInit() {
    this.updateChartData();
  }

  ngOnChanges() {
    this.updateChartData();
  }

  updateChartData() {
    const data = (this.categoryChartData = this.chartService.categoryChartExpected(this.categoryPage));
  }

  openChartDrawer() {
    this.drawerService.openCategoriesPieChartDrawer();
  }

  async updateApp() {
    await this.update.activateUpdate();
    location.reload();
  }
}
