import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { TransactionService } from '../../services/transaction.service';
import { BucketService } from '../../services/bucket.service';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { TransactionFilters } from '../../types';
import { NavigationService } from '../../services/navigation.service';
import { switchMap, distinctUntilChanged, startWith, filter, take } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { TransactionDataSource } from '../transaction-data-source.class';
import isEqual from 'lodash.isequal';
import { AccountService } from 'src/app/services/account.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SessionService } from 'src/app/services/session.service';
import { emptyTransactionFilter } from '../transaction-list-toolbar/transaction-list-toolbar.component';

@Component({
  selector: 'app-all-transactions-view',
  templateUrl: './all-transactions-view.component.html',
  styleUrls: ['./all-transactions-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AllTransactionsViewComponent implements OnInit, OnDestroy {
  loadTransactionsSub: Subscription;
  _filter: BehaviorSubject<TransactionFilters> = new BehaviorSubject({
    labelIds: [],
    bucketIds: [],
    unbucketedOnly: false,
    deletedOnly: false,
  });
  filter$: Observable<TransactionFilters> = this._filter.asObservable();

  transactionDataSource: TransactionDataSource;

  constructor(
    public accountService: AccountService,
    public bucketService: BucketService,
    private nav: NavigationService,
    private transactionService: TransactionService,
    private sessionService: SessionService,
    public user: UserService,
  ) {
    this.bucketService.loadAllBuckets();
  }

  ngOnInit() {
    this.nav.title = 'transactions';

    this.loadTransactionsSub = this.filter$
      .pipe(
        switchMap(tFilter => this.transactionService.fetchTransactions$(tFilter)),
        startWith(this.transactionService.cachedTransactions()),
        filter(t => !!t),
        distinctUntilChanged((a, b) => isEqual(a, b)),
      )
      .subscribe(transactionPage => {
        this.transactionDataSource = new TransactionDataSource(
          transactionPage,
          this.transactionService,
          this.bucketService,
        );
      });
  }

  ngOnDestroy() {
    this.loadTransactionsSub.unsubscribe();
  }
}
