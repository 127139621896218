<div class="transaction-list-toolbar">
  <mat-toolbar>
    <div class="flex-row">
      <ng-container *ngIf="!searching">
        <ng-container *ngIf="transactionDataSource.unsortedTransactions$ | async; let unsorted">
          <mat-chip
            [color]="unsorted.length ? 'accent' : 'primary'"
            [selected]="(filters$ | async)?.unbucketedOnly || !unsorted.length"
            (click)="toggleUnsortedFilter()"
          >
            <ng-container *ngIf="unsorted.length">
              Sort {{ unsorted.length > 98 ? '99+' : unsorted.length }}
            </ng-container>
            <ng-container *ngIf="!unsorted.length"> All Sorted <mat-icon>check</mat-icon></ng-container>
          </mat-chip>
        </ng-container>

        <mat-chip color="accent" (click)="clear()" [selected]="!(hasFilters$ | async)">View all</mat-chip>
        <div class="spacer"></div>
      </ng-container>
      <div *ngIf="searching" class="transaction-list-toolbar-search">
        <mat-form-field class="no-padding" appearance="outline" hideRequiredMarker="true">
          <input
            matInput
            name="search"
            type="text"
            placeholder="Search by Merchant"
            [ngModel]="merchant"
            (ngModelChange)="merchantChanged($event)"
            autocomplete="off"
            [autofocus]
          />
          <span class="prefix" matPrefix><mat-icon>search</mat-icon></span>

          <button mat-icon-button (click)="closeSearch()" class="suffix" matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="transaction-list-toolbar-actions">
        <button *ngIf="!searching" mat-icon-button type="button" (click)="searching = true">
          <mat-icon>search</mat-icon>
        </button>

        <button
          mat-icon-button
          type="button"
          [color]="(hasFilters$ | async) ? 'accent' : false"
          (click)="togglePanel()"
        >
          <mat-icon>filter_list</mat-icon>
        </button>

        <button mat-icon-button [matMenuTriggerFor]="transactionListToolbarMenu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #transactionListToolbarMenu="matMenu">
          <button mat-menu-item (click)="exportTransactions()">
            <span>
              Export Transactions
            </span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
  <mat-expansion-panel class="transaction-list-toolbar-filters">
    <app-transaction-list-filter
      [transactionDataSource]="transactionDataSource"
      [filters$]="filters$"
      (doneFiltering)="closePanel()"
      (selectedLabelIdsChange)="updateLabelIds($event)"
      (selectedBucketIdsChange)="updateBucketIds($event)"
      (unbucketedOnlyChange)="toggleUnsortedFilter()"
      (deletedOnlyChange)="toggleDeletedFilter()"
      (clear)="clear()"
      (focus)="closePanel()"
    ></app-transaction-list-filter>
  </mat-expansion-panel>
</div>
