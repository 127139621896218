import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendService } from '../../services/backend.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { ResetAccountDialogComponent } from '../reset-account-dialog/reset-account-dialog.component';
import { AccountSettingsService } from '../../services/account-settings.service';

@Component({
  selector: 'app-reset-balances-dialog',
  templateUrl: './reset-balances-dialog.component.html',
  styleUrls: ['./reset-balances-dialog.component.scss'],
})
export class ResetBalancesDialogComponent implements OnInit {
  password = null;
  badPassword = false;
  loading = false;
  syncStartDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ResetBalancesDialogComponent>,
    public backend: BackendService,
    public userService: UserService,
    private router: Router,
    private accountSettingsService: AccountSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const now = new Date();
    this.syncStartDate = new Date(now.getFullYear(), now.getMonth(), 1);
  }

  resetAccount(password) {
    this.loading = true;
    this.accountSettingsService.resetBalances$(password).subscribe(
      res =>
        this.userService.refreshSession$.subscribe(() => {
          this.router.navigate(['/']);
          this.dialogRef.close();
        }),
      err => {
        console.log(err);
        this.loading = false;
        this.badPassword = err.error.errors[0] === 'Incorrect password';
      },
    );
  }

  openResetAccountDialog() {
    console.log('this.data', this.data);
    this.dialogRef.close();

    this.data.openResetAccountDialog();
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
