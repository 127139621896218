import { Injectable, NgZone } from '@angular/core';

import { Observable } from 'rxjs';
import { User } from '../types';
import { BackendService } from './backend.service';
import { AnalyticsService } from './analytics.service';
import { NavigationService } from './navigation.service';
import { tap, map, filter, switchMap } from 'rxjs/operators';
import { UserSession } from '../types/session';
import { SessionService } from './session.service';
import { EVENT_LOGOUT } from '../analytics/events';

@Injectable({ providedIn: 'root' })
export class UserService {
  getSessionRequest$: Observable<UserSession> = this.backend.request({
    type: 'get',
    apiRoute: 'session',
    authed: false,
  });

  logoutRequest$ = this.backend
    .request({
      type: 'get',
      apiRoute: 'logout',
    })
    .pipe(
      tap((response: any) => {
        if (response.success) {
          console.log('clearing session because logout triggered');
          this.analytics.sendEvent(EVENT_LOGOUT);
          this.clearSession();
        }
      }),
    );

  refreshSession$: Observable<UserSession> = this.getSessionRequest$.pipe(
    tap(session => this.sessionService.updateSession(session)),
  );

  completedTutorial$ = this.backend.request({
    type: 'update',
    apiRoute: 'user/tutorial',
    data: {
      completed: true,
    },
  });

  user$ = this.sessionService.session$.pipe(map(session => (session ? session.user : null)));

  userIsUpgraded$ = this.user$.pipe(
    filter(user => !!user),
    map(user => user.serviceLevel === 'PAID'),
  );

  constructor(
    private analytics: AnalyticsService,
    private backend: BackendService,
    private nav: NavigationService,
    private zone: NgZone,
    private sessionService: SessionService,
  ) {}

  updateUserRequest$: (update: Partial<User>) => Observable<User> = update =>
    this.backend.request({
      type: 'update',
      apiRoute: 'user',
      data: { ...this.sessionService.storedSession.user, ...update },
    });

  updateUserEmailRequest$: (email: string, password: string) => Observable<User> = (email, password) =>
    this.backend.request({
      type: 'update',
      apiRoute: 'user/email',
      data: { email, password },
    });

  changeEmail(email: string, password: string): Observable<User> {
    return this.updateUserEmailRequest$(email, password).pipe(tap(user => this.setUser(user)));
  }

  setUser(user: User) {
    this.sessionService.getOneSession$.subscribe(session => {
      session.user = user;
      this.sessionService.updateSession(session);
    });
  }

  clearSession() {
    console.log('clearing session');
    this.sessionService.clearSession();
    this.zone.run(() => this.nav.navigateTo(['/login']));
  }

  endImpersonation() {
    this.sessionService.getOneSession$.subscribe(oldSession => {
      const wasImpersonatingId = oldSession.account.id;

      this.backend
        .request({
          type: 'post',
          apiRoute: 'admin/impersonate/end',
          data: {},
        })
        .subscribe((session: UserSession) => {
          this.sessionService.updateSession(session);
          this.nav.goHome();
          return this.nav.navigateTo(['admin', 'account', `${wasImpersonatingId}`]);
        });
    });
  }
}
