import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BucketService } from '../../services/bucket.service';
import { map } from 'rxjs/operators';
import { Bucket } from '../../types';
import { BackendService } from '../../services/backend.service';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_TRANSFERRED_BUDGET_IN } from '../../analytics/events';
const UNBUDGETED_BUCKET_ID = '000000000000000000000000';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-transfer-budget',
  templateUrl: './transfer-budget.component.html',
  styleUrls: ['./transfer-budget.component.scss'],
})
export class TransferBudgetComponent implements OnInit {
  @Input() bucket: Bucket;
  @Input() options: { initialAmount?: number; hideTransferIn?: boolean; hideTransferOut?: boolean } = {};
  @Output() transferSuccess = new EventEmitter();

  loading = false;
  transfer: { bucketId?: string; amount?: number } = {};
  selectedBucket: Bucket;

  availableBuckets$ = this.bucketService.allCurrentMonthBuckets$.pipe(
    map(buckets => buckets.filter(bucket => (this.bucket ? bucket.id !== this.bucket.id : true))),
  );

  transferInRequest$ = (transferBucketId, amount) =>
    this.backend.request({
      type: 'post',
      apiRoute: 'fundTransfers',
      data: {
        transfers: [
          {
            fromBucketId: transferBucketId,
            toBucketId: this.bucket ? this.bucket.id : UNBUDGETED_BUCKET_ID,
            amount,
          },
        ],
      },
    });

  transferOutRequest$ = (transferBucketId, amount) =>
    this.backend.request({
      type: 'post',
      apiRoute: 'fundTransfers',
      data: {
        transfers: [
          {
            fromBucketId: this.bucket ? this.bucket.id : UNBUDGETED_BUCKET_ID,
            toBucketId: transferBucketId,
            amount,
          },
        ],
      },
    });

  constructor(
    private analytics: AnalyticsService,
    private bucketService: BucketService,
    private backend: BackendService,
  ) {}

  ngOnInit() {
    this.transfer.amount = this.options.initialAmount;
  }

  get maxTransfer$() {
    return this.availableBuckets$.pipe(
      map(buckets => {
        const selected = buckets.find(bucket => bucket.id === this.transfer.bucketId);
        if (selected && selected.fund) return selected.fund.balance;
        return 0;
      }),
    );
  }

  transferBudget(direction: string, form: NgForm) {
    if (!this.selectedBucket || !form.value.amount) return;
    this.loading = true;
    const transferRequest$ = direction === 'in' ? this.transferInRequest$ : this.transferOutRequest$;
    transferRequest$(this.selectedBucket.id, form.value.amount).subscribe(response => {
      this.selectedBucket = undefined;
      form.resetForm();
      this.loading = false;
      this.transferSuccess.emit(response);
      this.analytics.sendEvent(EVENT_TRANSFERRED_BUDGET_IN);
    });
  }
}
