import { Component, Input, OnInit } from '@angular/core';

export interface ChartLegendItem {
  label: string;
  color: string;
}

@Component({
  selector: 'chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent implements OnInit {
  constructor() {}
  @Input() items: ChartLegendItem[] = [];
  @Input() selectedIndex: number | null = null;
  ngOnInit(): void {}
  ngOnUpdate() {
    console.log('legend selectedIndex', this.selectedIndex);
  }
  toggleDataset(item) {}
}
