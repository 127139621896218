import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddTransactionComponent } from './add-transaction/add-transaction.component';
import { SortTransactionsPageComponent } from './sort-transactions-page/sort-transactions-page.component';
import { UpgradeGuard } from '../routing/upgrade.guard';
import { AllTransactionsViewComponent } from './all-transactions-view/all-transactions-view.component';

const routes: Routes = [
  {
    path: '',
    component: AllTransactionsViewComponent,
    pathMatch: 'full',
    data: {
      goBackTo: '/budgets',
    },
  },
  {
    path: 'add',
    component: AddTransactionComponent,
    data: {
      goBackTo: false,
    },
  },
  {
    path: 'sort',
    component: SortTransactionsPageComponent,
    canActivate: [UpgradeGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionsRoutingModule {}
