import { Action, Transaction, Bucket } from '../../types';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransactionList } from '../transaction-list.class';
import { ConfirmDialogComponent } from '../../dialog-templates/confirm-dialog/confirm-dialog.component';
import { TransactionService } from '../../services/transaction.service';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionListComponent implements OnInit, OnDestroy {
  @Input() buckets: Bucket[];

  @Input() transactionList: TransactionList;
  @Input() type: string;
  @Output() transactionListEvent = new EventEmitter<Action>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  transactionSub: Subscription;
  transactionsDataSource: MatTableDataSource<Transaction> = new MatTableDataSource<Transaction>();

  constructor(private dialog: MatDialog, private transactionService: TransactionService) {}

  toggleSelection(transaction) {
    this.transactionList.toggleSelection(transaction);
  }

  ngOnInit() {
    this.transactionSub = this.transactionList.transactionsPage$
      .pipe(map(({ data }) => data))
      .subscribe(transactions => (this.transactionsDataSource.data = transactions || []));

    this.transactionsDataSource.paginator = this.paginator;
  }

  handlePageEvent(event: PageEvent) {
    this.transactionListEvent.emit({ type: 'PAGE-EVENT', value: event });
  }

  isExpanded(row) {
    return this.transactionList.transactionsPage$.pipe(map((page: any) => row && page.expanded === row.id));
  }

  isIncome(row) {
    if (!row) return false;
    return row.netValue > 0;
  }

  isTransaction(row) {
    if (!row) return false;
    return row._objectType === 'transaction';
  }

  isTransfer(row) {
    if (!row) return false;
    return row._objectType === 'bucketFundTransfer';
  }

  whyLocked(reason: string) {
    const reasons = {
      ARCHIVED_BUCKET: 'This transaction is locked, because it is in an archived budget.',
    };
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: reasons[reason],
        confirmColor: 'primary',
        onConfirm: () => {},
      },
    });
  }

  openDeleteTransferModal(transfer) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this transfer?',
        confirmColor: 'primary',
        onConfirm: () => this.deleteTransfer(transfer),
      },
    });
  }

  deleteTransfer(transfer) {
    this.transactionListEvent.emit({ type: 'DELETE-TRANSFERS', value: [transfer] });
  }

  ngOnDestroy() {
    this.transactionSub.unsubscribe();
    this.transactionListEvent.emit({ type: 'TABLE-DESTROYED' });
  }
}
