import { Component, OnInit, Input } from '@angular/core';
import { Bucket, Transaction } from '../../types';
import { FormControl } from '@angular/forms';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { startWith, map, shareReplay } from 'rxjs/operators';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_SORTED_TRANSACTION_FORM, EVENT_SORTED_TRANSACTION_SUGGESTION } from '../../analytics/events';
import { TransactionDataSource } from '../transaction-data-source.class';
import { byCount } from 'src/util/helpers';

@Component({
  selector: 'app-sort-transactions-form',
  templateUrl: './sort-transactions-form.component.html',
  styleUrls: ['./sort-transactions-form.component.scss'],
})
export class SortTransactionsFormComponent implements OnInit {
  @Input() sorting: boolean;
  @Input() transactionDataSource: TransactionDataSource;
  @Input() buckets: Bucket[];

  descriptionControl: FormControl = new FormControl();
  selectedBucket: Bucket;
  selectedLabelIds: string[] = [];
  selectedTransactions: Transaction[] = [];
  _bucketsChanged: Subject<number> = new BehaviorSubject(1);
  selectedSub: Subscription;
  constructor(private analytics: AnalyticsService) {}

  ngOnInit() {
    this.setupSelectedTransactionsSubscription();
  }

  ngOnChanges() {
    this.setupSelectedTransactionsSubscription();
    this._bucketsChanged.next(1);
  }

  setupSelectedTransactionsSubscription() {
    if (this.selectedSub) this.selectedSub.unsubscribe();
    this.selectedSub = this.transactionDataSource.selectedTransactions$.subscribe(
      selected => (this.selectedTransactions = selected),
    );
  }

  getBucketsFromIds(ids) {
    return ids.map(id => this.buckets.find(bucket => bucket.id === id));
  }

  get selectedLength() {
    return this.selectedTransactions.length;
  }

  get suggestedBuckets() {
    if (this.selectedLength < 1) return null;
    if (this.selectedLength === 1 && this.selectedTransactions[0].suggestedBucketIds)
      return this.getBucketsFromIds(this.selectedTransactions[0].suggestedBucketIds).slice(0, 3);
    const suggestions = this.selectedTransactions.reduce((acc, transaction) => {
      if (!transaction.suggestedBucketIds) return acc;
      return [...acc, ...transaction.suggestedBucketIds];
    }, []);
    return this.getBucketsFromIds(byCount(suggestions)).slice(0, 3);
  }

  sortTransactionsFormSubmit() {
    this.analytics.sendEvent(EVENT_SORTED_TRANSACTION_FORM);
    this.updateManyTransactions(this.selectedBucket.id, this.descriptionControl.value, this.selectedLabelIds);
    this.resetForm();
  }

  private resetForm() {
    this.selectedBucket = undefined;
    this.descriptionControl.reset('');
    this.selectedLabelIds = [];
  }

  private updateManyTransactions(bucketId, description, labelIds) {
    const transactionIds = this.selectedTransactions.map(t => t.id);
    const value = {
      bucketId: bucketId,
      description: description,
      labelIds: labelIds,
      ids: transactionIds,
    };
    this.transactionDataSource.dispatch({ type: 'EDIT_MANY_TRANSACTIONS', value });
  }

  suggestionClicked(bucketId) {
    this.analytics.sendEvent(EVENT_SORTED_TRANSACTION_SUGGESTION);
    this.updateManyTransactions(bucketId, this.descriptionControl.value, this.selectedLabelIds);
    this.resetForm();
  }
}
