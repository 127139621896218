import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Observable, Subject, from } from 'rxjs';
import { CustomerInfo } from '../types/subscription';
import { map, tap, switchMap } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';
import { EVENT_UPDATED_PAYMENT_METHOD } from '../analytics/events';
import { UserService } from './user.service';
import { UserSession } from '../types/session';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  _customerInfo: Subject<CustomerInfo> = new Subject();
  customerInfo$ = from(this._customerInfo);
  paymentCard$ = this.customerInfo$.pipe(map(userInfo => userInfo.card));
  subscriptionInfo$ = this.customerInfo$.pipe(map(userInfo => userInfo.subscription));

  cancelSubscriptionRequest$ = cancelAtPeriodEnd =>
    this.backend.request({
      type: 'update',
      apiRoute: 'subscription/cancel',
      data: { cancelAtPeriodEnd },
    });

  getSubscriptionRequest$ = this.backend.request({
    type: 'get',
    apiRoute: 'subscription',
  });

  createSubscriptionRequest$ = (stripeToken: string) =>
    this.backend
      .request({
        type: 'post',
        apiRoute: 'subscription',
        data: { stripeToken },
      })
      .pipe(tap(this.updateSubscription.bind(this)));

  createSubscription$ = (stripeToken: string): Observable<UserSession> =>
    this.createSubscriptionRequest$(stripeToken).pipe(switchMap(() => this.user.refreshSession$));

  updateSubscriptionPaymentRequest$ = (stripeToken: string) =>
    this.backend
      .request({
        type: 'update',
        apiRoute: 'subscription/payment',
        data: { stripeToken },
      })
      .pipe(
        tap((customerInfo: CustomerInfo) => {
          this.updateSubscription(customerInfo);
          if (customerInfo.id) this.analytics.sendEvent(EVENT_UPDATED_PAYMENT_METHOD);
        }),
      );

  constructor(private analytics: AnalyticsService, private backend: BackendService, private user: UserService) {}

  getSubscription() {
    this.getSubscriptionRequest$.subscribe(this.updateSubscription.bind(this));
  }

  updateSubscription(customerInfo: CustomerInfo) {
    this._customerInfo.next(customerInfo);
  }
}
