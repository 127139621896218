<div [formGroup]="parent">
  <div>
    <div class="flex-row full-width">
      <mat-form-field class="password" floatLabel="auto" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" name="password" type="password" />
        <mat-error>
          {{ errors.password }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-row full-width">
      <mat-form-field floatLabel="auto" appearance="outline">
        <mat-label>Repeat Password</mat-label>
        <input
          matInput
          [errorStateMatcher]="confirmValidParentMatcher"
          formControlName="validatePassword"
          name="validatePassword"
          type="password"
        />
        <mat-error>
          {{ errors.validatePassword }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
