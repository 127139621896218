<h3 class="title">Reset your balances</h3>

<p>
  Resetting your balances allows you to have a fresh start with Budgets, without having to setup all your budgets,
  groups, and accounts again. We recommend this for users who may have gotten behind sorting transactions or who are
  returning to Budgets after some time away.
</p>
<p>
  Resetting your balances will <span class="bold">permanently</span> delete all of your transactions and transfers. Your
  authenticated financial accounts will remain, and will begin syncing transactions again starting
  {{ syncStartDate | date: 'MM/dd/yyyy' }}.
</p>

<p>
  If you wish to completely reset your account by deleting your budgets and groups, you can
  <span class="reset-link" (click)="openResetAccountDialog()">Reset Your Budgets Account</span>
</p>

<p><span class="warning">This action cannot be undone.</span></p>

<form #resetForm="ngForm" (ngSubmit)="resetAccount(password)">
  <p>Input your password to reset your balances.</p>
  <div class="password-input">
    <mat-form-field floatLabel="auto" appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput name="password" type="password" [(ngModel)]="password" required />
    </mat-form-field>
  </div>
  <div class="password-error">
    <mat-error *ngIf="badPassword">Incorrect password. Please try again.</mat-error>
  </div>
  <div class="dialog-actions">
    <button type="button" (click)="onCancel()" mat-button>Cancel</button>
    <button
      type="submit"
      mat-flat-button
      color="warn"
      [disabled]="!resetForm.form.dirty || !resetForm.form.valid || loading"
    >
      Reset Balances
    </button>
  </div>
</form>
