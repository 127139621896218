import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './routing/routing.module';
import { BucketsModule } from './buckets/buckets.module';
import { CategoriesModule } from './categories/categories.module';
import { DrawerModule } from './drawer/drawer.module';
import { NavigationModule } from './navigation/navigation.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { ConfirmDialogComponent } from './dialog-templates/confirm-dialog/confirm-dialog.component';
import { FormDialogComponent } from './dialog-templates/form-dialog/form-dialog.component';
import { PageTemplateComponent } from './page-template/page-template.component';
import { ConfigureAccountsDialogComponent } from './dialog-templates/configure-accounts-dialog/configure-accounts-dialog.component';
import { EditTransactionsDialogComponent } from './transactions/edit-transactions-dialog/edit-transactions-dialog.component';
import { ArchiveBucketDialogComponent } from './bucket/archive-bucket-dialog/archive-bucket-dialog.component';
import { ResetAccountDialogComponent } from './dialog-templates/reset-account-dialog/reset-account-dialog.component';
import { ResetBalancesDialogComponent } from './dialog-templates/reset-balances-dialog/reset-balances-dialog.component';
import { StripePaymentDialogComponent } from './dialog-templates/stripe-payment-dialog/stripe-payment-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    FormDialogComponent,
    PageTemplateComponent,
    ConfigureAccountsDialogComponent,
    EditTransactionsDialogComponent,
    ArchiveBucketDialogComponent,
    ResetAccountDialogComponent,
    ResetBalancesDialogComponent,
    StripePaymentDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BucketsModule,
    CategoriesModule,
    DrawerModule,
    HttpClientModule,
    NavigationModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.serviceWorker,
    }),
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // tslint:disable-next-line:no-console
    console.log('Environment: ', environment);
  }
}
