import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

type LocalStorageMetaOptions = {
  persist?: boolean;
  timestamp?: number;
};

@Injectable({ providedIn: 'root' })
export class DataService {
  constructor() {
    this.pruneLocalStorage();
  }
  setLocalStorage(location: string, data: any, meta: LocalStorageMetaOptions = {}) {
    if (!environment.localStorage) return;
    // console.log('setting local storage at: ', location, data);
    meta.timestamp = Date.now();
    this.pruneLocalStorage();
    return window.localStorage.setItem(location, JSON.stringify({ data, ...meta }));
  }
  getLocalStorage(location: string) {
    if (!environment.localStorage) return null;

    let item: any = window.localStorage.getItem(location);
    item = item && item !== '{}' ? JSON.parse(item) : null;
    // console.log('getting local storage at: ', location, JSON.parse(data));
    const maybeItem = item || {};
    if (maybeItem.data === null || maybeItem.data === undefined) return item; // needed for data that might be stored prior to adding the wrapper
    return item.data;
  }
  getLocalStorageByFilter(filter: Function) {
    if (!environment.localStorage) return null;
    const values = {};
    const keys = Object.keys(localStorage);
    let i = keys.length;
    while (i--) if (filter(keys[i])) values[keys[i]] = this.getLocalStorage(keys[i]);
    return values;
  }
  removeLocalStorage(location: string) {
    return window.localStorage.removeItem(location);
  }
  pruneLocalStorage() {
    // This strategy works best with shallow data
    if (window.localStorage.length < 300) return;
    // Extract all timestamps
    const items = [];
    for (var i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      const entry = JSON.parse(window.localStorage.getItem(key));
      if (!entry.timestamp) this.removeLocalStorage(key);
      if (!key.startsWith('_')) items.push({ key, timestamp: entry.timestamp });
    }
    // Sort newest first (we want to keep the first newest), filter out the items that should be persisted
    items.sort((a, b) => b.timestamp - a.timestamp).filter(item => !item.persist);
    // Remove oldest items greater than the last 50 items
    for (var i = 50; i < items.length; i++) this.removeLocalStorage(items[i].key);
  }
  setSessionStorage(location, data) {
    return window.sessionStorage.setItem(location, JSON.stringify(data));
  }
  getSessionStorage(location) {
    const data = window.sessionStorage.getItem(location);
    return data && data !== '{}' ? JSON.parse(data) : null;
  }
  removeSessionStorage(location) {
    return window.sessionStorage.removeItem(location);
  }
}
