<div *ngIf="finishedLoading; else loading">
  <h2 class="title">Archive {{ data.bucket.name }}?</h2>
  <div class="spacer"></div>

  <p class="info">
    This will make <span class="bucket-name">{{ data.bucket.name }}</span> un-editable and hide it starting next month.
  </p>

  <form
    #archiveForm="ngForm"
    class="archive-bucket-dialog"
    (ngSubmit)="archiveBucket(balanceTransferBucket)"
    autocomplete="off"
  >
    <div *ngIf="balance !== 0">
      <p>
        There is a balance of
        <span [ngClass]="{ good: balance > 0, bad: balance < 0 }">
          {{ balance | currency: "USD":"symbol":"1.2-2" }}</span
        >
        left in <span class="bucket-name">{{ data.bucket.name }}</span
        >. Select a budget to transfer this balance.
      </p>
      <mat-form-field>
        <mat-select
          required
          placeholder="Select Budget"
          [(ngModel)]="balanceTransferBucket"
          name="balanceTransferBucket"
        >
          <mat-option *ngFor="let bucket of availableBuckets$ | async" [value]="bucket.id">
            {{ bucket.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="dialog-actions">
      <button type="button" (click)="cancel()" mat-button>Cancel</button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="balance !== 0 && (!archiveForm.form.dirty || !archiveForm.form.valid)"
      >
        Archive
      </button>
    </div>
  </form>
</div>

<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>
