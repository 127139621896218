import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Bucket } from '../../types';

@Component({
  selector: 'app-category-bucket-summary-info',
  templateUrl: './category-bucket-summary-info.component.html',
  styleUrls: ['./category-bucket-summary-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryBucketSummaryInfoComponent implements OnInit {
  @Input() bucket: Bucket;
  LABELS = {
    REMAINING: {
      BUDGET: 'Remaining',
      INCOME: 'Expected',
    },
    OVER: {
      BUDGET: 'Exceeded',
      INCOME: 'Surplus',
    },
  };

  get remainingLabel() {
    return this.LABELS.REMAINING[this.bucket.type];
  }

  get monthsLeft() {
    if (this.bucket.fund && this.bucket.fund.goal)
      return Math.round((this.bucket.fund.goal - this.bucket.fund.balance) / this.bucket.amount);
  }

  get yearsLeft() {
    if (this.bucket.fund && this.bucket.fund.goal)
      return Math.round(((this.bucket.fund.goal - this.bucket.fund.balance) / this.bucket.amount / 12) * 10) / 10;
  }

  get remaining() {
    if (this.bucket.type === 'INCOME' && this.bucket.amount - this.bucket.total >= 0)
      return this.bucket.amount - this.bucket.total;
    if (this.bucket.type === 'INCOME' && this.bucket.amount - this.bucket.total < 0) return false;
    if (this.bucket.fund && this.bucket.fund.balance >= 0) return this.bucket.fund.balance;
    if (!this.bucket.fund && this.bucket.amount + this.bucket.total > 0) return this.bucket.amount + this.bucket.total;

    return false;
  }

  get overLabel() {
    return this.LABELS.OVER[this.bucket.type];
  }

  get over() {
    if (this.bucket.type === 'INCOME' && this.bucket.amount - this.bucket.total < 0)
      return (this.bucket.amount - this.bucket.total) * -1;
    if (this.bucket.fund && this.bucket.fund.balance < 0) return this.bucket.fund.balance * -1;
    if (!this.bucket.fund && this.bucket.amount + this.bucket.total < 0)
      return (this.bucket.amount + this.bucket.total) * -1;
    return false;
  }

  get goal() {
    if (this.bucket.type === 'INCOME') return false;
    return this.bucket.fund.goal;
  }

  get spent() {
    if (this.bucket.type === 'INCOME') return false;
    return this.bucket.total * -1;
  }

  get unsignedBucketTotal() {
    return Math.abs(this.bucket.total);
  }

  get earned() {
    if (this.bucket.type !== 'INCOME') return false;
    return this.bucket.total;
  }
  constructor() {}

  ngOnInit() {}
}
