import { Component, OnDestroy } from '@angular/core';
import { DrawerService, DrawerComponentData } from '../../services/drawer.service';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetDrawerComponent } from '../../bottom-templates/bottom-sheet-drawer/bottom-sheet-drawer.component';
import { BucketDrawerWrapperComponent } from '../drawer-wrappers/bucket-drawer-wrapper/bucket-drawer-wrapper.component';
import { AddBucketDrawerWrapperComponent } from '../drawer-wrappers/add-bucket-drawer-wrapper/add-bucket-drawer-wrapper.component';
import { AccountDetailsDrawerWrapperComponent } from '../drawer-wrappers/account-details-drawer-wrapper/account-details-drawer-wrapper.component';
import { UnbudgetedDrawerWrapperComponent } from '../drawer-wrappers/unbudgeted-drawer-wrapper/unbudgeted-drawer-wrapper.component';
import { CategoriesPieChartDrawerWrapperComponent } from '../drawer-wrappers/categories-pie-chart-drawer-wrapper/categories-pie-chart-drawer-wrapper.component';
import { CustomAccountDrawerWrapperComponent } from '../drawer-wrappers/custom-account-drawer-wrapper/custom-account-drawer-wrapper.component';
import { DocumentationDrawerWrapperComponent } from '../drawer-wrappers/documentation-drawer-wrapper/documentation-drawer-wrapper.component';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnDestroy {
  drawerParamsSubscription: Subscription;
  dragPosition: { x: number; y: number };

  drawerData = {
    budget: params => ({
      type: BucketDrawerWrapperComponent,
      data: { bucketId: params.drawerBudgetId },
    }),
    'new-budget': params => ({
      type: AddBucketDrawerWrapperComponent,
      data: { categoryId: params.drawerCategoryId },
    }),
    'account-details': params => ({
      type: AccountDetailsDrawerWrapperComponent,
      data: { accountId: params.drawerAccountId, credentialId: params.drawerCredentialId },
    }),
    'categories-chart': params => ({
      type: CategoriesPieChartDrawerWrapperComponent,
      data: { categoryId: params.drawerCategoryId },
    }),
    'custom-account': params => ({
      type: CustomAccountDrawerWrapperComponent,
      data: { accountId: params.drawerAccountId },
    }),
    available: () => ({ type: UnbudgetedDrawerWrapperComponent, data: {} }),
    documentation: params => ({
      type: DocumentationDrawerWrapperComponent,
      data: { slug: params.drawerSlug },
    }),
  };

  constructor(
    public drawerService: DrawerService,
    private nav: NavigationService,
    private _bottomSheet: MatBottomSheet,
  ) {
    this.drawerParamsSubscription = this.nav.routes$.subscribe(() => {
      console.log('drawer budget params: ', this.nav.params);
      const drawerData = this.getDrawerDataFromParams(this.nav.params);
      if (drawerData) return this.openDrawer(drawerData);

      this.drawerService.navOpen = false;
      this.drawerService.bottomSheetOpen = false;
      window.document.body.classList.remove('noscroll');
      this._bottomSheet.dismiss();
    });
  }

  private getDrawerDataFromParams(params) {
    if (params.drawer === 'budget' && params.drawerBudgetId) return this.drawerData['budget'](params);
    if (params.drawer === 'account-details' && params.drawerAccountId)
      return this.drawerData['account-details'](params);
    if (this.drawerData[params.drawer]) return this.drawerData[params.drawer](params);
    return false;
  }

  private openDrawer(data: DrawerComponentData) {
    this.drawerService._currentComponent.next(data);

    if (window.innerWidth >= 600) {
      this._bottomSheet.dismiss();
      this.drawerService.navOpen = true;
    } else {
      this.drawerService.navOpen = false;
      this.drawerService.bottomSheetOpen = true;
      window.document.body.classList.add('noscroll');
      if (!this._bottomSheet._openedBottomSheetRef) {
        this._bottomSheet.open(BottomSheetDrawerComponent, {
          data: data.data,
          hasBackdrop: false,
        });
      }
    }
  }

  dragMoved(event: CdkDragMove) {
    this.drawerService.dragDistance = event.distance.x;
    if (event.distance.x > 180) {
      this.drawerService.closeDrawer();
    }
  }

  dragEnded($event) {
    if ($event.distance.x < 180) this.resetDrag();
  }

  resetDrag() {
    this.drawerService.dragDistance = 0;
    this.dragPosition = { x: 0, y: 0 };
  }

  ngOnDestroy() {
    this.drawerParamsSubscription.unsubscribe();
  }
}
