<div class="add-transaction">
  <h3 class="add-transaction-title">Add Transaction</h3>
  <form #addTransactionForm="ngForm" (ngSubmit)="addTransactionFormSubmit(addTransactionForm)" autocomplete="off">
    <mat-form-field floatLabel="auto" appearance="outline" class="no-padding">
      <mat-label>Date</mat-label>
      <input matInput type="date" [(ngModel)]="transaction.date" name="date" required />
    </mat-form-field>

    <mat-radio-group [(ngModel)]="transaction.type" name="type" required>
      <mat-radio-button name="type" value="EXPENSE"> Spent </mat-radio-button>
      <mat-radio-button name="type" value="INCOME"> Received </mat-radio-button>
    </mat-radio-group>

    <mat-form-field floatLabel="auto" appearance="outline" class="no-padding">
      <mat-label>Transaction Amount</mat-label>
      <span matPrefix>$&nbsp;</span>
      <input
        name="amount"
        matInput
        type="number"
        min="0.01"
        step="0.01"
        [(ngModel)]="transaction.amount"
        pattern="^[0-9]+(\.[0-9][0-9]?)?$"
        required
      />
    </mat-form-field>

    <mat-form-field floatLabel="auto" appearance="outline" class="no-padding">
      <mat-label>Merchant Name</mat-label>
      <input matInput type="text" [(ngModel)]="transaction.merchant" name="merchant" required />
    </mat-form-field>

    <app-label-typeahead [(selectedLabelIds)]="transaction.labelIds"></app-label-typeahead>

    <mat-form-field class="add-transaction-description no-padding" floatLabel="auto" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        placeholder="More information about this transaction..."
        [(ngModel)]="transaction.description"
        name="description"
      ></textarea>
    </mat-form-field>

    <div class="flex-row">
      <div class="spacer"></div>
      <button mat-flat-button color="primary" type="submit" [disabled]="!addTransactionForm.form.valid || loading">
        Add Transaction
      </button>
    </div>
  </form>
</div>
