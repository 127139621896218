import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomValidators, errorMessages, ConfirmValidParentMatcher } from '../forms/custom-validators';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
  @Input()
  parent: FormGroup;
  errors = errorMessages;
  confirmValidParentMatcher = new ConfirmValidParentMatcher();
  constructor() {}
  ngOnInit() {}
}
