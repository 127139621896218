import {
  FormGroup,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  AsyncValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { map, switchMap } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';

/**
 * Custom validator functions for reactive form validation
 */
export class CustomValidators {
  /**
   * Validates that child controls in the form group are equal
   */
  static childrenEqual: ValidatorFn = (formGroup: FormGroup) => {
    const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
    const isValid = otherControlNames.every(
      controlName => formGroup.get(controlName).value === formGroup.get(firstControlName).value,
    );
    return isValid ? null : { childrenNotEqual: true };
  };

  static existingEmailValidator(request$: (email: string) => Observable<any>): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return timer(400).pipe(
        switchMap(() =>
          request$(control.value).pipe(
            map((res: any) => {
              return res.available ? null : { emailTaken: true };
            }),
          ),
        ),
      );
    };
  }
}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.parent.invalid && control.touched;
  }
}

/**
 * Collection of reusable regex
 */
export const patterns: { [key: string]: RegExp } = {
  password: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,32}$/,
};

/**
 * Collection of reusable error messages
 */
export const errorMessages: { [key: string]: string } = {
  firstName: 'Please enter your first name.',
  lastName: 'Please enter your last name.',
  email: 'Please enter a valid email address.',
  emailTaken: 'That email address already has an account!',
  password: 'Password must be between 8 and 32 characters, and contain at least one letter and number',
  validatePassword: 'Please make sure your password is typed correctly.',
};
