<div class="spend-summary" *ngIf="bucket">
  <div *ngIf="earned !== false" class="spend-summary-item">
    <span class="spend-summary-title">
      EARNED
    </span>
    <span class="spend-summary-number">
      {{ earned | currency:'USD':'symbol':'1.0-0'}}
    </span>
  </div>
  <div *ngIf="spent !== false" class="spend-summary-item">
    <span class="spend-summary-title">
      SPENT
    </span>
    <span class="spend-summary-number">
      {{ spent | currency:'USD':'symbol':'1.0-0'}}
    </span>
  </div>
  <div *ngIf="remaining" class="spend-summary-item">
    <span class="spend-summary-title">
      {{ remainingLabel }}
    </span>
    <span class="spend-summary-number">
      {{ remaining | currency:'USD':'symbol':'1.0-0'}}
    </span>
  </div>
  <div *ngIf="over" class="spend-summary-item">
    <span class="spend-summary-title">
      {{ overLabel }}
    </span>
    <span class="spend-summary-number">
      {{ over | currency:'USD':'symbol':'1.0-0'}}
    </span>
  </div>
  <div *ngIf="goal !== false" class="spend-summary-item">
    <span class="spend-summary-title">
      GOAL
    </span>
    <span class="spend-summary-number">
      {{ goal | currency:'USD':'symbol':'1.0-0'}}
    </span>
  </div>
</div>