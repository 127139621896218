<div class="bucket-selector">
  <button
    mat-flat-button
    [color]="needsSaving ? 'accent' : 'basic'"
    class="bucket-selector-dropdown-button"
    (click)="onDropdownClick($event)"
    [matMenuTriggerFor]="budgets"
  >
    {{ selectedBucket?.name || 'Select Budget' }} <mat-icon>expand_more</mat-icon>
  </button>

  <mat-menu class="bucket-selector-menu" #budgets="matMenu" yPosition="below">
    <mat-form-field
      class="bucket-selector-filter no-padding"
      floatLabel="auto"
      appearance="outline"
      (click)="stopPropagation($event)"
    >
      <mat-label>Search Budgets</mat-label>
      <input type="text" name="filterBudget" matInput [(ngModel)]="searchValue" (keydown)="onKeyDown($event)" />

      <button mat-icon-button *ngIf="searchValue" (click)="searchValue = ''" class="suffix" matSuffix>
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div class="bucket-selector-list">
      <div class="bucket-selector-suggested" *ngIf="suggestedBuckets.length && !searchValue">
        <span class="bucket-selector-suggested-title">Suggested: </span>

        <button *ngFor="let suggestedBucket of suggestedBuckets" mat-menu-item (click)="selectBucket(suggestedBucket)">
          {{ suggestedBucket.name }}
        </button>
      </div>

      <button
        *ngFor="let bucket of buckets | fuzzy: searchKeys:searchValue; trackBy: trackByFn"
        mat-menu-item
        (click)="selectBucket(bucket)"
      >
        {{ bucket.name }}
      </button>
    </div>
  </mat-menu>

  <button *ngIf="needsSaving" mat-flat-button color="primary" class="bucket-selector-save" (click)="onSave($event)">
    <mat-icon>check</mat-icon>
  </button>
</div>
