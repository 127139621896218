import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { Bucket } from '../../types';
import { Subject, Subscription, Observable, from, combineLatest, ReplaySubject, BehaviorSubject } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { BucketService } from '../../services/bucket.service';
import { NgForm, FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-bucket-setup-toolbar',
  templateUrl: './bucket-setup-toolbar.component.html',
  styleUrls: ['./bucket-setup-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BucketSetupToolbarComponent implements OnInit, OnDestroy {
  @Input() bucket: Bucket;
  formGroup = new FormGroup({
    amount: new FormControl(0, [Validators.required, Validators.pattern('[0-9.]*')]),
    goal: new FormControl(0, [Validators.pattern('[0-9.]*')]),
  });
  _amountChanged = new ReplaySubject(1);
  _goalChanged = new ReplaySubject(1);

  subscriptions = new Subscription();

  constructor(private bucketService: BucketService) {
    this.formGroup.markAllAsTouched();
    this.subscriptions = this.formGroup.valueChanges
      .pipe(filter(() => this.formGroup.valid))
      .pipe(debounceTime(200))
      .subscribe(value => this.updateBucket(value));
  }

  ngOnInit() {
    this.formGroup.setValue({ amount: this.bucket.amount, goal: this.bucket.fund.goal });
  }

  updateBucket({ amount, goal }) {
    if (goal === this.bucket.fund.goal && amount === this.bucket.amount) return;
    this.bucket.fund.goal = goal;
    this.bucket.amount = amount;
    this.bucketService.editBucket$({ ...this.bucket }).subscribe();
  }

  onFocus(control: AbstractControl) {
    if (control.value === 0) control.setValue(null);
  }

  onFocusOut(control: AbstractControl) {
    if (!control.value) control.setValue(0);
  }

  get bucketTotal(): number {
    if (this.bucket.type === 'INCOME') return this.bucket.total;
    return this.bucket.total * -1;
  }

  finishSetup() {
    this.bucketService.editBucket$({ ...this.bucket, isSetup: true }).subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
