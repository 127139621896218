<div class="categories-page-summary" *ngIf="!isSearching; else search">
  <div class="flex-row categories-page-summary-top">
    <div class="categories-page-summary-info">
      <div class="categories-page-summary-info-item">
        <div class="categories-page-summary-info-title">Spent</div>
        {{ categoryPage.balance.BUDGET * -1 | currency: 'USD':'symbol':'1.2-2' }}
      </div>
      <div class="categories-page-summary-info-item">
        <div class="categories-page-summary-info-title">Earned</div>
        {{ categoryPage.balance.INCOME | currency: 'USD':'symbol':'1.2-2' }}
      </div>
    </div>
    <div class="spacer"></div>

    <button *ngIf="categoryChartData?.length" class="categories-page-summary-chart" mat-icon-button
      (click)="openChartDrawer()">
      <doughnut-chart [width]="28" [height]="28" [data]="categoryChartData" [preview]="true">
      </doughnut-chart>
    </button>

    <button mat-icon-button (click)="isSearching = true">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <app-budget-in-sync class="categories-page-summary-sync" *ngIf="
      (sessionService.hasEnabledFeature$('transfer-account-balance') | async) &&
      categoryPage.unbudgeted &&
      categoryPage.unbudgeted.unbudgeted !== 0
    " [unbudgeted]="categoryPage.unbudgeted.unbudgeted"></app-budget-in-sync>

  <button *ngIf="update.available | async" class="categories-page-summary-update" mat-flat-button color="primary"
    (click)="updateApp()">
    <span class="flex-row">
      The Budgets app has an update available, click here to refresh now.<span class="spacer"></span>
      <mat-icon>refresh</mat-icon>
    </span>
  </button>
</div>

<ng-template #search>
  <div class="categories-page-summary-search">
    <mat-form-field class="category-page-search-field remove-spacing" floatLabel="auto" appearance="outline">
      <span matPrefix aria-hidden="true" class="categories-page-summary-search-icon">
        <mat-icon>search</mat-icon>
      </span>
      <mat-label>Search budgets</mat-label>
      <input matInput name="budgetSearch" type="text" [(ngModel)]="categoryService.categorySearch" [autofocus] />
      <button mat-icon-button matSuffix aria-label="Clear" class="category-page-search-clear"
        (click)="categoryService.categorySearch = ''; isSearching = false">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</ng-template>