import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { DataService } from './data.service';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { filter, catchError, tap, switchMap, shareReplay, take, startWith, map, find } from 'rxjs/operators';
import { PlaidService } from './plaid.service';
import { CredentialPage, CustomAccount } from '../types';
import { ErrorMessagingService } from './error.service';
import { AnalyticsService } from './analytics.service';
import { EVENT_ADDED_ACCOUNT, EVENT_ADDED_CUSTOM_ACCOUNT } from '../analytics/events';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    private analytics: AnalyticsService,
    private backend: BackendService,
    private data: DataService,
    private plaidService: PlaidService,
    private errorMessaging: ErrorMessagingService,
  ) {}

  creatingCredential = false;

  credentialsRequest$ = this.backend.request({
    type: 'get',
    apiRoute: 'credential',
  });

  fetchCredentials$ = this.credentialsRequest$.pipe(tap(c => this.setAccountData(c)));

  _credentials: BehaviorSubject<CredentialPage> = new BehaviorSubject(null);
  credentials$: Observable<CredentialPage> = this.fetchCredentials$.pipe(
    switchMap(() => this._credentials.asObservable()),
    startWith(this.data.getLocalStorage('credentials')),
    filter(page => !!page),
    shareReplay(1),
  );

  totalAccounts$ = this.credentials$.pipe(
    map((credentialPage: CredentialPage) =>
      credentialPage.data.reduce((acc, credential) => acc + credential.accounts.length, 0),
    ),
  );

  credentialByAccountId$ = (accountId: string) =>
    this.credentials$.pipe(
      map(credentials => credentials.data.find(data => !!data.accounts.find(a => a.id === accountId))),
    );

  customCredential$ = () =>
    this.credentials$.pipe(map(credentials => credentials.data.find(credential => credential.type === 'CUSTOM')));

  accountByAccountId$ = (accountId: string) =>
    this.credentialByAccountId$(accountId).pipe(map(credential => credential.accounts.find(a => a.id === accountId)));

  // If a credentialId is passed, that means Link is being opened in update mode (re-authenticating a credential, not creating a new one.)
  // Therefore we need to fetch a separate token from the backend.
  // Otherwise we can used the cached token
  private fetchLinkToken$(
    path: string,
    credentialId?: string,
  ): Observable<{ token: string; expires: string; credentialId?: string }> {
    const cachedToken = credentialId ? null : this.data.getSessionStorage(`plaid-link-token-${path}`);

    if (cachedToken && cachedToken.expires && DateTime.fromISO(cachedToken.expires) > DateTime.local()) {
      return of(cachedToken);
    }
    return this.backend
      .request({
        type: 'get',
        apiRoute: `credential/linkToken`,
        params: { path, credentialId },
      })
      .pipe(tap(token => this.data.setSessionStorage(`plaid-link-token-${path}`, token)));
  }

  initializePlaidLink$({
    path,
    receivedRedirectUri,
    credentialId,
    onLoad,
  }: { path?: string; receivedRedirectUri?: string; credentialId?: string; onLoad?: () => void } | undefined) {
    const configuredPath = path || window.location.pathname;
    return this.fetchLinkToken$(configuredPath, credentialId).pipe(
      switchMap(res =>
        this.plaidService.initializePlaid$({
          token: res.token,
          receivedRedirectUri,
          onSuccess: (publicToken, metadata) => {
            if (res.credentialId) {
              this.data.removeSessionStorage(`plaid-link-token-${path}`);
              return this.reauthenticateCredential$(res.credentialId).subscribe();
            }
            return this.configureAccounts(publicToken);
          },
          onExit: () => {
            this.data.removeSessionStorage(`plaid-link-token-${path}`);
          },
          onLoad,
        }),
      ),
    );
  }

  setAccountData(accountData) {
    this.data.setLocalStorage('credentials', accountData);
    this._credentials.next(accountData);
  }

  // Dont refresh account data if we haven't fetched it yet.
  // Simply subscribing to credentials$ will cause an http call.
  refreshAccountData$() {
    return this._credentials.pipe(
      take(1),
      switchMap(credentials => (!!credentials ? this.fetchCredentials$ : of())),
    );
  }

  createCredential(publicToken: string, syncStartDate: string) {
    this.creatingCredential = true;
    this.backend
      .request({
        type: 'post',
        apiRoute: 'credential',
        data: { publicToken, syncStartDate },
      })
      .pipe(
        catchError(e => {
          const baseMsg = 'Failed to authenticate accounts';
          this.errorMessaging.backendError(e, baseMsg);
          this.creatingCredential = false;
          return throwError(e);
        }),
        switchMap(() => this.refreshAccountData$()),
        tap(() => (this.creatingCredential = false)),
      )
      .subscribe(() => this.analytics.sendEvent(EVENT_ADDED_ACCOUNT));
  }

  deleteCredential$(credential): Observable<void> {
    return this.backend
      .request({
        type: 'delete',
        apiRoute: `credential/${credential.id}`,
      })
      .pipe(switchMap(() => this.refreshAccountData$()));
  }

  reauthenticateCredential$(credentialId: string) {
    const credentials = this._credentials.getValue();
    this._credentials.next({
      ...credentials,
      data: credentials.data.map(c => (c.id === credentialId ? { ...c, reauthenticating: true } : c)),
    });
    return this.backend
      .request({
        type: 'update',
        apiRoute: 'credential/reauthenticate',
        data: { id: credentialId },
      })
      .pipe(switchMap(() => this.refreshAccountData$()));
  }

  configureAccounts(publicToken: string) {
    const now = new Date();
    this.createCredential(publicToken, new Date(now.getFullYear(), now.getMonth(), 1).toISOString());
  }

  addCustomAccount$(data: Partial<CustomAccount>, credentialId: string) {
    return this.backend
      .request({
        type: 'post',
        apiRoute: `credential/custom/${credentialId}/account`,
        data,
      })
      .pipe(
        switchMap(() => this.refreshAccountData$()),
        tap(() => {
          this.creatingCredential = false;
          if (!data.id) this.analytics.sendEvent(EVENT_ADDED_CUSTOM_ACCOUNT);
        }),
      );
  }

  editCustomAccount$(data: Partial<CustomAccount>, credentialId: string) {
    return this.backend
      .request({
        type: 'update',
        apiRoute: `credential/custom/${credentialId}/account`,
        data,
      })
      .pipe(
        switchMap(() => this.refreshAccountData$()),
        tap(() => (this.creatingCredential = false)),
      );
  }

  deleteCustomAccount$(data: Partial<CustomAccount>, credentialId: string) {
    return this.backend
      .request({
        type: 'delete',
        apiRoute: `credential/custom/${credentialId}/account/${data.id}`,
      })
      .pipe(
        switchMap(() => this.refreshAccountData$()),
        tap(() => (this.creatingCredential = false)),
      );
  }
}
