import './setupLogRocket';
import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MobileService } from './app/services/mobile.service';

if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((modRef: NgModuleRef<AppModule>) => {
    window['BudgetsMobileAppService'] = modRef.injector.get(MobileService);
  })
  .catch((err) => console.log(err));
