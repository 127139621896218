import * as LogRocket from 'logrocket';
import { environment } from './environments/environment';
import { UserSession } from './app/types/session';

if (environment.logrocket !== 'false') {
  const hideUsersFromLogrocket = environment.hideUsersFromLogrocket;
  if (hideUsersFromLogrocket.length > 1) {
    const item = window.localStorage.getItem('session');
    const session: UserSession = item && item !== '{}' ? JSON.parse(item) : null;
    if (!environment.hideUsersFromLogrocket.includes(session?.user?.email))
      LogRocket.init(environment.logrocket, {
        network: {
          requestSanitizer: (request: any) => {
            if (request.body && request.body.password) request.body.password = 'hidden';
            return request;
          },
        },
      });
  }
}
