import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DrawerService } from '../../services/drawer.service';

@Component({
  selector: 'app-drawer-header',
  templateUrl: './drawer-header.component.html',
  styleUrls: ['./drawer-header.component.scss'],
})
export class DrawerHeaderComponent implements OnInit {
  @Input() title: string;
  @Output() onClose = new EventEmitter();

  constructor(private drawerService: DrawerService) {}

  ngOnInit() {}

  close() {
    this.onClose.emit();
    this.drawerService.closeDrawer();
  }
}
