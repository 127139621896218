export const EVENT_ACCOUNT_CREATED = 'Account Created';
export const EVENT_PARTNER_ACCOUNT_INVITED = 'Partner Account Invited';
export const EVENT_PARTNER_ACCOUNT_CREATED = 'Partner Account Created';
export const EVENT_GETTING_STARTED_PROGRESS = 'Onboarding Progress';
export const EVENT_ONBOARDING_COMPLETED = 'Onboarding Completed';
export const EVENT_LOGGED_IN_USERPASS = 'Logged In with Username/Password';
export const EVENT_LOGOUT = 'Logged Out';
export const EVENT_ACCOUNT_UPGRADED = 'Upgrade Account from Upgrade Page';
export const EVENT_ACCOUNT_UPGRADED_SETTINGS = 'Upgrade Account from Settings Page';
export const EVENT_ACCOUNT_DOWNGRADED = 'Downgraded Account';
export const EVENT_UPDATED_EMAIL = 'Updated Email';
export const EVENT_UPDATING_EMAIL_CANCELED = 'Canceled Updating Email';
export const EVENT_UPDATED_PAYMENT_METHOD = 'Updated Payment Method';
export const EVENT_NOTIFICATIONS_SETTINGS_UPDATED = 'Notifications Settings Updated';
export const EVENT_OPENED_CATEGORY_SEARCH = 'Opened Category Search';
export const EVENT_REORDERED_CATEGORY = 'Reordered Category';
export const EVENT_REORDERED_BUDGET = 'Reordered Budget';
export const EVENT_CHANGED_BUDGET_AMOUNT = 'Change Budget Amount';
export const EVENT_CREATED_BUDGET = 'Created Budget';
export const EVENT_UPDATED_BUDGET = 'Updated Budget';
export const EVENT_DELETED_BUDGET = 'Deleted Budget';
export const EVENT_TRANSFERRED_BUDGET_IN = 'Transferred Budget In';
export const EVENT_RENAMED_CATEGORY = 'Renamed Category';
export const EVENT_DELETED_CATEGORY = 'Deleted Category';
export const EVENT_DELETED_CATEGORY_CANCELED = 'Canceled Deleting Category';
export const EVENT_CREATED_TRANSACTION = 'Created Transaction';
export const EVENT_CREATED_LABEL = 'Created Label';
export const EVENT_ADDED_LABEL = 'Added Label';
export const EVENT_REMOVED_LABEL = 'Removed Label';
export const EVENT_FEEDBACK_OPENED = 'Opened Feedback';
export const EVENT_FEEDBACK_CANCELED = 'Canceled Feedback';
export const EVENT_SENT_FEEDBACK = 'Sent Feedback';
export const EVENT_PREVIOUS_MONTH_SELECTED = 'Previously Month Selected';
export const EVENT_NEXT_MONTH_SELECTED = 'Next Month Selected';
export const EVENT_TODAYS_MONTH_SELECTED = `Today's Month Selected`;
export const EVENT_SORTED_TRANSACTION_FORM = 'Sorted Transaction with Form';
export const EVENT_SORTED_TRANSACTION_SUGGESTION = 'Sorted Transaction with Suggested Buttons';
export const EVENT_SELECTED_TRANSACTION = 'Selected Transaction';
export const EVENT_DESELECTED_TRANSACTION = 'Deselected Transaction';
export const EVENT_SELECTED_TRANSACTIONS_DELETED = 'Selected Transactions Deleted';
export const EVENT_OPENED_PLAID = 'Opened Plaid';
export const EVENT_ADDED_ACCOUNT = 'Added Account';
export const EVENT_ADDED_CUSTOM_ACCOUNT = 'Added Custom Account';
export const EVENT_TRIED_DELETING_ACCOUNT = 'Tried Deleting Account';
export const EVENT_ADDED_ACCOUNT_NICKNAME = 'Added Account Nickname';
export const EVENT_ENABLED_ACCOUNT_SYNCING = 'Enabled Account Syncing';
export const EVENT_DISABLED_ACCOUNT_SYNCING = 'Disabled Account Syncing';

const EVENT_TYPES = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  CANCEL: 'Cancel',
  CONFIRM: 'Confirm',
  CLICK: 'Click',
  FORM: 'FormSubmit',
  SUB: 'Subscription Update',
  SETTINGS: 'Settings Update',
  REORDER: 'Reorder',
  LABELS: 'Labels Update',
};

const EVENT_CATEGORIES = {
  ACCOUNTS: 'Accounts Page',
  AUTH: 'Auth',
  BUDGETING: 'Budgeting',
  SETTINGS: 'Account Settings',
  DASH: 'Dashboard',
  UPGRADE: 'Upgrade Page',
  ONBOARD: 'Onboarding',
  SORTING: 'Sorting Page',
};

export default {
  [EVENT_ACCOUNT_CREATED]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.ONBOARD,
  },
  [EVENT_PARTNER_ACCOUNT_INVITED]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.SETTINGS,
  },
  [EVENT_PARTNER_ACCOUNT_CREATED]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.ONBOARD,
  },
  [EVENT_GETTING_STARTED_PROGRESS]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.ONBOARD,
  },
  [EVENT_ONBOARDING_COMPLETED]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.ONBOARD,
  },
  [EVENT_LOGGED_IN_USERPASS]: {
    event: EVENT_TYPES.LOGIN,
    category: EVENT_CATEGORIES.AUTH,
  },
  [EVENT_LOGOUT]: {
    event: EVENT_TYPES.LOGOUT,
    category: EVENT_CATEGORIES.AUTH,
  },
  [EVENT_UPDATED_EMAIL]: {
    event: EVENT_TYPES.SETTINGS,
    category: EVENT_CATEGORIES.SETTINGS,
  },
  [EVENT_UPDATING_EMAIL_CANCELED]: {
    event: EVENT_TYPES.CANCEL,
    category: EVENT_CATEGORIES.SETTINGS,
  },
  [EVENT_ACCOUNT_UPGRADED]: {
    event: EVENT_TYPES.SUB,
    category: EVENT_CATEGORIES.UPGRADE,
    value: 5,
  },
  [EVENT_ACCOUNT_UPGRADED_SETTINGS]: {
    event: EVENT_TYPES.SUB,
    category: EVENT_CATEGORIES.SETTINGS,
    value: 5,
  },
  [EVENT_ACCOUNT_DOWNGRADED]: {
    event: EVENT_TYPES.SUB,
    category: EVENT_CATEGORIES.SETTINGS,
  },
  [EVENT_NOTIFICATIONS_SETTINGS_UPDATED]: {
    event: EVENT_TYPES.SETTINGS,
    category: EVENT_CATEGORIES.SETTINGS,
  },
  [EVENT_UPDATED_PAYMENT_METHOD]: {
    event: EVENT_TYPES.SETTINGS,
    category: EVENT_CATEGORIES.SETTINGS,
  },
  [EVENT_OPENED_CATEGORY_SEARCH]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_REORDERED_CATEGORY]: {
    event: EVENT_TYPES.REORDER,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_REORDERED_BUDGET]: {
    event: EVENT_TYPES.REORDER,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_CREATED_BUDGET]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_CHANGED_BUDGET_AMOUNT]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_UPDATED_BUDGET]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_DELETED_BUDGET]: {
    event: EVENT_TYPES.CONFIRM,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_TRANSFERRED_BUDGET_IN]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_RENAMED_CATEGORY]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_DELETED_CATEGORY]: {
    event: EVENT_TYPES.CONFIRM,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_DELETED_CATEGORY_CANCELED]: {
    event: EVENT_TYPES.CANCEL,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_CREATED_TRANSACTION]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_CREATED_LABEL]: {
    event: EVENT_TYPES.LABELS,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_ADDED_LABEL]: {
    event: EVENT_TYPES.LABELS,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_REMOVED_LABEL]: {
    event: EVENT_TYPES.LABELS,
    category: EVENT_CATEGORIES.BUDGETING,
  },
  [EVENT_SENT_FEEDBACK]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_FEEDBACK_OPENED]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_FEEDBACK_CANCELED]: {
    event: EVENT_TYPES.CANCEL,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_PREVIOUS_MONTH_SELECTED]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_NEXT_MONTH_SELECTED]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_TODAYS_MONTH_SELECTED]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.DASH,
  },
  [EVENT_SORTED_TRANSACTION_FORM]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.SORTING,
  },
  [EVENT_SORTED_TRANSACTION_SUGGESTION]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.SORTING,
  },
  [EVENT_SELECTED_TRANSACTION]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.SORTING,
  },
  [EVENT_DESELECTED_TRANSACTION]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.SORTING,
  },
  [EVENT_SELECTED_TRANSACTIONS_DELETED]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.SORTING,
  },
  [EVENT_OPENED_PLAID]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.ACCOUNTS,
  },
  [EVENT_ADDED_ACCOUNT]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.ACCOUNTS,
  },
  [EVENT_ADDED_CUSTOM_ACCOUNT]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.ACCOUNTS,
  },
  [EVENT_TRIED_DELETING_ACCOUNT]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.ACCOUNTS,
  },
  [EVENT_ADDED_ACCOUNT_NICKNAME]: {
    event: EVENT_TYPES.FORM,
    category: EVENT_CATEGORIES.ACCOUNTS,
  },
  [EVENT_ENABLED_ACCOUNT_SYNCING]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.ACCOUNTS,
  },
  [EVENT_DISABLED_ACCOUNT_SYNCING]: {
    event: EVENT_TYPES.CLICK,
    category: EVENT_CATEGORIES.ACCOUNTS,
  },
};
