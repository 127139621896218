<div class="transaction-list" [ngClass]="{ selectable: transactionList.options.selectable }">
  <app-selected-transaction-toolbar
    *ngIf="transactionList.options.selectable"
    [buckets]="buckets"
    [transactionList]="transactionList"
    (transactionListEvent)="transactionListEvent.emit($event)"
    [type]="type"
  ></app-selected-transaction-toolbar>
  <mat-table #table [dataSource]="transactionsDataSource">
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row?.date | date: 'MM/dd' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="merchant">
      <mat-header-cell *matHeaderCellDef> Info </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngIf="isTransaction(row)">
          {{ row?.merchant }} <span *ngIf="row?.bankMeta?.pending"> (...pending) </span>
          <span *ngIf="row?.description"> {{ row.description }}</span>
        </div>
        <div *ngIf="isTransfer(row)">Transfer {{ isIncome(row) ? 'from' : 'to' }} {{ row.transferBucket.name }}</div>
        <div *ngIf="row?.plaidAccount" class="transaction-list-account">
          {{ row.plaidAccount.nickname || row.plaidAccount.officialName || row.plaidAccount.name }}
          <ng-container *ngIf="row.plaidAccount.mask">| {{ row.plaidAccount.mask }}</ng-container>
        </div>
        <div *ngIf="row?.parent && row?.parent.plaidAccount" class="transaction-list-account">
          {{ row.parent.plaidAccount.nickname || row.parent.plaidAccount.officialName || row.parent.plaidAccount.name }}
          <ng-container *ngIf="row.parent.plaidAccount.mask">| {{ row.parent.plaidAccount.mask }}</ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row?.amount | currency }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['date', 'merchant', 'amount']"></mat-header-row>

    <ng-container *ngIf="!transactionList.options.selectable">
      <div
        *matRowDef="let row; columns: ['date', 'merchant', 'amount']"
        class="transaction-row"
        [ngClass]="{ income: isIncome(row), empty: !row }"
      >
        <mat-row></mat-row>
      </div>
    </ng-container>
    <ng-container *ngIf="transactionList.options.selectable">
      <div
        *matRowDef="let row; columns: ['date', 'merchant', 'amount']"
        class="transaction-row"
        [ngClass]="{ income: isIncome(row), transfer: isTransfer(row), empty: !row }"
      >
        <mat-button-toggle
          type="button"
          (change)="toggleSelection(row)"
          [checked]="transactionList.isSelected(row)"
          [disabled]="!isTransaction(row) || (row.locked && row.locked.value)"
        >
          <div class="flex-row">
            <mat-icon
              *ngIf="isTransaction(row) && (!row.locked || !row.locked.value) && transactionList.isSelected(row)"
            >
              check_box_outline_blank
            </mat-icon>
            <mat-icon
              *ngIf="isTransaction(row) && (!row.locked || !row.locked.value) && !transactionList.isSelected(row)"
            >
              check_box_outline_blank
            </mat-icon>

            <mat-icon
              class="transfer-icon"
              *ngIf="isTransfer(row)"
              [svgIcon]="isIncome(row) ? 'transfer-in' : 'transfer-out'"
            >
            </mat-icon>
            <button
              mat-icon-button
              class="locked"
              *ngIf="row && row.locked && row.locked.value"
              (click)="whyLocked(row.locked.reason)"
            >
              <mat-icon>lock</mat-icon>
            </button>
            <mat-row></mat-row>
          </div>
        </mat-button-toggle>
        <button
          class="transfer-delete-button"
          *ngIf="isTransfer(row)"
          (click)="openDeleteTransferModal(row)"
          mat-icon-button
        >
          <mat-icon inline="true">delete</mat-icon>
        </button>
      </div>
    </ng-container>
  </mat-table>

  <mat-paginator
    #paginator
    [length]="(transactionList.page$ | async)?.totalRecords"
    [pageIndex]="(transactionList.page$ | async)?.pageIndex || 0"
    [pageSize]="transactionList?.options.listPageSize"
    (page)="handlePageEvent($event)"
  >
  </mat-paginator>

  <div *ngIf="transactionList?.loading.moreTransactions" class="loading">
    <mat-spinner [strokeWidth]="2" [diameter]="40"></mat-spinner>
  </div>
</div>
