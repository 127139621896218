<h3 class="title">Reset your account</h3>
<p>
  Resetting your account will <span class="bold">permanently</span> delete all of your budgets, groups, transactions,
  and transfers. Your authenticated financial accounts will remain, and will begin syncing transactions again starting
  {{ syncStartDate | date: 'MM/dd/yyyy' }}.
</p>

<p>
  If you wish to start fresh in {{ syncStartDate | date: 'MMMM' }} while keeping your budgets and groups, you can
  <span class="reset-link" (click)="openResetBalancesDialog()">Reset Your Budgets Balances</span>
</p>

<p>After resetting your account, you will be prompted to go through the getting-started process again.</p>

<p><span class="warning">This action cannot be undone.</span></p>

<form #resetForm="ngForm" (ngSubmit)="resetAccount(password)">
  <p>Input your password to reset your account.</p>
  <div class="password-input">
    <mat-form-field floatLabel="auto" appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput name="password" type="password" [(ngModel)]="password" required />
    </mat-form-field>
  </div>
  <div class="password-error">
    <mat-error *ngIf="badPassword">Incorrect password. Please try again.</mat-error>
  </div>
  <div class="dialog-actions">
    <button type="button" (click)="onCancel()" mat-button>Cancel</button>
    <button
      type="submit"
      mat-flat-button
      color="warn"
      [disabled]="!resetForm.form.dirty || !resetForm.form.valid || loading"
    >
      Reset Account
    </button>
  </div>
</form>
