import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BackendService } from './backend.service';
import { ResetAccountDialogComponent } from '../dialog-templates/reset-account-dialog/reset-account-dialog.component';
import { ResetBalancesDialogComponent } from '../dialog-templates/reset-balances-dialog/reset-balances-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {
  constructor(private backend: BackendService) {}

  resetAccount$(password: string) {
    return this.resetAccountReq$(password, true);
  }

  resetBalances$(password: string) {
    return this.resetAccountReq$(password, false);
  }

  private resetAccountReq$(password: string, deleteBuckets: boolean): Observable<any> {
    return this.backend.request({ type: 'post', apiRoute: 'account/reset', data: { password, deleteBuckets } });
  }
}
