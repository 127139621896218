<div [ngClass]="{ inDrawer: options.isInDrawer }" class="transaction-list" *ngIf="transactionDataSource">
  <div class="transaction-list-loading-wrapper">
    <mat-spinner
      class="transaction-list-loading"
      diameter="20"
      *ngIf="transactionDataSource.transactionsLoading$ | async"
    ></mat-spinner>
  </div>
  <cdk-virtual-scroll-viewport itemSize="49" minBufferPx="800" maxBufferPx="1200" class="transaction-list-viewport">
    <div class="transaction-list-viewport-wrapper">
      <div
        class="transaction-list-item-wrapper"
        *cdkVirtualFor="let transaction of transactionDataSource; templateCacheSize: 0"
      >
        <div class="transaction-list-header" *ngIf="transaction._objectType === 'dateHeader'">
          {{ transaction.date | date: 'longDate' }}
        </div>
        <app-transaction-list-item
          [transactionDataSource]="transactionDataSource"
          [transaction]="transaction"
          [isSelected]="transactionDataSource.isSelected$(transaction) | async"
          (selectionToggled)="transactionDataSource.toggleSelected($event)"
        ></app-transaction-list-item>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
  <div class="transaction-list-actions" *ngIf="hasSelectedTransactions$(transactionDataSource) | async">
    <ng-container *ngIf="transactionDataSource.selectedTransactions$ | async as selectedTransactions">
      <button class="transaction-list-edit" mat-icon-button (click)="openEditDialog()">
        <mat-icon svgIcon="pencil"></mat-icon>
      </button>
      <bucket-selector
        [transactions]="selectedTransactions$(transactionDataSource) | async"
        [buckets]="buckets$ | async"
        [needsSaving]="selectedBucket$ | async"
        [selectedBucket]="selectedBucket$ | async"
        (changeSelectedBucket)="changeSelectedBucket($event)"
        (saveBucket)="onSaveBucket($event, selectedTransactions)"
        (changeSuggestedBuckets)="onSuggestedBucketChange($event)"
      ></bucket-selector>
      <div class="spacer"></div>

      <span class="transaction-list-selected">{{ selectedTransactions.length }}</span>
      <button class="transaction-list-clear" mat-icon-button (click)="transactionDataSource.clearSelected()">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>
  </div>
</div>
