import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { switchMap, take } from 'rxjs/operators';
import { UserSession } from '../types/session';
import { StripePaymentDialogComponent } from '../dialog-templates/stripe-payment-dialog/stripe-payment-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

declare var Stripe: stripe.StripeStatic;

@Injectable({ providedIn: 'root' })
export class StripeService {
  constructor(private userService: UserService, private dialog: MatDialog) {}
  stripe: stripe.Stripe;
  stripeDialogRef: MatDialogRef<StripePaymentDialogComponent>;

  openStripeModal(
    action$: (token: stripe.Token) => Observable<any>,
    callback: (session: UserSession) => any,
    options?: StripeCheckoutOptions,
  ) {
    this.stripe = Stripe(environment.stripeKey);
    this.stripeDialogRef = this.dialog.open(StripePaymentDialogComponent, {
      data: {
        stripe: this.stripe,
        options: options,
        token: (token: stripe.Token) =>
          action$(token)
            .pipe(switchMap(() => this.userService.refreshSession$))
            .subscribe(session => callback(session)),
      },
    });
  }
}
