import { Injectable } from '@angular/core';
import { ArcElement, Chart, DoughnutController, Legend, Title, Tooltip } from 'chart.js';
import { ChartDataInput } from '../shared/doughnut-chart/doughnut-chart.component';
import { CategoryPage } from '../types';

Chart.register(DoughnutController, Title, Legend, ArcElement, Tooltip);

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor() {}

  categoryChartExpected(categoryPage: CategoryPage, categoryId?: string): ChartDataInput[] {
    if (!categoryId || categoryId === 'undefined')
      return [...categoryPage.data]
        .sort((a, b) => b.budgeted.BUDGET - a.budgeted.BUDGET)
        .map(c => ({ label: c.name, amount: c.budgeted.BUDGET }));

    const category = categoryPage.data.find(c => c.id === categoryId);
    return [...category.buckets].sort((a, b) => b.amount - a.amount).map(c => ({ label: c.name, amount: c.amount }));
  }

  categoryChartActual(categoryPage: CategoryPage, categoryId?: string): ChartDataInput[] {
    if (!categoryId || categoryId === 'undefined')
      return [...categoryPage.data]
        .sort((a, b) => a.balance.BUDGET - b.balance.BUDGET)
        .filter(c => c.balance.BUDGET <= 0)
        .map(c => ({ label: c.name, amount: Math.abs(c.balance.BUDGET) }));

    const category = categoryPage.data.find(c => c.id === categoryId);
    return [...category.buckets]
      .sort((a, b) => a.total - b.total)
      .filter(b => b.type === 'BUDGET' && b.total <= 0)
      .map(c => ({ label: c.name, amount: Math.abs(c.total) }));
  }
}
