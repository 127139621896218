import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CategoryPage } from '../../types';
import { DrawerService } from '../../services/drawer.service';

@Component({
  selector: 'app-budget-in-sync',
  templateUrl: './budget-in-sync.component.html',
  styleUrls: ['./budget-in-sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetInSyncComponent {
  @Input() unbudgeted: number;

  constructor(public drawerService: DrawerService) {}
}
