import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';

import { TransactionFilters } from '../../types';
import { Observable, Subject, empty } from 'rxjs';
import { take, debounceTime, map, switchMap, tap, startWith, shareReplay, delay } from 'rxjs/operators';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TransactionService } from '../../services/transaction.service';
import { TransactionDataSource } from '../transaction-data-source.class';
import { SessionService } from 'src/app/services/session.service';

export const emptyTransactionFilter = () => {
  return {
    labelIds: [],
    bucketIds: [],
    deletedOnly: false,
    unbucketedOnly: false,
  };
};

@Component({
  selector: 'app-transaction-list-toolbar',
  templateUrl: './transaction-list-toolbar.component.html',
  styleUrls: ['./transaction-list-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TransactionListToolbarComponent implements OnInit {
  @Input() transactionDataSource: TransactionDataSource;
  @Output() selectedFiltersChange: EventEmitter<TransactionFilters> = new EventEmitter();
  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;
  _merchant: Subject<string> = new Subject();
  merchant = '';

  filters$: Observable<TransactionFilters> = this.selectedFiltersChange.pipe(
    startWith(emptyTransactionFilter()),
    shareReplay(1),
  );

  hasFilters$: Observable<boolean> = this.filters$.pipe(
    map(
      filters =>
        filters.deletedOnly || filters.unbucketedOnly || filters.bucketIds.length > 0 || filters.labelIds.length > 0,
    ),
  );

  searching: boolean;
  showFilter: boolean;
  selectUnsorted: boolean;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const elementContainsEvent = this.eRef.nativeElement.contains(event.target);
    const overlayFound = event.path && event.path.find(e => e.className === 'cdk-overlay-container');
    if (!elementContainsEvent && !overlayFound && this.panel.expanded) this.panel.close();
  }

  constructor(
    private transactionService: TransactionService,
    private eRef: ElementRef,
    private sessionService: SessionService,
  ) {
    this._merchant.pipe(debounceTime(150)).subscribe(m => this.updateMerchant(m));
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.sessionService.session$.pipe(take(1)).subscribe(session => {
      if (session.notifications.unsortedTransactionsCount > 0)
        this.selectedFiltersChange.emit({ ...emptyTransactionFilter(), unbucketedOnly: true });
    });
  }

  toggleUnsortedFilter() {
    this.filters$.pipe(take(1)).subscribe(f => {
      const unbucketedOnly = !f.unbucketedOnly;
      if (unbucketedOnly) {
        this.selectUnsorted = true;
        return this.selectedFiltersChange.next({ ...f, unbucketedOnly, bucketIds: [] });
      }

      this.selectUnsorted = false;
      this.selectedFiltersChange.next({ ...f, unbucketedOnly });
    });
  }

  togglePanel() {
    this.panel.toggle();
  }

  openPanel() {
    this.panel.open();
  }

  closePanel() {
    this.panel.close();
  }

  toggleDeletedFilter() {
    this.filters$.pipe(take(1)).subscribe(f => this.selectedFiltersChange.next({ ...f, deletedOnly: !f.deletedOnly }));
  }

  updateLabelIds(labelIds: string[]) {
    this.filters$.pipe(take(1)).subscribe(f => this.selectedFiltersChange.next({ ...f, labelIds }));
  }

  updateBucketIds(bucketIds: string[]) {
    this.filters$
      .pipe(take(1))
      .subscribe(f => this.selectedFiltersChange.next({ ...f, bucketIds, unbucketedOnly: false }));
  }

  closeSearch() {
    this.searching = false;
    this.merchantChanged('');
  }

  merchantChanged(merchant: string) {
    this._merchant.next(merchant);
    this.merchant = merchant;
  }

  private updateMerchant(merchant: string) {
    this.filters$.pipe(take(1)).subscribe(f => this.selectedFiltersChange.next({ ...f, merchant }));
  }

  exportTransactions() {
    this.filters$
      .pipe(
        take(1),
        switchMap(filter => this.transactionService.downloadTransactionsRequest$(filter)),
      )
      .subscribe();
  }

  clear() {
    this.selectedFiltersChange.next(emptyTransactionFilter());
    this.merchantChanged('');
    this.selectUnsorted = false;
  }
}
