<div *ngIf="bucket" class="balance-amount">
  <span
    [ngClass]="{
      good: bucket.fund.balance > 0,
      bad: bucket.fund.balance < 0 && !bucketService.isIncomeAndWithinPaycheck(bucket),
      warn: bucketService.isIncomeAndWithinPaycheck(bucket)
    }"
  >
    <ng-container
      *ngIf="(showDecimals === false || bucket.fund.balance > 10) && showDecimals !== true; else withDecimals"
    >
      {{ bucketService.getBalance(bucket) | currency: 'USD':'symbol':'1.0-0' }}
    </ng-container>
    <ng-template #withDecimals>
      {{ bucketService.getBalance(bucket) | currency: 'USD':'symbol':'1.2-2' }}
    </ng-template>
  </span>
</div>
