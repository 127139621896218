import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UserSession } from '../types/session';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { take, map, filter, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private _session: BehaviorSubject<UserSession> = new BehaviorSubject(this.storedSession);
  session$: Observable<UserSession> = from(this._session);
  getOneSession$ = this.session$.pipe(
    take(1),
    switchMap(session => {
      if (session) return of(session);
      const params = new URLSearchParams(window.location.search);
      const jwt = params.get('jwt');
      if (!jwt) return of(null);
      return this.http
        .get<{ data: UserSession }>(`${environment.restUrl}/api/session`, {
          withCredentials: true,
          headers: { Authorization: jwt },
        })
        .pipe(
          tap(data => {
            if (!data) return;
            this.updateSession(data);
          }),
        );
    }),
  );
  hasEnabledFeature$ = featureFlag =>
    this.getOneSession$.pipe(map(session => session.enabledFeatures.includes(featureFlag)));

  trialEndsInDays$ = this.session$.pipe(
    filter(session => !!(session.account.subscription && session.account.subscription.trialEnd)),
    map(session => Math.ceil(DateTime.fromISO(session.account.subscription.trialEnd).diffNow('days').as('days'))),
  );

  unsortedTransactionsCount$ = this.session$.pipe(map(session => session.notifications.unsortedTransactionsCount));
  deauthenticatedCredentialsCount$ = this.session$.pipe(
    map(session => session.notifications.deauthenticatedCredentialsCount),
  );

  constructor(private data: DataService, private route: ActivatedRoute, private http: HttpClient) {}

  set remainLoggedIn(shouldRemainLoggedIn: boolean) {
    this.data.setLocalStorage('remainLoggedIn', shouldRemainLoggedIn, { persist: true });
  }

  get remainLoggedIn(): boolean {
    return this.data.getLocalStorage('remainLoggedIn') || false;
  }

  get storedSession(): UserSession {
    const session = this.remainLoggedIn ? this.data.getLocalStorage('session') : this.data.getSessionStorage('session');
    console.log('stored session', session);
    return session;
  }

  private clearStorage() {
    this.data.removeLocalStorage('session');
    this.data.removeSessionStorage('session');
  }

  updateSession(session) {
    this._session.next(session);
    this.storeSession(session);
  }

  private storeSession(session) {
    console.log('storing session');
    this.clearStorage();
    if (session)
      this.remainLoggedIn
        ? this.data.setLocalStorage('session', session, { persist: true })
        : this.data.setSessionStorage('session', session);
  }

  clearSession() {
    this.clearStorage();
    this._session.next(null);
  }
}
