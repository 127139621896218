import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-data-token',
  templateUrl: './data-token.component.html',
  styleUrls: ['./data-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTokenComponent {
  @Input() loading: boolean;
  @Input() label: string;
  @Input() icon: string;
  @Input() svgIcon: string;

  constructor() {}
}
