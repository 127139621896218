import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action, Bucket } from '../../types';
import { TransactionDataSource } from '../transaction-data-source.class';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-transactions-dialog',
  templateUrl: './edit-transactions-dialog.component.html',
  styleUrls: ['./edit-transactions-dialog.component.scss'],
})
export class EditTransactionsDialogComponent implements OnInit {
  selected$ = this.data.transactionDataSource.selectedTransactions$;
  selectedCount$ = this.selected$.pipe(map(selected => selected.length));

  constructor(
    public dialogRef: MatDialogRef<EditTransactionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transactionDataSource: TransactionDataSource;
    },
  ) {}

  ngOnInit() {}

  transactionListEvent(action: Action) {
    this.dialogRef.close();
    if (action.type === 'CANCEL') return;
    this.data.transactionDataSource.dispatch(action);
    this.data.transactionDataSource.clearSelected();
  }
}
