<ul class="accounts-list">
  <li *ngFor="let account of accounts">
    <form
      class="update-account-details"
      #updateAccountDetailsForm="ngForm"
      (ngSubmit)="addAccountDetailsSave(credentialId, account, updateAccountDetailsForm)"
      autocomplete="off"
    >
      <mat-expansion-panel [expanded]="expanded === account.id" (opened)="openAccount(account)">
        <mat-expansion-panel-header>
          <div class="flex-row">
            <span *ngIf="account.nickname"> {{ account.nickname }} </span>
            <span *ngIf="!account.nickname"> {{ account.name }} | {{ account.mask }} </span>
            <div class="spacer"></div>
            <span> {{ account.balance.current | currency: 'USD':'symbol':'1.2-2' }} </span>
          </div>
        </mat-expansion-panel-header>

        <span *ngIf="account.nickname">Account: {{ account.name }} | {{ account.mask }}</span> <br />
        <span>Account Details: {{ account.officialName }}</span> <br />
        <span>Account Type: {{ account.subtype | titlecase }}</span>
        <div class="flex-row">
          <mat-form-field>
            <input
              matInput
              name="nickname"
              type="text"
              placeholder="Account Nickname"
              [(ngModel)]="accountModel.nickname"
            />
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-slide-toggle name="syncDisabled" [(ngModel)]="accountModel.syncDisabled">
            Disable syncing for this account
          </mat-slide-toggle>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <input
              name="syncStartDate"
              matInput
              [minDate]="minimumDate$ | async"
              [min]="minimumDate$ | async"
              [matDatepicker]="picker"
              placeholder="Start date for transactions syncing"
              [disabled]="accountModel.syncDisabled"
              [(ngModel)]="accountModel.syncStartDate"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error> Cannot sync before {{ minimumDate$ | async | date: 'MM/dd/yyyy' }} </mat-error>
          </mat-form-field>

          <div class="spacer"></div>
          <button mat-stroked-button [disabled]="!updateAccountDetailsForm?.valid" type="">Save</button>
        </div>
      </mat-expansion-panel>
    </form>
  </li>
</ul>
