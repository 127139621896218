import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { map, take, shareReplay, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MonthService {
  showSelector: boolean;
  _currentMonthYear: BehaviorSubject<string> = new BehaviorSubject(this.monthYearFromDate());
  currentMonthYear$ = from(this._currentMonthYear);
  isThisMonthCurrent$ = this.currentMonthYear$.pipe(
    map(currentMonthYear => currentMonthYear === this.monthYearFromDate()),
  );

  constructor() {}

  findMonthYear(currentMonthYear: string, months: number) {
    const date = DateTime.fromISO(currentMonthYear).plus({ months });
    return this.monthYearFromDate(date);
  }

  monthYearFromDate(date = DateTime.local()) {
    return date.toFormat('yyyy-MM');
  }

  formatMonthYear(monthYear, format) {
    return DateTime.fromISO(monthYear).toFormat(format);
  }

  setMonthYear(monthYear) {
    this._currentMonthYear.next(monthYear);
  }

  getCurrentMonthYearFromParams$(route: ActivatedRoute) {
    return route.paramMap.pipe(
      map(paramMap => paramMap.get('month')),
      map(monthYear => (monthYear ? monthYear : this.monthYearFromDate())),
      distinctUntilChanged(),
    );
  }
}
