<mat-form-field class="label-typeahead no-padding" floatLabel="auto" appearance="outline">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-chip-list #labelChipList>
    <mat-chip
      *ngFor="let labelId of selectedLabelIds"
      selectable="true"
      removable="true"
      (removed)="remove(labelId)"
      [style.backgroundColor]="(this.labelMap$ | async)[labelId].color"
    >
      {{ (this.labelMap$ | async)[labelId].name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #labelInput
      aria-label="Label"
      [(ngModel)]="currentLabelText"
      (input)="inputChanged()"
      [matAutocomplete]="labelAuto"
      [matChipInputFor]="labelChipList"
      [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
      matChipInputAddOnBlur="false"
    />
  </mat-chip-list>
  <mat-autocomplete autoActiveFirstOption #labelAuto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let label of filteredLabels$ | async" [value]="label.name">
      {{ label.name }}
    </mat-option>
    <mat-option *ngIf="canCreateLabel$(currentLabelText) | async" [value]="currentLabelText">
      <span class="italicize">Create</span> "{{ currentLabelText | lowercase }}"
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
