<app-infinite-transaction-list
  *ngIf="!hideTransactionList; else emptyList"
  [transactionDataSource]="transactionDataSource"
  [options]="{ isInDrawer: true, bucketId: bucket.id }"
></app-infinite-transaction-list>

<ng-template #emptyList>
  <div *ngIf="(accountService.credentials$ | async)?.totalRecords === 1; else viewAllOrSort" class="empty-list">
    <div class="empty-list-graphic-card">
      <img src="assets/images/emptycard.svg" alt="credit card graphic" />
    </div>
    <div class="empty-list-content">
      <p>Almost there! Link your financial<br />accounts to start budgeting.</p>
      <div class="flex-row empty-list-link-actions">
        <button mat-flat-button color="primary" [routerLink]="['/accounts']">Link Bank Account</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #viewAllOrSort>
  <div class="empty-list">
    <div class="empty-list-graphic-transactions">
      <img src="assets/images/emptytransactions.svg" alt="guy holding a coin" />
    </div>
    <div class="empty-list-content">
      <p>Transactions sorted into this<br />budget will show up here.</p>

      <div class="flex-row empty-list-actions">
        <button mat-stroked-button color="primary" [routerLink]="['/transactions']">View All</button>
        <button mat-stroked-button color="primary" [routerLink]="['/transactions/sort']">Sort</button>
      </div>
    </div>
  </div>
</ng-template>
