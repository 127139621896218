<mat-form-field
  class="select-bucket-typeahead"
  *ngIf="filteredBuckets$ | async; let filteredBuckets"
  floatLabel="auto"
  appearance="outline"
>
  <mat-label>{{ label }} </mat-label>
  <input
    type="text"
    name="selectedBudget"
    matInput
    [placeholder]="placeholder"
    [formControl]="control"
    [matAutocomplete]="auto"
    [required]="required"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let bucket of filteredBuckets" [value]="bucket.name">
      {{ bucket.name }}
      <ng-container *ngIf="options.showFundBalance">
        ({{ bucket.fund.balance | currency: 'USD':'symbol':'1.0-0' }})
      </ng-container>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
