<div class="bucket-setup-toolbar">
  <div class="bucket-setup-toolbar-content">
    <form [formGroup]="formGroup" #toolbarForm="ngForm" (ngSubmit)="finishSetup()">
      <div class="bucket-setup-toolbar-content-row">
        <mat-form-field class="no-padding" floatLabel="auto" appearance="outline" hideRequiredMarker="true">
          <mat-label *ngIf="bucket.type !== 'INCOME'">Monthly Amount </mat-label>
          <mat-label *ngIf="bucket.type === 'INCOME'">Monthly Expected </mat-label>
          <input
            matInput
            name="amount"
            type="number"
            formControlName="amount"
            (focus)="onFocus(formGroup.controls.amount)"
            (focusout)="onFocusOut(formGroup.controls.amount)"
            autocomplete="off"
            [disabled]="bucket.archivedMonth"
          />
          <span class="prefix" matPrefix>$</span>
        </mat-form-field>
      </div>
      <div class="bucket-setup-toolbar-content-row">
        <mat-form-field
          *ngIf="bucket.type !== 'INCOME'"
          class="no-padding"
          floatLabel="auto"
          appearance="outline"
          hideRequiredMarker="true"
        >
          <mat-label>Savings Goal</mat-label>
          <input
            matInput
            name="goal"
            type="number"
            formControlName="goal"
            (focus)="onFocus(formGroup.controls.goal)"
            (focusout)="onFocusOut(formGroup.controls.goal)"
            autocomplete="off"
            [disabled]="bucket.archivedMonth"
          />
          <span class="prefix" matPrefix>$</span>
        </mat-form-field>

        <button mat-button type="submit">Done</button>
      </div>
    </form>
  </div>
</div>
