import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ErrorMessagingService {
  constructor(private snackbar: MatSnackBar) {}
  backendError(response, defaultMsg: string) {
    let errorMsg = defaultMsg;
    if (response.error.errors.length > 0) {
      errorMsg += ': ' + response.error.errors[0];
    }
    this.snackbar.open(errorMsg, 'OK', {
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'top',
    });
  }
}
