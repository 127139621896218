import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormDialog } from '../../types';

@Component({
  selector: 'app-form-dialog',
  templateUrl: 'form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
})
export class FormDialogComponent {
  constructor(public dialogRef: MatDialogRef<FormDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: FormDialog) {}

  onSubmit(form) {
    this.data.onSubmit(form);
    this.dialogRef.close();
  }

  onCancel() {
    if (this.data.onCancel) this.data.onCancel();
    this.dialogRef.close();
  }
}
