import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, OnChanges } from '@angular/core';
import { TransactionDataSource } from '../transaction-data-source.class';
import { MatDialog } from '@angular/material/dialog';
import { EditTransactionsDialogComponent } from '../edit-transactions-dialog/edit-transactions-dialog.component';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Bucket, Transaction } from 'src/app/types';
import { BucketService } from 'src/app/services/bucket.service';

@Component({
  selector: 'app-infinite-transaction-list',
  templateUrl: './infinite-transaction-list.component.html',
  styleUrls: ['./infinite-transaction-list.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, OnPush here delays the change of selectedBucket, so disabling for now
})
export class InfiniteTransactionListComponent implements OnInit, OnChanges {
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  @Input() transactionDataSource: TransactionDataSource;
  @Input() options: { isInDrawer?: boolean; bucketId?: string } = {};

  buckets$ = this.bucketService.allCurrentMonthBuckets$.pipe(
    map(buckets =>
      buckets.filter(bucket => {
        if (this.options.bucketId) return bucket.id !== this.options.bucketId;
        return true;
      }),
    ),
  );

  hasSelectedTransactions$ = (dataSource: TransactionDataSource): Observable<boolean> =>
    dataSource.selectedTransactions$.pipe(
      map(selected => selected.length > 0),
      tap(hasSelected => {
        if (!hasSelected) this._selectedBucket.next(null);
      }),
    );

  selectedTransactions$ = (dataSource: TransactionDataSource): Observable<Transaction[]> =>
    dataSource.selectedTransactions$;

  _selectedBucket = new BehaviorSubject<Bucket | null>(null);
  selectedBucket$ = from(this._selectedBucket);

  constructor(private dialog: MatDialog, private bucketService: BucketService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.viewPort) this.viewPort.scrollToIndex(0);
  }

  openEditDialog() {
    this.dialog.open(EditTransactionsDialogComponent, {
      data: {
        transactionDataSource: this.transactionDataSource,
      },
      autoFocus: false,
    });
  }

  onSuggestedBucketChange(suggestedBuckets: Bucket[]) {
    if (suggestedBuckets && suggestedBuckets.length) this._selectedBucket.next(suggestedBuckets[0]);
  }

  changeSelectedBucket(bucket: Bucket) {
    this._selectedBucket.next(bucket);
  }

  onSaveBucket(bucket: Bucket, selectedTransactions) {
    const transactionIds = selectedTransactions.map(t => t.id);
    const value = {
      bucketId: bucket.id,
      ids: transactionIds,
    };

    this.transactionDataSource.clearSelected();

    return this.transactionDataSource.dispatch({
      type: 'EDIT_MANY_TRANSACTIONS',
      value,
    });
  }
}
