import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bucket-page-summary',
  templateUrl: './bucket-page-summary.component.html',
  styleUrls: ['./bucket-page-summary.component.scss'],
})
export class BucketPageSummaryComponent implements OnInit {
  @Input() bucket;
  LABELS = {
    REMAINING: {
      BUDGET: 'Remaining',
      INCOME: 'Expected',
    },
    OVER: {
      BUDGET: 'Exceeded',
      INCOME: 'Surplus',
    },
  };

  get remainingLabel() {
    return this.LABELS.REMAINING[this.bucket.type];
  }

  get remaining() {
    if (this.bucket.type === 'INCOME' && this.bucket.amount - this.bucket.total >= 0)
      return this.bucket.amount - this.bucket.total;
    if (this.bucket.type === 'INCOME' && this.bucket.amount - this.bucket.total < 0) return false;
    if (this.bucket.fund && this.bucket.fund.balance >= 0) return this.bucket.fund.balance;
    if (!this.bucket.fund && this.bucket.amount + this.bucket.total > 0) return this.bucket.amount + this.bucket.total;

    return false;
  }

  get overLabel() {
    return this.LABELS.OVER[this.bucket.type];
  }

  get over() {
    if (this.bucket.type === 'INCOME' && this.bucket.amount - this.bucket.total < 0)
      return (this.bucket.amount - this.bucket.total) * -1;
    if (this.bucket.fund && this.bucket.fund.balance < 0) return this.bucket.fund.balance * -1;
    if (!this.bucket.fund && this.bucket.amount + this.bucket.total < 0)
      return (this.bucket.amount + this.bucket.total) * -1;
    return false;
  }

  get goal() {
    if (this.bucket.type === 'INCOME') return false;
    return this.bucket.fund.goal;
  }

  get spent() {
    if (this.bucket.type !== 'BUDGET') return false;
    if (this.bucket.total >= 0) return 0;
    return this.bucket.total * -1;
  }

  get earned() {
    if (this.bucket.type !== 'INCOME') return false;
    if (this.bucket.total <= 0) return 0;
    return this.bucket.total;
  }
  constructor() {}

  ngOnInit() {}
}
