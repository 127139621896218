<div class="sort-transaction-list">
  <table mat-table [dataSource]="transactionDataSource">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.date | date: 'MM/dd' }}</td>
    </ng-container>

    <ng-container matColumnDef="merchant">
      <th mat-header-cell *matHeaderCellDef>Merchant</th>
      <td mat-cell *matCellDef="let element">
        {{ element.merchant }} <span class="pending" *ngIf="element.bankMeta?.pending">pending</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let element">{{ element.amount | currency }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['date', 'merchant', 'amount']"></tr>
    <div
      *matRowDef="let row; columns: ['date', 'merchant', 'amount']"
      class="transaction-row"
      [ngClass]="{ income: row?.netValue > 0 }"
    >
      <mat-checkbox [checked]="transactionDataSource.isSelected$(row) | async" disabled="true"></mat-checkbox>
      <mat-button-toggle type="button" (change)="handleToggle(row)" [checked]="transactionDataSource.isSelected$(row) | async">
        <tr mat-row></tr>
      </mat-button-toggle>
    </div>
  </table>

  <!-- <mat-paginator
    #paginator
    [length]="(transactionList.page$ | async).totalRecords"
    [pageIndex]="(transactionList.page$ | async).pageIndex || 0"
    [pageSize]="transactionList.options.listPageSize"
    (page)="handlePageEvent($event)"
  >
  </mat-paginator> -->
</div>
