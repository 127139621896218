<div class="configure-accounts-dialog">
  <h1 mat-dialog-title>Configure Accounts</h1>
  <div mat-dialog-content>
    <p>Select a start date for transaction syncing</p>

    <form class="update-account-details" #configureForm="ngForm" autocomplete="off">
      <mat-form-field floatLabel="auto" appearance="outline">
        <mat-label>Transaction sync start date</mat-label>
        <input
          name="syncStartDate"
          [minDate]="minimumDate$ | async"
          [min]="minimumDate$ | async"
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="syncStartDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>Cannot sync before {{ minimumDate$ | async | date: 'MM/dd/yyyy' }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button
      color="primary"
      [disabled]="!configureForm?.valid"
      mat-flat-button
      [mat-dialog-close]="syncStartDateIso"
      cdkFocusInitial
    >
      Ok
    </button>
  </div>
</div>
