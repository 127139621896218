import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Label } from '../types/label';
import { Observable, ReplaySubject, from } from 'rxjs';
import { map, take, switchMap, tap } from 'rxjs/operators';
import { Page } from '../types';
import { DataService } from './data.service';
import { AnalyticsService } from './analytics.service';
import { EVENT_CREATED_LABEL } from '../analytics/events';

@Injectable({ providedIn: 'root' })
export class LabelService {
  labelsRequest$ = this.backend
    .request({
      type: 'get',
      apiRoute: 'label',
      params: { pageNumber: 1, pageSize: 10000 },
    })
    .pipe(map((page: Page<Label>) => page.data));

  _labels: ReplaySubject<Label[]> = new ReplaySubject(1);
  labels$ = from(this._labels);

  constructor(private analytics: AnalyticsService, private backend: BackendService) {
    this.labelsRequest$.subscribe(labels => this._labels.next(labels));
  }

  createLabel$(name: string, color: string): Observable<Label> {
    return this.labels$.pipe(
      take(1),
      switchMap(labels =>
        this.backend
          .request({
            type: 'post',
            apiRoute: 'label',
            data: { name, color },
          })
          .pipe(
            tap((label: Label) => {
              this.analytics.sendEvent(EVENT_CREATED_LABEL);
              this._labels.next([...labels, label]);
            }),
          ),
      ),
    );
  }
}
