import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-transactions-page-summary',
  templateUrl: './transactions-page-summary.component.html',
  styleUrls: ['./transactions-page-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransactionsPageSummaryComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
