import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import { AuthGuard } from './auth.guard';
import { SessionService } from '../services/session.service';

@Injectable()
export class UpgradeGuard implements CanActivate {
  constructor(private router: Router, private sessionService: SessionService, private authGuard: AuthGuard) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authGuard.canActivate(next, state).pipe(
      switchMap(canActivate => {
        if (!canActivate) return of(false);
        return this.sessionService.getOneSession$.pipe(
          map(session => {
            if (session.enabledFeatures.some(feature => feature === 'bank-account-sync')) return true;
            this.router.navigate(['upgrade']);
            return false;
          }),
        );
      }),
    );
  }
}
