<mat-drawer-container
  class="drawer-container"
  [hasBackdrop]="false"
  [ngClass]="{
    'drawer-open': drawerOpen,
    'bottomsheet-open': drawer.bottomSheetOpen
  }"
>
  <mat-drawer
    [(opened)]="drawer.navOpen"
    mode="over"
    position="end"
    (openedStart)="onOpenStart()"
    (closedStart)="onCloseStart()"
  >
    <app-drawer></app-drawer>
  </mat-drawer>
  <mat-drawer-content>
    <app-header id="app-header" role="header"></app-header>
    <main id="app-body">
      <mat-sidenav-container class="sidenav-container">
        <mat-sidenav [(opened)]="nav.sideNav" mode="over">
          <app-sidenav></app-sidenav>
        </mat-sidenav>
        <mat-sidenav-content class="page-container">
          <ng-content></ng-content>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
