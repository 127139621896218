import { Component, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StripeDialog } from '../../types';

@Component({
  selector: 'app-stripe-payment-dialog',
  templateUrl: 'stripe-payment-dialog.component.html',
  styleUrls: ['./stripe-payment-dialog.component.scss'],
})
export class StripePaymentDialogComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<StripePaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StripeDialog,
  ) {}

  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;

  stripe: stripe.Stripe;
  card: stripe.elements.Element;
  cardErrors: string;

  close() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    const elements = this.data.stripe.elements();
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  async handleForm(e) {
    e.preventDefault();
    const { token, error } = await this.data.stripe.createToken(this.card);

    if (error) {
      this.cardErrors = error.message;
    } else {
      this.data.token(token);
      this.dialogRef.close();
    }
  }
}
