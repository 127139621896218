<ng-container *ngIf="categoryPage$ | async; let categoryPage">
  <div
    class="category-page-wrapper"
    [ngClass]="{ hasOverUnder: categoryPage.budgeted.INCOME - categoryPage.budgeted.BUDGET !== 0 }"
  >
    <app-budget-over-under
      *ngIf="categoryPage.budgeted.INCOME - categoryPage.budgeted.BUDGET !== 0 && !categoryService.reordering"
      class="category-page-over-under"
      [categoryPage]="categoryPage"
    ></app-budget-over-under>

    <div
      class="page category-page"
      *ngIf="categoryPage"
      [ngClass]="{
        reordering: categoryService.reordering
      }"
    >
      <app-categories-page-summary
        [categoryPage]="categoryPage"
        *ngIf="!categoryService.reordering"
      ></app-categories-page-summary>

      <app-upgrade-card
        class="category-page-upgrade"
        *ngIf="showUpgradeCard$ | async"
        [trialEndsInDays]="sessionService.trialEndsInDays$ | async"
      ></app-upgrade-card>

      <app-categories-list
        [reordering]="categoryService.reordering"
        [categories]="
          categories$ | filter: { target: 'buckets', match: 'name', value: categoryService.categorySearch } | async
        "
      ></app-categories-list>
    </div>
  </div>
  <mat-toolbar class="reordering-actions" *ngIf="categoryService.reordering">
    <div class="flex-row">
      <span>Drag to reorder budgets</span>
      <div class="spacer"></div>
      <button color="primary" mat-flat-button (click)="doneReordering()">Done</button>
    </div>
  </mat-toolbar>
</ng-container>
