import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { switchMap, take, map, filter, tap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { MonthService } from '../../services/month.service';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_OPENED_CATEGORY_SEARCH, EVENT_CHANGED_BUDGET_AMOUNT } from '../../analytics/events';
import { Subscription, Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { CategoryPage } from '../../types';
import { NgForm } from '@angular/forms';
import { DrawerService } from '../../services/drawer.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-categories-page',
  templateUrl: './categories-page.component.html',
  styleUrls: ['./categories-page.component.scss'],
})
export class CategoriesPageComponent implements OnInit, OnDestroy {
  search: boolean;
  editBudget: boolean;
  filterValue: string;
  categorySubscription: Subscription;
  monthSubscription: Subscription;
  budgetSubscription: Subscription;

  categoryPage$ = this.month.currentMonthYear$.pipe(
    switchMap((monthYear: string) => this.categoryService.categoryPage$(monthYear)),
  );

  categories$ = this.categoryPage$.pipe(
    filter(c => !!c),
    map((categoryPage: CategoryPage) => categoryPage.data),
  );

  budget: number;
  budget$ = this.categoryPage$.pipe(
    filter(c => !!c),
    map((categoryPage: CategoryPage) => categoryPage.budget),
  );

  incomeVariance$ = this.categoryPage$.pipe(
    map((categoryPage: CategoryPage) =>
      categoryPage && categoryPage.budgeted ? categoryPage.budget - categoryPage.budgeted.INCOME : 0,
    ),
  );

  remaining$ = this.categoryPage$.pipe(
    map((categoryPage: CategoryPage) => {
      if (!categoryPage || !categoryPage.budgeted) return false;
      return categoryPage.budgeted.INCOME - categoryPage.budget;
    }),
  );

  remainingAbsolute$ = this.remaining$.pipe(
    map((remaining: number) => {
      if (!remaining) return false;
      return Math.abs(remaining);
    }),
  );

  showUpgradeCard$: Observable<boolean> = this.sessionService.session$.pipe(
    map(session => {
      if (this.categoryService.categorySearch) return false;
      if (session.account.stripe?.card?.lastDigits) return false;
      if (session.account.subscription) {
        if (
          new Date(session.account.subscription?.trialEnd) < new Date() &&
          session.enabledFeatures.includes('bank-account-sync')
        )
          return false;
        return true;
      }

      if (session.account.subscription.status !== 'active' && !session.account.stripe?.card?.lastDigits) return false;

      return true;
    }),
  );

  constructor(
    private analytics: AnalyticsService,
    public categoryService: CategoryService,
    private nav: NavigationService,
    public month: MonthService,
    private route: ActivatedRoute,
    public userService: UserService,
    public drawerService: DrawerService,
    public sessionService: SessionService,
  ) {}

  ngOnInit() {
    // console.log('params in component', this.route.snapshot.routeConfig);
    this.month.showSelector = true;
    this.nav.title = 'budgets';
    this.monthSubscription = this.month
      .getCurrentMonthYearFromParams$(this.route)
      .subscribe(this.updateOnMonthChange.bind(this));
    this.budgetSubscription = this.budget$.subscribe(budget => {
      this.budget = budget;
    });
  }

  private updateOnMonthChange(monthYear: string) {
    this.month.setMonthYear(monthYear);
    if (this.categorySubscription) this.categorySubscription.unsubscribe();
    this.categorySubscription = this.categoryService.loadCategory$(monthYear).subscribe();
  }

  openSearch() {
    this.analytics.sendEvent(EVENT_OPENED_CATEGORY_SEARCH);
    this.search = true;
  }

  closeSearch() {
    this.search = !this.search;
    this.filterValue = '';
  }

  doneReordering() {
    this.categoryService.reordering = false;
    this.categoryService.loadCategory$().subscribe();
  }

  editMonthlyBudget(form: NgForm) {
    if (!form.value.budget) return;
    this.editBudget = false;
    this.categoryService.updateMonthlyBudget$(form.value.budget).subscribe(response => {
      form.resetForm();
      this.analytics.sendEvent(EVENT_CHANGED_BUDGET_AMOUNT);
    });
  }

  closeEditMonthlyBudget(form: NgForm) {
    this.editBudget = false;
    this.budget$.pipe(take(1)).subscribe(budget => form.resetForm({ budget }));
  }

  matchBudget() {
    this.editBudget = false;

    this.categoryPage$
      .pipe(
        take(1),
        switchMap((categoryPage: CategoryPage) =>
          this.categoryService.updateMonthlyBudget$(categoryPage.budgeted.INCOME),
        ),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.month.showSelector = false;
    this.monthSubscription.unsubscribe();
    this.budgetSubscription.unsubscribe();
  }
}
