import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, tap, switchMap } from 'rxjs/operators';

import { SessionService } from '../services/session.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.sessionService.getOneSession$.pipe(
      tap(session => {
        if (!session) {
          console.log({ route, state });
          console.log('redirectTo url', state.url);
          this.router.navigate(['/login', { redirectTo: state.url }]);
        }
      }),
      map(session => !!session),
    );
  }
}
