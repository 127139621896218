<div class="transaction-list-filter">
  <div class="transaction-list-filter-top">
    <mat-chip
      class="transaction-list-filter-chip"
      color="accent"
      (click)="unbucketedOnlyChange.emit($event)"
      [selected]="(filters$ | async)?.unbucketedOnly"
      [disabled]="!(transactionDataSource.unsortedTransactions$ | async)?.length"
      >Unsorted</mat-chip
    >
    <mat-chip
      class="transaction-list-filter-chip"
      color="accent"
      (click)="deletedOnlyChange.emit($event)"
      [selected]="(filters$ | async)?.deletedOnly"
      >Deleted</mat-chip
    >
    <div class="spacer"></div>
    <button mat-button [disabled]="filterEmpty$ | async" (click)="clear.emit(1)">Clear</button>
    <button mat-button (click)="doneFiltering.emit($event)">Done</button>
  </div>
  <app-label-typeahead
    canCreate="false"
    placeholder="Filter by label"
    [selectedLabelIds]="(filters$ | async)?.labelIds"
    (selectedLabelIdsChange)="selectedLabelIdsChange.emit($event)"
  ></app-label-typeahead>
  <app-bucket-typeahead
    class="no-padding"
    placeholder="Filter by budget"
    [selectedBucketIds]="(filters$ | async)?.bucketIds"
    (selectedBucketIdsChange)="selectedBucketIdsChange.emit($event)"
  >
  </app-bucket-typeahead>
</div>
