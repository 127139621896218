import { Component, OnInit, Inject } from '@angular/core';
import { DrawerService } from 'src/app/services/drawer.service';
import { HttpClient } from '@angular/common/http';
import { shareReplay, map, tap } from 'rxjs/operators';
import { DRAWER_DATA } from 'src/app/shared/injector-tokens';

@Component({
  selector: 'documentation-drawer-wrapper',
  templateUrl: './documentation-drawer-wrapper.component.html',
  styleUrls: ['./documentation-drawer-wrapper.component.scss'],
})
export class DocumentationDrawerWrapperComponent implements OnInit {
  private url = `https://cms.budgets.money/ghost/api/v3/content/pages/slug/${this.data.slug}/?key=79fe3810f85c48820dc85e041d`;
  content$ = this.http.get<any>(this.url).pipe(
    tap(res => console.log('response from ghost', res)),
    map(res => res.pages[0]),
    shareReplay(1),
  );
  slug$ = this.content$.pipe(map(content => content.slug));
  title$ = this.content$.pipe(map(content => content.title));

  constructor(
    @Inject(DRAWER_DATA) private data: { slug: string },
    private http: HttpClient,
    private drawer: DrawerService,
  ) {}

  ngOnInit(): void {}
}
