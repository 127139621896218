<div class="budget-over-under">
  <button
    class="budget-over-under-content-wrapper"
    [ngClass]="{
      good: categoryPage.budgeted.INCOME - categoryPage.budgeted.BUDGET > 0,
      bad: categoryPage.budgeted.INCOME - categoryPage.budgeted.BUDGET < 0
    }"
    mat-flat-button
    (click)="drawerService.openDocumentationDrawer('monthly-budget')"
  >
    <div class="budget-over-under-content">
      <span class="budget-over-under-title">
        <ng-container *ngIf="categoryPage.budgeted.INCOME - categoryPage.budgeted.BUDGET < 0"
          >Monthly budget exceeds income by</ng-container
        >
        <ng-container *ngIf="categoryPage.budgeted.INCOME - categoryPage.budgeted.BUDGET > 0"
          >Monthly budget is under income by</ng-container
        >
        {{ amount | currency: 'USD':'symbol':'1.2-2' }}</span
      >
      <button class="budget-over-under-icon" mat-icon-button>
        <mat-icon>info</mat-icon>
      </button>
    </div>
  </button>
</div>
