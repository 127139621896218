<mat-card *ngIf="buckets">
  <form (ngSubmit)="sortTransactionsFormSubmit()" autocomplete="off">
    <app-select-bucket-typeahead
      [buckets]="buckets"
      [(selectedBucket)]="selectedBucket"
      [required]="true"
    ></app-select-bucket-typeahead>
    <mat-form-field class="transaction-description" floatLabel="auto" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        placeholder="More information about this transaction..."
        [formControl]="descriptionControl"
        name="description"
      ></textarea>
    </mat-form-field>
    <app-label-typeahead [(selectedLabelIds)]="selectedLabelIds"></app-label-typeahead>
    <button
      class="add-transactions-button"
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="(transactionDataSource?.transactionsLoading$ | async) || selectedLength < 1 || !selectedBucket"
    >
      <span *ngIf="transactionDataSource?.transactionsLoading$ | async; else notSorting">Sorting</span>
      <ng-template #notSorting>
        <span *ngIf="selectedLength < 1">Select transactions</span>
        <span *ngIf="selectedLength > 0 && !selectedBucket">Choose a budget</span>
        <div class="flex-row" *ngIf="selectedLength > 0 && selectedBucket">
          <span>Sort</span>
          <div class="spacer"></div>
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </ng-template>
    </button>
  </form>

  <div class="suggested" *ngIf="suggestedBuckets && suggestedBuckets.length">
    <span class="hr">or</span>

    <span class="suggested-title">Quick Sort: </span>

    <ng-container *ngFor="let bucket of suggestedBuckets">
      <button
        [disabled]="transactionDataSource?.transactionsLoading$ | async"
        mat-stroked-button
        (click)="suggestionClicked(bucket.id)"
      >
        <div>{{ bucket.name }}</div>
      </button>
    </ng-container>
  </div>
</mat-card>
