<ul
  [id]="categoryId"
  class="category-bucket-list"
  cdkDropList
  [cdkDropListData]="buckets"
  [cdkDropListConnectedTo]="getConnectedList$ | async"
  (cdkDropListDropped)="bucketSortUpdate($event)"
>
  <li
    class="category-bucket-list-item"
    *ngFor="let bucket of buckets; trackBy: bucketTrackBy"
    [id]="bucket.id"
    [ngClass]="{
      archived: bucket.archivedMonth
    }"
    cdkDrag
    [cdkDragData]="bucket"
    [cdkDragDisabled]="!reorder"
  >
    <app-category-bucket-summary [reorder]="reorder" [bucket]="bucket"></app-category-bucket-summary>
    <mat-icon cdkDragHandle *ngIf="reorder" class="category-bucket-list-reorder bucket-drag-handle">reorder</mat-icon>
  </li>
</ul>
