export function byCount(arr) {
  var itm,
    a = [],
    L = arr.length,
    o = {};
  for (var i = 0; i < L; i++) {
    itm = arr[i];
    if (!itm) continue;
    if (o[itm] == undefined) o[itm] = 1;
    else ++o[itm];
  }
  for (var p in o) a[a.length] = p;
  return a.sort(function (a, b) {
    return o[b] - o[a];
  });
}
