import { TransactionPage, Transaction, Action, Bucket } from '../types';
import {
  Observable,
  Subscription,
  ReplaySubject,
  from,
  forkJoin,
  Subject,
  of,
  BehaviorSubject,
  combineLatest,
  pipe,
} from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { TransactionService } from '../services/transaction.service';
import { map, take, switchMap, withLatestFrom, tap, distinctUntilChanged, shareReplay, filter } from 'rxjs/operators';
import { BucketService } from '../services/bucket.service';

import isEqual from 'lodash.isequal';

export interface TransactionDataSourceOptions {
  withoutDateHeaders: boolean;
  loadFirstPageOnly: boolean;
}

export class TransactionDataSource extends DataSource<Transaction> {
  private subscription: Subscription;
  private bucket?: Bucket;
  private _transactionPage: ReplaySubject<TransactionPage> = new ReplaySubject(1);
  private transactionPage$: Observable<TransactionPage> = this._transactionPage.asObservable().pipe(
    distinctUntilChanged((a, b) => isEqual(a, b)),
    tap(v => console.log('emitting new value for transactionPage', v)),
    shareReplay(1),
  );
  public transactions$ = this.transactionPage$.pipe(
    filter(p => !!p && !!p.data),
    map(page => page.data.filter(t => !!t)),
  );

  public isUnbucketedOnly$ = this.transactionPage$.pipe(
    map(transactionPage => transactionPage?.params?.unbucketedOnly),
  );

  public unsortedTransactions$ = this.transactions$.pipe(map(list => list.filter(t => !t.bucketId)));

  private _reducer: Subject<Action> = new Subject();
  private _transactionsLoading: ReplaySubject<boolean> = new ReplaySubject();
  transactionsLoading$ = this._transactionsLoading.asObservable();
  private _selectedTransactionIds: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set());
  public selectedTransactions$: Observable<Transaction[]> = combineLatest([
    this.transactions$,
    this._selectedTransactionIds,
  ]).pipe(map(([transactions, selectedIds]) => transactions.filter(t => selectedIds.has(t.id))));

  private _loadingMoreTransactions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loadingMoreTransactions$ = this._loadingMoreTransactions.asObservable();

  private transactionsWithDateHeaders$: Observable<any[]> = this.transactions$.pipe(
    map(transactions => {
      return transactions.reduce((items, transaction) => {
        const previousItem = items.length === 0 ? null : items[items.length - 1];
        if (previousItem && previousItem.date === transaction.date) {
          return [...items, transaction];
        }
        return [...items, { _objectType: 'dateHeader', date: transaction.date }, transaction];
      }, []);
    }),
  );

  constructor(
    starting: TransactionPage | Bucket,
    private transactionService: TransactionService,
    private bucketService: BucketService,
    private options?: TransactionDataSourceOptions,
  ) {
    super();

    if (starting._objectType === 'bucket') {
      this.bucket = starting as Bucket;
      this._transactionPage.next(this.bucket.transactions);
    } else {
      this._transactionPage.next(starting as TransactionPage);
    }

    this._reducer.pipe(switchMap(action => this.reduce(action).pipe(take(1)))).subscribe(({ page, action }) => {
      this._transactionPage.next(page);
      console.log('action.type', action.type);

      if (action.type !== 'SCROLL_EVENT') this.transactionService.userService.refreshSession$.subscribe();
    });
  }

  connect(collectionViewer: CollectionViewer): Observable<Transaction[]> {
    this.subscription = collectionViewer.viewChange
      .pipe(map(range => range.end))
      .subscribe(end => this.dispatch({ type: 'SCROLL_EVENT', value: end }));

    if (this.options && this.options.withoutDateHeaders) {
      return this.transactions$;
    }
    return this.transactionsWithDateHeaders$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subscription.unsubscribe();
  }

  public dispatch(action: Action) {
    this._reducer.next(action);
  }

  toggleSelected({ isSelected, transaction }: { isSelected: boolean; transaction: Transaction }) {
    this._selectedTransactionIds.pipe(take(1)).subscribe(selectedIds => {
      isSelected ? selectedIds.add(transaction.id) : selectedIds.delete(transaction.id);
      this._selectedTransactionIds.next(selectedIds);
    });
  }

  clearSelected() {
    return this._selectedTransactionIds.next(new Set());
  }

  selectAll() {
    this.transactionPage$
      .pipe(
        take(1),
        map(page => this._selectedTransactionIds.next(new Set(page.data.map(t => t.id)))),
      )
      .subscribe();
  }

  isSelected$(transaction: Transaction): Observable<boolean> {
    return this._selectedTransactionIds.pipe(map(selected => selected.has(transaction.id)));
  }

  private reduce({ type, value }: Action): Observable<{ page: TransactionPage; action: Action }> {
    console.log('reducing ', type);
    const handlers = {
      SCROLL_EVENT: () => this.scrollEvent$(value as number),
      DELETE_TRANSACTIONS: () => this.deleteTransactions$(value as Transaction[]),
      DELETE_TRANSFERS: () => this.deleteTransfers$(value as any[]),
      RESTORE_TRANSACTIONS: () => this.undeleteTransactions$(value as Transaction[]),
      EDIT_TRANSACTION: () => this.editTransaction$(value as Transaction),
      EDIT_MANY_TRANSACTIONS: () => this.editManyTransactions$(value),
      REFRESH_TRANSACTIONS: () => this.refreshTransactions$(),
    };
    return handlers[type]().pipe(
      map(page => ({ page, action: { type, value } })),
      tap(() => this._transactionsLoading.next(false)),
    );
  }

  private scrollEvent$(rangeEnd: number) {
    return of(rangeEnd).pipe(
      withLatestFrom(this.transactionPage$, this.transactionsWithDateHeaders$),
      filter(() => !this.options || !this.options.loadFirstPageOnly),
      switchMap(([rangeEnd, page, listItems]) => {
        if (listItems.length <= rangeEnd && page.totalRecords > page.data.length) {
          return this.loadMoreTransactions$(page);
        }
        return of(page);
      }),
    );
  }

  private editManyTransactions$(value): Observable<TransactionPage> {
    this._transactionsLoading.next(true);
    return this.transactionPage$.pipe(
      take(1),
      switchMap(page => {
        return this.transactionService.updateMultipleTransactions(value).pipe(
          map(result => this.replaceChangedTransactions(page, value.ids, result.data)),
          switchMap(page => this.reloadLastPage$(page)),
        );
      }),
    );
  }

  private editTransaction$(transaction: Transaction): Observable<TransactionPage> {
    this._transactionsLoading.next(true);
    return this.transactionPage$.pipe(
      take(1),
      switchMap(page => {
        return this.transactionService.updateTransaction(transaction).pipe(
          map(updatedTransaction =>
            this.replaceChangedTransactions(
              page,
              [transaction.id, ...(transaction.children || []).map(t => t.id)],
              [updatedTransaction, ...(updatedTransaction.children || [])],
            ),
          ),
          switchMap(this.reloadLastPage$.bind(this)),
        );
      }),
    );
  }

  private undeleteTransactions$(transactions: Transaction[]): Observable<TransactionPage> {
    this._transactionsLoading.next(true);
    return this.transactionPage$.pipe(
      take(1),
      switchMap(page => {
        const transactionIdsToDelete = transactions.map(t => t.id);
        const newPage = { ...page, data: page.data.filter(t => transactionIdsToDelete.indexOf(t.id) === -1) };
        const requests = transactionIdsToDelete.map(id => this.transactionService.undeleteTransaction(id));
        return forkJoin(requests).pipe(switchMap(() => this.reloadLastPage$(newPage)));
      }),
    );
  }

  private deleteTransfers$(transactions: any[]): Observable<TransactionPage> {
    this._transactionsLoading.next(true);
    return this.transactionPage$.pipe(
      take(1),
      switchMap(page => {
        const idsToDelete = transactions.map(t => t.id);
        const newPage = { ...page, data: page.data.filter(t => idsToDelete.indexOf(t.id) === -1) };
        const requests = idsToDelete.map(id => this.transactionService.deleteTransfer(id));
        return forkJoin(requests).pipe(switchMap(() => this.reloadLastPage$(newPage)));
      }),
    );
  }

  private deleteTransactions$(transactions: Transaction[]): Observable<TransactionPage> {
    this._transactionsLoading.next(true);
    return this.transactionPage$.pipe(
      take(1),
      switchMap(page => {
        const idsToDelete = transactions.map(t => t.id);
        const newPage = { ...page, data: page.data.filter(t => idsToDelete.indexOf(t.id) === -1) };
        const requests = idsToDelete.map(id => this.transactionService.deleteTransaction(id));
        return forkJoin(requests).pipe(switchMap(() => this.reloadLastPage$(newPage)));
      }),
    );
  }

  private refreshTransactions$(): Observable<TransactionPage> {
    return this.transactionPage$.pipe(
      take(1),
      switchMap(page => this.reloadLastPage$(page)),
    );
  }

  private reloadLastPage$(page: TransactionPage): Observable<TransactionPage> {
    if (this.bucket) {
      return this.bucketService.loadBucket$(this.bucket.id, this.bucket.monthYear).pipe(
        tap(b => (this.bucket = b)),
        map(b => b.transactions),
      );
    }
    const newPageNumber = page.pageNumber - 1;
    return this.loadMoreTransactions$({
      ...page,
      pageNumber: newPageNumber,
      data: page.data.slice(0, newPageNumber * page.pageSize),
    });
  }

  private loadMoreTransactions$(page: TransactionPage): Observable<TransactionPage> {
    if (this.bucket) {
      return of(page);
    }
    this._loadingMoreTransactions.next(true);
    this._transactionsLoading.next(true);
    return this.transactionService
      .loadTransactions({
        pageNumber: page.pageNumber + 1,
        pageSize: page.pageSize,
        ...page.params,
      })
      .pipe(
        map(newPage => ({ ...newPage, data: [...page.data, ...newPage.data] })),
        tap(() => this._loadingMoreTransactions.next(false)),
        tap(() => this._transactionsLoading.next(false)),
      );
  }

  // Assumes sort is always descending by date+id.
  private replaceChangedTransactions(
    page: TransactionPage,
    oldTransactionIds: string[],
    newTransactions: Transaction[],
  ): TransactionPage {
    const idsToRemove = oldTransactionIds;
    let transactionsToAdd = newTransactions
      .filter(t => !t.parent && this.transactionService.matchesFilters(t, page.params))
      .sort((t1, t2) => (`${t1.date}${t1.id}` < `${t2.date}${t2.id}` ? 1 : -1));

    const updatedList = page.data
      .filter(t => idsToRemove.indexOf(t.id) === -1)
      .reduce((curr, t) => {
        const toAdd = [] as Transaction[];
        [...transactionsToAdd].forEach(t2 => {
          if (`${t.date}${t.id}` <= `${t2.date}${t2.id}`) {
            toAdd.push(t2);
            transactionsToAdd = transactionsToAdd.filter(t => t.id !== t2.id);
          }
        });
        return [...curr, ...toAdd, t];
      }, []);
    return { ...page, data: updatedList };
  }
}
