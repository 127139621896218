import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap, take, tap, delay } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { LabelService } from '../../services/label.service';
import { Label } from '../../types/label';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_ADDED_LABEL, EVENT_REMOVED_LABEL } from '../../analytics/events';

const COLORS = [
  '#E6B0AA',
  '#F5B7B1',
  '#D7BDE2',
  '#D2B4DE',
  '#A9CCE3',
  '#AED6F1',
  '#A3E4D7',
  '#A2D9CE',
  '#A9DFBF',
  '#ABEBC6',
  '#F9E79F',
  '#FAD7A0',
  '#F5CBA7',
  '#EDBB99',
];

@Component({
  selector: 'app-label-typeahead',
  templateUrl: './label-typeahead.component.html',
  styleUrls: ['./label-typeahead.component.scss'],
})
export class LabelTypeaheadComponent implements OnInit {
  labelCtrl: FormControl = new FormControl();
  @Input() selectedLabelIds: string[] = [];
  @Input() canCreate = true;
  @Input() placeholder = 'Labels';
  @Input() keepOpenOnSelect = false;
  @Output()
  selectedLabelIdsChange = new EventEmitter<string[]>();
  _filteredLabels: ReplaySubject<Label[]> = new ReplaySubject(1);
  filteredLabels$: Observable<Label[]> = this._filteredLabels.asObservable();
  separatorKeyCodes: number[] = [];
  @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  currentLabelText = '';

  constructor(private analytics: AnalyticsService, private labelsService: LabelService) {
    this.labelsService.labels$.pipe(take(1)).subscribe(l => this._filteredLabels.next(l));
  }

  inputChanged() {
    this.labelsService.labels$
      .pipe(take(1))
      .subscribe(labels =>
        this._filteredLabels.next(
          labels.filter(
            label =>
              (this.currentLabelText === '' ||
                label.name.toLowerCase().indexOf(this.currentLabelText.toLowerCase()) !== -1) &&
              this.selectedLabelIds.indexOf(label.id) === -1,
          ),
        ),
      );
  }

  labelMap$: Observable<{}> = this.labelsService.labels$.pipe(
    map(labels =>
      labels.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    ),
  );

  private addLabelId(labelId: string): void {
    this.selectedLabelIds.push(labelId);
    this.selectedLabelIdsChange.emit(this.selectedLabelIds);
    this.analytics.sendEvent(EVENT_ADDED_LABEL);
  }

  private removeLabelId(labelId: string) {
    const index = this.selectedLabelIds.indexOf(labelId);
    if (index > -1) this.selectedLabelIds.splice(index, 1);
    this.selectedLabelIdsChange.emit(this.selectedLabelIds);
    this.analytics.sendEvent(EVENT_REMOVED_LABEL);
  }

  createAndAddLabel$(name: string): Observable<void> {
    const color = COLORS[Math.floor(Math.random() * COLORS.length)];
    return this.labelsService.createLabel$(name, color).pipe(map(label => this.addLabelId(label.id)));
  }

  canCreateLabel$(value: string): Observable<boolean> {
    const term = (value || '').trim().toLowerCase();
    return this.labelsService.labels$.pipe(
      map(
        labels =>
          this.canCreate === true && term !== '' && labels.map(label => label.name.toLowerCase()).indexOf(term) === -1,
      ),
    );
  }

  remove(labelId: string): void {
    if (labelId) {
      this.removeLabelId(labelId);
    }
  }

  private clearInput() {
    this.currentLabelText = '';
    this.labelInput.nativeElement.value = '';
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const selectedName = event.option.value.trim().toLowerCase();
    this.labelsService.labels$
      .pipe(
        take(1),
        switchMap(labels => {
          this.clearInput();
          const label = labels.find(label => label.name.trim().toLowerCase() === selectedName);
          if (label) {
            return of(this.addLabelId(label.id));
          }
          return this.createAndAddLabel$(selectedName);
        }),
      )
      .subscribe(l => {
        this.inputChanged();
        if (this.keepOpenOnSelect) {
          setTimeout(() => this.autoTrigger.openPanel(), 0);
        }
      });
  }

  ngOnDestroy() {}

  ngOnInit() {}
}
