import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { CategoriesPageComponent } from '../categories/categories-page/categories-page.component';

import { AddBucketComponent } from '../buckets/add-bucket/add-bucket.component';
import { GettingStartedGuard } from './getting-started.guard';
import { UpgradeGuard } from './upgrade.guard';
import { AdminGuard } from './admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/budgets',
    pathMatch: 'full',
  },
  {
    path: 'budgets',
    component: CategoriesPageComponent,
    canActivate: [GettingStartedGuard],
    pathMatch: 'full',
  },
  {
    path: 'bucket',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add',
        component: AddBucketComponent,
      },
    ],
  },
  {
    path: 'transactions',
    canActivate: [AuthGuard],
    data: {
      goBackTo: '/budgets',
    },
    loadChildren: () => import('../transactions/transactions.module').then(m => m.TransactionsModule),
  },
  {
    path: 'accounts',
    canActivate: [UpgradeGuard],
    data: {
      goBackTo: '/budgets',
    },
    loadChildren: () => import('../accounts/accounts.module').then(m => m.AccountsModule),
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    data: {
      goBackTo: '/budgets',
    },
    loadChildren: () => import('../user-account/user-account.module').then(m => m.UserAccountModule),
  },
  {
    path: 'upgrade',
    canActivate: [AuthGuard],
    data: {
      goBackTo: '/budgets',
    },
    loadChildren: () => import('../upgrade/upgrade.module').then(m => m.UpgradeModule),
  },
  {
    path: 'getting-started',
    canActivate: [AuthGuard],
    data: {
      goBackTo: '/budgets',
    },
    loadChildren: () => import('../getting-started/getting-started.module').then(m => m.GettingStartedModule),
  },
  {
    path: 'login',
    loadChildren: () => import('../login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    data: {
      goBackTo: '/budgets',
    },
    loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule),
  },
  { path: '**', redirectTo: '/budgets' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'corrected' })],
  exports: [RouterModule],
  providers: [AuthGuard, GettingStartedGuard, UpgradeGuard, AdminGuard],
})
export class AppRoutingModule {}
