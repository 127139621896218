import { Component, Input } from '@angular/core';
import { Bucket } from '../../types';
import { BucketService } from '../../services/bucket.service';

@Component({
  selector: 'app-balance-amount',
  templateUrl: './balance-amount.component.html',
  styleUrls: ['./balance-amount.component.scss'],
})
export class BalanceAmountComponent {
  @Input() bucket: Bucket;
  @Input() showDecimals: boolean;

  constructor(public bucketService: BucketService) {}
}
