<div class="data-token" [ngClass]="{ hasLabel: label, hasIcon: icon || svgIcon }">
  <span class="data-token-label" *ngIf="label">{{ label }}</span>
  <span class="data-token-content" *ngIf="!loading; else loadingContent"><ng-content></ng-content></span>
  <span class="data-token-icon" *ngIf="icon"
    ><mat-icon>{{ icon }}</mat-icon></span
  >
  <span class="data-token-icon" *ngIf="svgIcon"><mat-icon [svgIcon]="svgIcon"></mat-icon></span>
</div>

<ng-template #loadingContent> Loading... </ng-template>
