import { Injectable } from '@angular/core';
import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BackendRequest } from '../types';
import { saveAs } from 'file-saver';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, throwError, of, empty } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, timeout, retry, tap, switchMap } from 'rxjs/operators';
import { SessionService } from './session.service';
import { UserSession } from '../types/session';
import { DeviceService } from './device.service';

@Injectable({ providedIn: 'root' })
export class BackendService {
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,

    private sessionService: SessionService,
    private device: DeviceService,
  ) {}

  request(requestData: BackendRequest): Observable<any> {
    return this.sessionService.getOneSession$.pipe(switchMap(session => this.request$(requestData, session)));
  }

  private request$(requestData: BackendRequest, session: UserSession) {
    const { type, apiRoute, data = {}, params = null, authed = true, timeoutMillis = 5000 } = requestData;

    if (params) for (const key in params) if (params[key] === null || params[key] === undefined) params[key] = '';

    const options = {
      params: new HttpParams({ encoder: new CustomHttpParamEncoder(), fromObject: params }),
      withCredentials: true,
      headers: { Authorization: session?.jwt || '' },
    };

    const requestTypes = {
      get: () => this.http.get(`${environment.restUrl}/api/${apiRoute}`, options),
      patch: () => this.http.patch(`${environment.restUrl}/api/${apiRoute}`, data, options),
      post: () => this.http.post(`${environment.restUrl}/api/${apiRoute}`, data, options),
      update: () => {
        if (data.id) return this.http.put(`${environment.restUrl}/api/${apiRoute}/${data.id}`, data, options);
        return this.http.put(`${environment.restUrl}/api/${apiRoute}`, data, options);
      },
      delete: () => this.http.request('delete', `${environment.restUrl}/api/${apiRoute}`, { body: data, ...options }),
    };
    return requestTypes[type]().pipe(
      tap(response => {
        console.group(`Backend Request ${type}`);
        console.log('API Route: ', apiRoute);
        if (params) console.log('Params: ', params);
        if (data) {
          const { password, ...prunedData } = data; // remove password from anything logged in data
          console.log('Data: ', prunedData);
        }
        console.log('Response: ', JSON.parse(JSON.stringify(response)));
        console.groupEnd();
      }),
      timeout(timeoutMillis),
      retry(1),
      catchError(error => this.onError(error, authed)),
    );
  }

  onError(error, authed) {
    if (authed && error.status === 401) {
      console.log('redirecting to login');
      this.sessionService.clearSession();
      this.router.navigate(['/login']);
    }
    if (error.status === 400) {
      this.snackBar.open(error.error.errors[0], 'OK', {
        verticalPosition: 'top',
        duration: 5000,
        panelClass: 'top',
      });
    }
    if (error.status === 0 || error.status >= 500) {
      this.snackBar.open('There was an error connecting with the server. Please try again later.', 'OK', {
        verticalPosition: 'top',
        duration: 5000,
        panelClass: 'top',
      });
    }
    return throwError(error);
  }

  download$(requestData: BackendRequest) {
    return this.sessionService.getOneSession$.pipe(
      switchMap(session => {
        const { type, apiRoute, data = {}, params = null, authed = true } = requestData;

        const options = {
          params: this.sanitizeParams(params),
          withCredentials: true,
          headers: { Authorization: session.jwt || '' },
          responseType: 'blob' as 'blob',
        };

        const downloadRequestTypes = {
          find: () => this.http.get(`${environment.restUrl}/api/${apiRoute}`, options),
          get: () => this.http.get(`${environment.restUrl}/api/${apiRoute}`, options),
          create: () => this.http.post(`${environment.restUrl}/api/${apiRoute}`, data, options),
        };

        if (this.device.isAndroidApp) {
          console.log('downloading as android app');
          // @ts-ignore
          Android.download(`${environment.restUrl}/api/${apiRoute}`, session.jwt || '', 'budgets-transactions.csv');
          return empty();
        }

        if (this.device.isIosApp) {
          console.log('downloading as ios app');
          // @ts-ignore
          window.webkit.messageHandlers.download.postMessage({
            url: `${environment.restUrl}/api/${apiRoute}`,
            jwt: session.jwt || '',
            fileName: `budgets-transactions-${new Date().getTime()}.csv`,
          });
          return empty();
        }

        return downloadRequestTypes[type]().pipe(
          tap(response => saveAs(response, 'budgets-transactions')),
          timeout(5000),
          retry(1),
          catchError(error => this.onError(error, authed)),
        );
      }),
    );
  }

  private sanitizeParams(params) {
    if (!params) return {};
    for (const key in params) if (params[key] === null || params[key] === undefined) params[key] = '';
    return params;
  }
}

// Src: https://medium.com/better-programming/how-to-fix-angular-httpclient-not-escaping-url-parameters-ddce3f9b8746
// Issue: https://github.com/angular/angular/issues/18261
class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
