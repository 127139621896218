import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { BucketService } from '../../services/bucket.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { MonthService } from '../../services/month.service';
import { map, catchError } from 'rxjs/operators';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_CREATED_BUDGET } from '../../analytics/events';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-bucket',
  templateUrl: './add-bucket.component.html',
  styleUrls: ['./add-bucket.component.scss'],
})
export class AddBucketComponent implements OnInit {
  loading: boolean;
  bucket: any = {
    type: 'BUDGET',
    fund: {
      start: this.month.monthYearFromDate(),
      initialAmount: 0,
    },
  };

  @Input() public categoryId;
  @Output() public createdBucketId: EventEmitter<string> = new EventEmitter();

  categorySelect$ = this.categoryService.categories$().pipe(
    map(categories => {
      const categorySelect = [...categories.map(category => ({ ...category }))];
      const last = categorySelect.pop();
      last.id = ' ';
      categorySelect.unshift(last);
      return categorySelect;
    }),
  );

  constructor(
    private analytics: AnalyticsService,
    private bucketService: BucketService,
    private categoryService: CategoryService,
    private month: MonthService,
  ) {}

  ngOnInit() {
    this.categoryId ? (this.bucket.categoryId = this.categoryId) : (this.bucket.categoryId = ' ');
  }

  addBucketFormSubmit(form: NgForm) {
    if (form.invalid) return;
    const bucket = this.bucket;
    this.loading = true;
    if (bucket.categoryId === ' ') delete bucket.categoryId;
    bucket.fund.start = this.month.monthYearFromDate();
    bucket.isSetup = true;
    this.bucketService.addBucket(bucket).subscribe(
      bucket => {
        this.categoryService.loadCategory$().subscribe();
        this.loading = false;
        this.analytics.sendEvent(EVENT_CREATED_BUDGET);
        this.createdBucketId.emit(bucket.id);
      },
      e => {
        this.loading = false;
        throw e;
      },
    );
  }
}
