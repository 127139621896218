import { Component, Input, ChangeDetectionStrategy, OnInit, Output } from '@angular/core';
import { Bucket } from '../../types';
import { CategoryService } from '../../services/category.service';
import { MonthService } from '../../services/month.service';
import { BucketService } from '../../services/bucket.service';
import { take, switchMap } from 'rxjs/operators';
import { DrawerService } from '../../services/drawer.service';

@Component({
  selector: 'app-bucket',
  templateUrl: './bucket.component.html',
  styleUrls: ['./bucket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BucketComponent implements OnInit {
  open: boolean;
  deleting: boolean;

  @Input() bucket: Bucket;

  selectedIndex = 0;

  constructor(
    private categoryService: CategoryService,
    private bucketService: BucketService,
    public month: MonthService,
    private drawer: DrawerService,
  ) {}

  bucketDeleted() {
    this.categoryService.loadCategory$(this.bucket.monthYear).subscribe(d => this.drawer.closeDrawer());
  }

  deleteBucket() {
    this.deleting = true;
    this.bucketService
      .deleteBucket$(this.bucket.id)
      .pipe(switchMap(() => this.categoryService.loadCategory$(this.bucket.monthYear)))
      .subscribe(() => this.drawer.closeDrawer());
  }

  ngOnInit() {}

  update({ success } = { success: false }) {
    console.log('success', success);
    if (success) this.selectedIndex = 0;
    this.categoryService.loadCategory$(this.bucket.monthYear).subscribe();
    this.bucketService
      .loadBucket$(this.bucket.id, this.bucket.monthYear)
      .pipe(take(1))
      .subscribe();
  }
}
