<div class="chart-wrapper" [ngClass]="{preview: preview}">
    <h5 class="chart-title-text" *ngIf="title">{{title}}</h5>
    <div [hidden]="!data" class="chart" [style.height.px]="height" [style.width.px]="width">
        <div *ngIf="!preview && showTitle" class="chart-title-wrapper" [style.width.px]="width"
            [style.height.px]="height">
            <h4 class="chart-title-total">{{ total | currency: "USD":"symbol":"1.0-0"}}</h4>
        </div>
        <canvas #ctx></canvas>
    </div>
    <chart-legend *ngIf="!preview && showLegend && data" [selectedIndex]="hoveredIndex" [items]="legendItems">
    </chart-legend>

    <div *ngIf="!data">
        <div class="empty-state">
            <mat-icon>data_usage</mat-icon>
            <div>{{emptyTitle}}</div>
        </div>
    </div>
</div>