import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, Subject } from 'rxjs';
import { BackendService } from '../services/backend.service';
import { DataService } from '../services/data.service';

import { map, tap, filter, take, switchMap } from 'rxjs/operators';
import { CategoryPage, Bucket } from '../types';
import { MonthService } from './month.service';
import { ActivatedRoute } from '@angular/router';

interface CategoryData {
  [s: string]: CategoryPage;
}

@Injectable({ providedIn: 'root' })
export class CategoryService {
  _categoryData: BehaviorSubject<CategoryData> = new BehaviorSubject(
    this.data.getLocalStorageByFilter((key: string) => key.startsWith('category')) || {},
  );
  categoryData$: Observable<CategoryData> = from(this._categoryData);
  categoryPage$ = (monthYear = this.month.monthYearFromDate()) =>
    this.categoryData$.pipe(
      map((categoryData: CategoryData) => categoryData[`category/${monthYear}`]),
      filter(d => !!d),
    );

  currentMonthCategoryPage$ = this.month.currentMonthYear$.pipe(switchMap(this.categoryPage$));

  categorySearch = '';

  categories$ = (monthYear = this.month.monthYearFromDate()) =>
    this.categoryPage$(monthYear).pipe(map((categoryPage: CategoryPage) => categoryPage.data));

  currentMonthCategories$ = this.month.currentMonthYear$.pipe(switchMap(this.categories$));

  bucket$ = (bucketId: string, month: string) =>
    this.categoryPage$(month).pipe(
      map(page => ([] as Bucket[]).concat(...page.data.map(c => c.buckets)).find(b => b.id === bucketId)),
    );

  categoryBalance$ = (categoryId: string) =>
    this.currentMonthCategories$.pipe(
      map(data => {
        if (!categoryId || categoryId === 'undefined')
          return data.reduce((acc, c) => acc + c.fundBalance.BUDGET + c.fundBalance.INCOME, 0);
        const { fundBalance } = data.find(c => c.id === categoryId);
        return fundBalance.INCOME + fundBalance.BUDGET;
      }),
    );

  categoryBudgeted$ = (categoryId: string) =>
    this.currentMonthCategories$.pipe(
      map(data => {
        if (!categoryId || categoryId === 'undefined') return data.reduce((acc, c) => acc + c.budgeted.BUDGET, 0);
        const { budgeted } = data.find(c => c.id === categoryId);
        return budgeted.BUDGET;
      }),
    );

  reordering: boolean;
  activePanel: string | boolean;

  categoriesRequest$ = (monthYear: string) =>
    this.backend
      .request({
        type: 'get',
        apiRoute: monthYear && monthYear !== this.month.monthYearFromDate() ? `category/${monthYear}` : 'category',
      })
      .pipe(tap((categoryPage: CategoryPage) => this.setCategoryPage(monthYear, categoryPage)));

  ignoreIncomeMismatchRequest$ = this.backend.request({
    type: 'update',
    apiRoute: 'account/warnings/ignore/budget-income-mismatch',
  });

  constructor(
    private backend: BackendService,
    private data: DataService,
    private month: MonthService,
    private route: ActivatedRoute,
  ) {}

  reloadCurrentMonthCategories$ = this.month
    .getCurrentMonthYearFromParams$(this.route)
    .pipe(switchMap(monthYear => this.categoriesRequest$(monthYear)));

  loadCategory$(monthYear = '') {
    return this.categoriesRequest$(monthYear);
  }

  setCategoryPage(monthYear: string, categoryPage: CategoryPage) {
    this.categoryData$.pipe(take(1)).subscribe((categoryData: CategoryData) => {
      if (!monthYear) monthYear = this.month.monthYearFromDate();
      categoryData[`category/${monthYear}`] = categoryPage;
      this._categoryData.next(categoryData);
      this.data.setLocalStorage(`category/${monthYear}`, categoryPage);
    });
  }

  ignoreIncomeMismatch$() {
    return this.ignoreIncomeMismatchRequest$.pipe(switchMap(() => this.loadCategory$()));
  }

  updateMonthlyBudget$(budget: number) {
    return this.month.currentMonthYear$.pipe(
      take(1),
      switchMap(monthYear =>
        this.backend.request({
          type: 'update',
          apiRoute: 'account/budget',
          data: {
            id: monthYear,
            budget,
          },
        }),
      ),
      switchMap(({ monthYear }) => this.loadCategory$(monthYear)),
    );
  }
}
