import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TransactionFilters } from '../../types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TransactionDataSource } from '../transaction-data-source.class';

@Component({
  selector: 'app-transaction-list-filter',
  templateUrl: './transaction-list-filter.component.html',
  styleUrls: ['./transaction-list-filter.component.scss'],
})
export class TransactionListFilterComponent implements OnInit {
  @Input() transactionDataSource: TransactionDataSource;
  @Input() filters$: Observable<TransactionFilters>;
  filterEmpty$: Observable<boolean>;
  @Output() doneFiltering = new EventEmitter();
  @Output() selectedLabelIdsChange = new EventEmitter();
  @Output() selectedBucketIdsChange = new EventEmitter();
  @Output() deletedOnlyChange = new EventEmitter();
  @Output() unbucketedOnlyChange = new EventEmitter();
  @Output() clear = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.filterEmpty$ = this.filters$.pipe(
      map(
        filter =>
          filter.bucketIds.length == 0 && filter.labelIds.length === 0 && !filter.deletedOnly && !filter.unbucketedOnly,
      ),
    );
  }
}
