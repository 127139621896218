<div class="split-transaction">
  <mat-form-field *ngIf="transaction.parent" floatLabel="auto" appearance="outline">
    <mat-label>Total Transaction Amount</mat-label>
    <span matPrefix>$&nbsp;</span>
    <input matInput type="number" name="amount" placeholder="Total Transaction Amount" min="0.01" step="0.01"
      [(ngModel)]="transaction.parent.amount" pattern="^[0-9]+(\.[0-9][0-9]?)?$" />
  </mat-form-field>

  <mat-card class="split-transaction-item" *ngFor="let child of children; let i = index">
    <app-select-bucket-typeahead [buckets]="buckets" [selectedBucket]="getBucket(child.bucketId)"
      (selectedBucketChange)="child.bucketId = $event.id" [required]="true">
    </app-select-bucket-typeahead>

    <div class="flex-row">
      <mat-form-field floatLabel="auto" appearance="outline">
        <mat-label>Amount</mat-label>
        <span class="prefix" matPrefix>$</span>
        <input matInput type="number" name="amount" placeholder="Amount" min="0.01" step="0.01"
          [(ngModel)]="child.amount" pattern="^[0-9]+(\.[0-9][0-9]?)?$" />
      </mat-form-field>
      <mat-form-field floatLabel="auto" appearance="outline">
        <mat-label>Percentage</mat-label>
        <input matInput type="number" name="child.percent" placeholder="Percentage" step="1"
          (blur)="setValueFromPercent(i, $event.target.value)" [value]="percent(child)" />
        <span class="suffix" matSuffix>%</span>
      </mat-form-field>
    </div>

    <div class="flex-row">
      <mat-form-field class="split-transaction-description no-padding" floatLabel="auto" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput rows="1" placeholder="Description..." [(ngModel)]="child.description"
          name="description"></textarea>
      </mat-form-field>

      <button *ngIf="this.children.length > 1" mat-icon-button type="button" color="warn" (click)="deleteSplit(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-card>
  <div class="flex-row">
    <button color="accent" mat-flat-button type="button" class="split-transaction-add" (click)="addSplit()">
      Add Split <mat-icon class="material-icons">call_split</mat-icon>
    </button>
  </div>

  <div class="flex-row split-transaction-actions">
    <span *ngIf="diff > 0">Over by {{ diff | currency: 'USD':'symbol':'1.2-2' }}</span>
    <span *ngIf="diff < 0">Under by {{ diff * -1 | currency: 'USD':'symbol':'1.2-2' }}</span>
    <div class="spacer"></div>

    <button mat-stroked-button type="button" (click)="cancelSplit()">Cancel</button>
    <button color="primary" [disabled]="totalAmount !== originalTransactionAmount"
      class="split-transaction-actions-save" mat-flat-button type="button" (click)="saveSplit()">
      Save
    </button>
  </div>
</div>