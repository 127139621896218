import { Component, OnInit, OnDestroy, InjectionToken, Injector } from '@angular/core';
import { DrawerService } from '../../services/drawer.service';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { distinctUntilChanged } from 'rxjs/operators';
import isEqual from 'lodash.isequal';
import { DRAWER_DATA } from 'src/app/shared/injector-tokens';

@Component({
  selector: 'drawer-content',
  templateUrl: './drawer-content.component.html',
  styleUrls: ['./drawer-content.component.scss'],
})
export class DrawerContentComponent implements OnInit, OnDestroy {
  portal: ComponentPortal<any>;

  constructor(private drawerService: DrawerService, private _injector: Injector) {}

  ngOnInit() {
    this.drawerService.currentComponent$.pipe(distinctUntilChanged((a, b) => isEqual(a, b))).subscribe((component) => {
      this.portal = new ComponentPortal(component.type, null, this.createInjector(component.data));
    });
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(DRAWER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  ngOnDestroy() {}
}
