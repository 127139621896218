import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerTemplateDirective } from '../directives/drawer-template.directive';
import { DrawerComponent } from '../drawer/drawer/drawer.component';
import { DrawerContentComponent } from '../drawer/drawer-content/drawer-content.component';
import { BottomSheetDrawerComponent } from '../bottom-templates/bottom-sheet-drawer/bottom-sheet-drawer.component';
import { BucketDrawerWrapperComponent } from './drawer-wrappers/bucket-drawer-wrapper/bucket-drawer-wrapper.component';
import { SharedModule } from '../shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { BucketsModule } from '../buckets/buckets.module';
import { DrawerHeaderComponent } from './drawer-header/drawer-header.component';
import { AddBucketDrawerWrapperComponent } from './drawer-wrappers/add-bucket-drawer-wrapper/add-bucket-drawer-wrapper.component';
import { CategoriesModule } from '../categories/categories.module';
import { AccountDetailsDrawerWrapperComponent } from './drawer-wrappers/account-details-drawer-wrapper/account-details-drawer-wrapper.component';
import { AccountsModule } from '../accounts/accounts.module';
import { UnbudgetedDrawerWrapperComponent } from './drawer-wrappers/unbudgeted-drawer-wrapper/unbudgeted-drawer-wrapper.component';
import { CategoriesPieChartDrawerWrapperComponent } from './drawer-wrappers/categories-pie-chart-drawer-wrapper/categories-pie-chart-drawer-wrapper.component';
import { CustomAccountDrawerWrapperComponent } from './drawer-wrappers/custom-account-drawer-wrapper/custom-account-drawer-wrapper.component';
import { DocumentationDrawerWrapperComponent } from './drawer-wrappers/documentation-drawer-wrapper/documentation-drawer-wrapper.component';

@NgModule({
  declarations: [
    DrawerTemplateDirective,
    DrawerComponent,
    DrawerContentComponent,
    BottomSheetDrawerComponent,
    BucketDrawerWrapperComponent,
    DrawerHeaderComponent,
    AddBucketDrawerWrapperComponent,
    AccountDetailsDrawerWrapperComponent,
    UnbudgetedDrawerWrapperComponent,
    CategoriesPieChartDrawerWrapperComponent,
    CustomAccountDrawerWrapperComponent,
    DocumentationDrawerWrapperComponent,
  ],
  exports: [DrawerComponent],
  imports: [CommonModule, SharedModule, DragDropModule, PortalModule, BucketsModule, CategoriesModule, AccountsModule],
})
export class DrawerModule {}
