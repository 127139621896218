<div class="bucket-drawer-wrapper" *ngIf="bucket$ | async; let bucket" [ngClass]="{ setup: !bucket.isSetup }">
  <app-drawer-header [title]="bucket.name">
    <div *ngIf="bucket.isSetup; else setup" class="bucket-balance">
      <span class="bucket-balance-label" *ngIf="!bucketService.isIncomeAndBehind(bucket); else expectedLabel">
        Balance:
      </span>
      <ng-template #expectedLabel>
        <span class="bucket-balance-label">
          Expected:
        </span>
      </ng-template>
      <app-balance-amount [bucket]="bucket" [showDecimals]="true"></app-balance-amount>
    </div>
    <ng-template #setup>
      <button class="edit-bucket-name-button" mat-stroked-button (click)="editName = true">
        Rename
      </button>
      <div class="edit-bucket-name no-padding" *ngIf="editName">
        <form #renameBucketForm="ngForm" (ngSubmit)="renameBucket(renameBucketForm, bucket)">
          <mat-form-field floatLabel="auto" appearance="outline" hideRequiredMarker="true">
            <mat-label> Budget Name </mat-label>
            <input
              matInput
              name="newBucketName"
              type="text"
              [(ngModel)]="bucket.name"
              placeholder="Budget Name"
              maxlength="24"
              [autofocus]
              required
            />
          </mat-form-field>
          <button class="edit-bucket-name-submit" mat-stroked-button type="submit" color="primary">
            Save
          </button>
        </form>
      </div>
    </ng-template>
  </app-drawer-header>
  <div class="bucket-wrapper">
    <app-bucket [bucket]="bucket"></app-bucket>
  </div>
</div>
