import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { AllTransactionsViewComponent } from './all-transactions-view/all-transactions-view.component';
import { UnbudgetedTransactionsViewComponent } from './unbudgeted-transactions-view/unbudgeted-transactions-view.component';
import { DeletedTransactionsViewComponent } from './deleted-transactions-view/deleted-transactions-view.component';
import { SortTransactionsPageComponent } from './sort-transactions-page/sort-transactions-page.component';
import { SortTransactionsListComponent } from './sort-transactions-list/sort-transactions-list.component';
import { SortTransactionsFormComponent } from './sort-transactions-form/sort-transactions-form.component';
import { TransactionsPageSummaryComponent } from './transactions-page-summary/transactions-page-summary.component';
import { InfiniteTransactionListComponent } from './infinite-transactions-list/infinite-transaction-list.component';
import { TransactionListItemComponent } from './transaction-list-item/transaction-list-item.component';
import { TransactionListToolbarComponent } from './transaction-list-toolbar/transaction-list-toolbar.component';
import { TransactionListFilterComponent } from './transaction-list-filter/transaction-list-filter.component';
import { BucketTypeaheadComponent } from './bucket-typeahead/bucket-typeahead.component';
import { EditTransactionListItemComponent } from './edit-transaction-list-item/edit-transaction-list-item.component';
import { TransactionListItemContentComponent } from './transaction-list-item-content/transaction-list-item-content.component';

@NgModule({
  imports: [CommonModule, SharedModule, TransactionsRoutingModule],
  declarations: [
    AllTransactionsViewComponent,
    UnbudgetedTransactionsViewComponent,
    DeletedTransactionsViewComponent,
    SortTransactionsPageComponent,
    SortTransactionsListComponent,
    SortTransactionsFormComponent,
    TransactionsPageSummaryComponent,
    TransactionListFilterComponent,
    TransactionListItemComponent,
    TransactionListToolbarComponent,
    InfiniteTransactionListComponent,
    BucketTypeaheadComponent,
    EditTransactionListItemComponent,
    TransactionListItemContentComponent,
  ],
  exports: [InfiniteTransactionListComponent],
  providers: [],
})
export class TransactionsModule {}
