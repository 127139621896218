<div class="unbudgeted-drawer-wrapper" *ngIf="categoryService.categoryPage$() | async; let categoryPage">
  <app-drawer-header title="Available Balance">
    <div class="unbudgeted-drawer-wrapper-balance">
      <span class="unbudgeted-drawer-wrapper-balance-label">Balance:</span>
      <span
        [ngClass]="{
          good: categoryPage.unbudgeted?.unbudgeted > 0,
          bad: categoryPage.unbudgeted?.unbudgeted < 0
        }"
      >
        {{ categoryPage.unbudgeted?.unbudgeted | currency: 'USD':'symbol':'1.2-2' }}</span
      >
    </div>
  </app-drawer-header>
  <div class="unbudgeted-drawer-wrapper-content">
    <ng-container *ngIf="categoryPage.unbudgeted?.unbudgeted === 0">
      <mat-icon [color]="'primary'">check_circle_outline</mat-icon>
      <p>
        Budgets is in sync with your accounts.
        <br /><br />
        If your budget balances ever get out of sync with your accounts, you will be able to transfer balance here to
        help get things back in sync.
      </p>
    </ng-container>

    <p *ngIf="categoryPage.unbudgeted?.unbudgeted > 0">
      Your synced accounts have more money than what your budget currently expects. You can transfer from your
      Unbudgeted balance to your budgets.
    </p>

    <p *ngIf="categoryPage.unbudgeted?.unbudgeted < 0">
      Your synced accounts have less money than what your budget currently expects. Please transfer from your budgets to
      the Unbudgeted balance.
    </p>

    <app-transfer-budget
      *ngIf="categoryPage.unbudgeted?.unbudgeted !== 0"
      [options]="{
        initialAmount: getInitialAmount(categoryPage),
        hideTransferIn: categoryPage.unbudgeted?.unbudgeted > 0,
        hideTransferOut: categoryPage.unbudgeted?.unbudgeted < 0
      }"
      (transferSuccess)="handleTransferSuccess($event)"
    ></app-transfer-budget>
  </div>
</div>
