import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { ConfirmDialogComponent } from './dialog-templates/confirm-dialog/confirm-dialog.component';

import { appIcons, budgetIcons } from './icon-registry';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService } from './services/analytics.service';
import { SessionService } from './services/session.service';
import { UserService } from './services/user.service';
import { DeviceService } from './services/device.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private analytics: AnalyticsService,
    private dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private sessionService: SessionService,
    private userService: UserService,
    public device: DeviceService,
    updates: SwUpdate,
  ) {
    // ** Note: Button to reload for update is in the categories page summary component
    if (environment.serviceWorker) updates.checkForUpdate();

    appIcons.forEach(icon =>
      this.iconRegistry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/app/${icon}.svg`)),
    );

    budgetIcons.forEach(icon =>
      this.iconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/budget/${icon}.svg`),
      ),
    );
  }

  ngOnInit() {
    this.sessionService.session$.subscribe(session =>
      session && session.user ? this.analytics.setUser(session.user) : this.analytics.setUser(null),
    );
    if (this.sessionService.storedSession) this.userService.refreshSession$.subscribe();
  }
}
