<mat-card class="edit-transaction">
  <button class="transaction-list-item-button" disableRipple="true" mat-button (click)="closeEditTransaction.emit()">
    <mat-icon class="transaction-list-item-expand">expand_less</mat-icon>
    <transaction-list-item-content
      *ngIf="transaction"
      [transaction]="transaction"
      [transactionDataSource]="transactionDataSource"
      (selectedBucketChange)="selectedBucketChange($event)"
    ></transaction-list-item-content>
  </button>

  <form
    class="edit-transaction-content"
    (ngSubmit)="submitUpdate(transactionModel)"
    autocomplete="off"
    #editTransactionForm="ngForm"
  >
    <mat-form-field class="description no-padding" floatLabel="auto" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        rows="3"
        placeholder="More information about this transaction..."
        [(ngModel)]="transactionModel.description"
        name="description"
      ></textarea>
    </mat-form-field>

    <app-label-typeahead [(selectedLabelIds)]="transactionModel.labelIds"></app-label-typeahead>

    <div class="flex-row">
      <button mat-button color="accent" (click)="openEditDialog()">More</button>
      <div class="spacer"></div>
      <button
        mat-stroked-button
        type="button"
        [disabled]="loading"
        (click)="onTransactionUpdatedEvent({ type: 'CANCEL' })"
      >
        Cancel
      </button>
      <button mat-flat-button color="primary" type="submit" class="edit-transaction-save" [disabled]="loading">
        Update
      </button>
    </div>
  </form>
</mat-card>
