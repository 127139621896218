import { Component, Input, ChangeDetectionStrategy, OnChanges, ViewEncapsulation } from '@angular/core';
import { BucketProgress } from '../../types';

@Component({
  selector: 'app-bucket-progress',
  templateUrl: './bucket-progress.component.html',
  styleUrls: ['./bucket-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BucketProgressComponent implements OnChanges {
  @Input() bucket;
  progress: BucketProgress;

  constructor() {}

  ngOnChanges() {
    this.progress = this.getProgress(this.bucket);
  }

  getProgress(bucket): BucketProgress {
    if (bucket.fund.goal) bucket.type = 'SAVINGS'; //Convert to savings type when a goal exists
    const options = {
      SAVINGS: ({ fund: { balance, goal } }) => ({
        percent: (balance / goal) * 100,
      }),
      BUDGET: ({ amount, fund, total }) => {
        if (fund.balance > -1 && fund.balance < 1) return { color: 'primary', percent: 0 };
        if (fund.balance <= amount * -1) return { color: 'warn', percent: 100 };
        if (fund.balance <= -1) {
          const percent = ((fund.balance * -1) / amount) * 100;
          return { color: 'warn', percent };
        }
        const percent = ((total * -1) / (fund.balance + total * -1)) * 100;
        const color = fund.balance < 0 ? 'warn' : total * -1 > amount ? 'accent' : 'primary';
        return {
          percent: 100 - Math.abs(percent),
          color,
        };
      },
      INCOME: ({ total, amount, fund }) => ({
        color:
          fund.balance < 0 && fund.balance >= amount * -1 ? 'accent' : fund.balance < amount * -1 ? 'warn' : 'primary',
        percent: (total / amount) * 100,
      }),
    };

    const bucketProgress = options[bucket.type](bucket);

    return {
      percent: 0,
      color: 'primary',
      ...bucketProgress,
    };
  }
}
