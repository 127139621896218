import { TransactionPage, Action, Transaction, TransactionListOptions } from '../types';
import { Observable, forkJoin, of } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { TransactionService } from '../services/transaction.service';
import { DateTime } from 'luxon';
import { SelectionModel } from '@angular/cdk/collections';
import { map, take, switchMap, tap } from 'rxjs/operators';

export class TransactionList {
  loading = {
    moreTransactions: false,
    reloading: false,
  };
  selection = new SelectionModel<Transaction>(true, []);
  transactionsPage$: Observable<TransactionPage>;
  defaultOptions = {
    listPageSize: 10,
    autoFillRows: false,
    selectable: false,
  };
  transactions$: Observable<Transaction[]>;
  constructor(
    public page$: Observable<TransactionPage>,
    private transactionService: TransactionService,
    public options?: TransactionListOptions,
  ) {
    this.options = { ...this.defaultOptions, ...options };
    this.transactionsPage$ = this.page$.pipe(
      map(page => this.transactionsArrayFilledToPageSize(page, this.options.listPageSize)),
    );
    this.transactions$ = this.transactionsPage$.pipe(map(({ data }) => data));
  }

  reduce({ type, value }: Action): Observable<TransactionPage> {
    const handlers = {
      'PAGE-EVENT': (): Observable<TransactionPage> => {
        const { pageSize, pageIndex, length } = value as PageEvent;
        return this.page$.pipe(
          take(1),
          switchMap(page => {
            const transactionsLength = page.data.length;
            const isLastPageOfDataLoaded = transactionsLength - pageSize === pageIndex * pageSize;
            const nextDataPageNumber = page.data.length / page.pageSize + 1;
            if (!isLastPageOfDataLoaded || nextDataPageNumber % 1 !== 0) {
              return of(page);
            }
            this.loading.moreTransactions = true;
            return this.transactionService
              .loadTransactions({
                pageNumber: nextDataPageNumber,
                pageSize: page.pageSize,
                ...page.params,
              })
              .pipe(
                map((loadedPage: TransactionPage) => {
                  page.data = page.data.concat(loadedPage.data);
                  this.loading.moreTransactions = false; // LOADING STATE
                  return page;
                }),
              );
          }),
        );
      },
      'DELETE-TRANSACTIONS': (): Observable<TransactionPage> => {
        this.loading.reloading = true;
        const transactions: Transaction[] = value;
        return this.page$.pipe(
          take(1),
          switchMap(page => {
            const lastPageNumberOfDataLoaded = Math.floor(page.data.length / page.pageSize);
            const indexOfLastPageOfDataLoaded = (lastPageNumberOfDataLoaded - 1) * page.pageSize;
            page.transactionAmountDiff = 0;
            const deleteRequests = transactions.map(transaction => {
              page.transactionAmountDiff = page.transactionAmountDiff + this.findTransactionAmountDiff(transaction);
              const index = page.data.findIndex(current => current.id === transaction.id);
              page.data.splice(index, 1);
              return this.transactionService.deleteTransaction(transaction.id);
            });
            return forkJoin(deleteRequests).pipe(
              switchMap(() =>
                this.reduce({
                  type: 'RELOAD-LAST-PAGE',
                  value: {
                    page,
                    lastPageNumberOfDataLoaded,
                    indexOfLastPageOfDataLoaded,
                  },
                }),
              ),
            );
          }),
        );
      },
      'DELETE-TRANSFERS': (): Observable<TransactionPage> => {
        this.loading.reloading = true;
        const transfers: any[] = value;
        return this.page$.pipe(
          take(1),
          switchMap(page => {
            const lastPageNumberOfDataLoaded = Math.floor(page.data.length / page.pageSize);
            const indexOfLastPageOfDataLoaded = (lastPageNumberOfDataLoaded - 1) * page.pageSize;
            page.transactionAmountDiff = 0;
            const deleteRequests = transfers.map(transfer => {
              page.transactionAmountDiff = page.transactionAmountDiff + this.findTransactionAmountDiff(transfer);
              const index = page.data.findIndex(current => current.id === transfer.id);
              page.data.splice(index, 1);
              return this.transactionService.deleteTransfer(transfer.id);
            });
            return forkJoin(deleteRequests).pipe(
              switchMap(() =>
                this.reduce({
                  type: 'RELOAD-LAST-PAGE',
                  value: {
                    page,
                    lastPageNumberOfDataLoaded,
                    indexOfLastPageOfDataLoaded,
                  },
                }),
              ),
            );
          }),
        );
      },
      'RESTORE-TRANSACTIONS': (): Observable<TransactionPage> => {
        this.loading.reloading = true;
        const transactions: Transaction[] = value;
        return this.page$.pipe(
          take(1),
          switchMap(page => {
            const lastPageNumberOfDataLoaded = Math.floor(page.data.length / page.pageSize);
            const indexOfLastPageOfDataLoaded = (lastPageNumberOfDataLoaded - 1) * page.pageSize;
            page.transactionAmountDiff = 0;
            const undeleteRequests = transactions.map(transaction => {
              page.transactionAmountDiff = page.transactionAmountDiff + this.findTransactionAmountDiff(transaction);
              const index = page.data.findIndex(current => current.id === transaction.id);
              page.data.splice(index, 1);
              return this.transactionService.undeleteTransaction(transaction.id);
            });
            return forkJoin(undeleteRequests).pipe(
              switchMap(() =>
                this.reduce({
                  type: 'RELOAD-LAST-PAGE',
                  value: {
                    page,
                    lastPageNumberOfDataLoaded,
                    indexOfLastPageOfDataLoaded,
                  },
                }),
              ),
            );
          }),
        );
      },
      'EDIT-TRANSACTIONS': (): Observable<TransactionPage> => {
        this.loading.reloading = true;
        const transactions: Transaction[] = value;
        return this.page$.pipe(
          take(1),
          switchMap(page => {
            const lastPageNumberOfDataLoaded = Math.floor(page.data.length / page.pageSize);
            const indexOfLastPageOfDataLoaded = (lastPageNumberOfDataLoaded - 1) * page.pageSize;
            console.log({
              lastPageNumberOfDataLoaded,
              indexOfLastPageOfDataLoaded,
            });
            page.transactionAmountDiff = 0;
            const editRequests = transactions.map(transaction => {
              return this.transactionService.updateTransaction(transaction).pipe(
                map((updatedTransaction: Transaction) => {
                  page.expanded = null;
                  return this.updatePageWithTransaction(updatedTransaction, page);
                }),
              );
            });
            return forkJoin(editRequests).pipe(
              switchMap(() =>
                this.reduce({
                  type: 'RELOAD-LAST-PAGE',
                  value: {
                    page,
                    lastPageNumberOfDataLoaded,
                    indexOfLastPageOfDataLoaded,
                  },
                }),
              ),
            );
          }),
        );
      },
      'EDIT-MULTIPLE-TRANSACTIONS': (): Observable<TransactionPage> => {
        this.loading.reloading = true;
        return this.page$.pipe(
          take(1),
          switchMap(page => {
            const lastPageNumberOfDataLoaded = Math.floor(page.data.length / page.pageSize);
            const indexOfLastPageOfDataLoaded = (lastPageNumberOfDataLoaded - 1) * page.pageSize;
            console.log({
              lastPageNumberOfDataLoaded,
              indexOfLastPageOfDataLoaded,
            });
            page.transactionAmountDiff = 0;

            return this.transactionService.updateMultipleTransactions(value).pipe(
              switchMap(() =>
                this.reduce({
                  type: 'RELOAD-LAST-PAGE',
                  value: {
                    page,
                    lastPageNumberOfDataLoaded,
                    indexOfLastPageOfDataLoaded,
                  },
                }),
              ),
            );
          }),
        );
      },
      'ROW-CLOSED': (): Observable<TransactionPage> => {
        return this.page$.pipe(
          map(page => {
            if (page.expanded === value.id) page.expanded = null;
            return page;
          }),
        );
      },
      'ROW-OPENED': (): Observable<TransactionPage> => {
        return this.page$.pipe(
          map(page => {
            page.expanded = value.id;
            return page;
          }),
        );
      },
      'TABLE-DESTROYED': (): Observable<TransactionPage> => {
        return this.page$.pipe(
          map(page => {
            page.expanded = null;
            return page;
          }),
        );
      },
      'RELOAD-LAST-PAGE': (): Observable<TransactionPage> => {
        this.loading.reloading = true;
        const { page, lastPageNumberOfDataLoaded, indexOfLastPageOfDataLoaded } = value;
        return this.transactionService
          .loadTransactions({
            pageNumber: lastPageNumberOfDataLoaded,
            pageSize: page.pageSize,
            ...page.params,
          })
          .pipe(
            map((loadedPage: TransactionPage) => {
              page.data.splice(indexOfLastPageOfDataLoaded, page.data.length, ...loadedPage.data);
              delete page.expanded;
              page.totalRecords = loadedPage.totalRecords;
              this.selection.clear();
              this.loading.reloading = false;
              return page;
            }),
          );
      },
    };
    return handlers[type]().pipe(
      take(1),
      tap(page => console.log('Transaction List Action: ', type, value, page)),
    );
  }

  isIncome(row) {
    if (!row) {
      return false;
    }
    return row.netValue > 0;
  }

  toggleSelection(row) {
    this.selection.toggle(row);
  }

  isSelected(row) {
    return !!this.selection.selected.find(selection => JSON.stringify(selection) === JSON.stringify(row));
  }

  private updatePageWithTransaction(updatedTransaction: Transaction, page: TransactionPage) {
    let transactions = [updatedTransaction];
    if (updatedTransaction.children) {
      const children = updatedTransaction.children.filter(child => child.id !== updatedTransaction.id);
      transactions = [...children];
    }
    transactions.forEach(transaction => {
      const index = page.data.findIndex(current => current && current.id === transaction.id);
      const dateTime = DateTime.fromISO(transaction.date);
      transaction.date = dateTime.toISODate();
      const shouldRemove =
        index !== -1 &&
        (page.data[index].bucketId !== transaction.bucketId || // different budget
          dateTime.toFormat('yyyy-MM') !== DateTime.fromISO(page.data[index].date).toFormat('yyyy-MM')); // different month

      if (shouldRemove) {
        page.transactionAmountDiff = page.transactionAmountDiff + this.findTransactionAmountDiff(transaction);
        page.data.splice(index, 1);
      } else if (index !== -1) {
        page.transactionAmountDiff =
          page.transactionAmountDiff + this.findTransactionAmountDiff(page.data[index], transaction);
        page.data[index] = updatedTransaction;
      }
      return page;
    });
  }

  private transactionsArrayFilledToPageSize(page, pageSize) {
    if (!this.options.autoFillRows) return page;
    const count = page.data.length;
    if (count < pageSize) {
      const numberToAdd = pageSize - count;
      page.data = page.data.concat(Array(numberToAdd));
    }
    if (count % pageSize !== 0) {
      const numberToAdd = pageSize - (count % pageSize);
      page.data = page.data.concat(Array(numberToAdd));
    }
    return page;
  }

  private findTransactionAmountDiff(current: Transaction, next?: Transaction): number {
    if (!next) return current.netValue * -1;
    if (current.type === 'EXPENSE' && next.type === 'EXPENSE') return current.amount - next.amount;
    if (current.type === 'INCOME' && next.type === 'INCOME') return next.amount - current.amount;
    if (current.type === 'EXPENSE' && next.type === 'INCOME') return current.amount + next.amount;
    if (current.type === 'INCOME' && next.type === 'EXPENSE') return current.amount * -1 - next.amount;
  }
}
