<button
  class="month-selector-previous"
  type="button"
  [disabled]="currentMonthYear <= (startMonthYear$ | async)"
  mat-raised-button
  (click)="previousMonth()"
  aria-label="select previous month"
>
  <mat-icon>keyboard_arrow_left</mat-icon>
</button>
<button class="month-selector-toggle" type="button" mat-raised-button (click)="todaysMonth()">
  {{ month.formatMonthYear(currentMonthYear, 'MMM yy') }}
</button>
<button
  class="month-selector-next"
  type="button"
  mat-raised-button
  (click)="nextMonth()"
  aria-label="select next month"
>
  <mat-icon>keyboard_arrow_right</mat-icon>
</button>
