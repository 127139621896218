import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Bucket, Transaction } from '../../types';
import { TransactionService } from '../../services/transaction.service';
import { BucketService } from '../../services/bucket.service';
import { DateTime } from 'luxon';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_CREATED_TRANSACTION } from '../../analytics/events';

@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss'],
})
export class AddTransactionComponent implements OnInit {
  @Input() bucket: Bucket;
  @Output() transactionAdded = new EventEmitter();

  loading: boolean;

  transaction: any = {
    type: 'EXPENSE',
    date: DateTime.local().toISODate(),
    bucketId: null,
    labelIds: [],
  };
  buckets: Bucket[];

  constructor(
    private analytics: AnalyticsService,
    public bucketService: BucketService,
    public transactionService: TransactionService,
  ) {}

  ngOnInit() {
    this.transaction.bucketId = this.bucket.id;
  }

  addTransactionFormSubmit(form) {
    this.loading = true;
    this.transactionService.addTransaction(this.transaction).subscribe(transaction => {
      this.loading = false;
      this.transactionAdded.emit(this.bucket);
      this.analytics.sendEvent(EVENT_CREATED_TRANSACTION);
      form.resetForm({
        type: 'EXPENSE',
        date: DateTime.local().toISODate(),
        bucketId: this.bucket.id,
        labelIds: [],
      });
    });
  }
}
