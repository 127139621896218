import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { DrawerService } from '../services/drawer.service';

@Component({
  selector: 'app-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss'],
  // tslint:disable-next-line:use-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class PageTemplateComponent {
  drawerOpen: boolean;
  constructor(public nav: NavigationService, public drawer: DrawerService) {}
  onOpenStart() {
    this.drawer.dragDistance = 0;
    this.drawerOpen = true;
  }
  onCloseStart() {
    this.drawerOpen = false;
    this.drawer.closeDrawer();
  }
}
