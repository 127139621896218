import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { NavigationService } from '../../services/navigation.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  constructor(private nav: NavigationService, public userService: UserService, private bottomSheet: MatBottomSheet) {}

  ngOnInit() {}

  navigateTo(location: string[]) {
    if (this.bottomSheet._openedBottomSheetRef) this.bottomSheet._openedBottomSheetRef.dismiss();
    this.nav.sideNav = false;
    return this.nav.navigateTo(location);
  }

  logout() {
    this.nav.sideNav = false;
    this.userService.logoutRequest$.subscribe();
  }
}
