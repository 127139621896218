import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { StripeService } from '../../services/stripe.service';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_ACCOUNT_UPGRADED } from '../../analytics/events';
import { SubscriptionService } from '../../services/subscription.service';
import { ScriptService } from '../../services/script.service';
import { DrawerService } from 'src/app/services/drawer.service';

@Component({
  selector: 'app-upgrade-card',
  templateUrl: './upgrade-card.component.html',
  styleUrls: ['./upgrade-card.component.scss'],
})
export class UpgradeCardComponent implements OnInit {
  @Input() trialEndsInDays: number;

  loading = true;

  constructor(
    private device: DeviceService,
    private stripe: StripeService,
    private analytics: AnalyticsService,
    private subscription: SubscriptionService,
    private script: ScriptService,
    private drawer: DrawerService,
  ) {}

  ngOnInit() {
    if (!this.device.isIosApp) {
      this.script.loadStripeSource().subscribe(() => {
        this.loading = false;
      });
    }
  }

  handlePayment() {
    if (this.device.isIosApp) return this.drawer.openDocumentationDrawer('upgrade-with-ios');
    this.loading = true;
    this.stripe.openStripeModal(
      token => this.subscription.createSubscription$(token.id),
      session => {
        this.analytics.sendEvent(EVENT_ACCOUNT_UPGRADED);
      },
      {
        closed: () => (this.loading = false),
        newUpgrade: true,
      },
    );
  }
}
