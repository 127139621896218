<div class="bucket-progress">
  <mat-progress-spinner
    class="top-spinner"
    mode="determinate"
    [value]="progress.percent"
    [color]="progress.color"
    strokeWidth="14"
  ></mat-progress-spinner>
  <mat-progress-spinner
    class="background-spinner"
    mode="determinate"
    value="100"
    [color]="progress.color"
    strokeWidth="14"
  ></mat-progress-spinner>
</div>
