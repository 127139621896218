import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Transaction, Bucket } from 'src/app/types';
import { BucketService } from 'src/app/services/bucket.service';
import { TransactionService } from 'src/app/services/transaction.service';

import { TransactionDataSource } from '../transaction-data-source.class';

@Component({
  selector: 'transaction-list-item-content',
  templateUrl: './transaction-list-item-content.component.html',
  styleUrls: ['./transaction-list-item-content.component.scss'],
})
export class TransactionListItemContentComponent implements OnInit, OnChanges {
  @Input() transaction: Transaction;
  @Input() transactionDataSource: TransactionDataSource;
  @Output() public selectedBucketChange = new EventEmitter();
  buckets$ = this.bucketService.allCurrentMonthBuckets$;

  selectedBucket: Bucket;

  needsSaving = false;

  editingInTransactionList$ = this.transactionService.editingInTransactionList$;

  constructor(private bucketService: BucketService, public transactionService: TransactionService) {}

  ngOnInit(): void {
    this.setup();
  }

  ngOnChanges() {
    this.setup();
  }

  setup() {
    if (this.transaction.bucketId) {
      this.bucketById$(this.transaction.bucketId).subscribe(bucket => this.changeSelectedBucket(bucket));
      return;
    }

    // If the bucket already has a selected bucket, we don't want to change it
    if (this.transaction.selectedBucket) return;

    if (this.transaction.suggestedBucketIds)
      this.bucketById$(this.transaction.suggestedBucketIds[0]).subscribe(bucket => this.changeSelectedBucket(bucket));

    this.needsSaving = !this.transaction.bucketId && !!this.transaction.suggestedBucketIds;
  }

  bucketById$(bucketId: string) {
    return this.bucketService.getBucketById$(bucketId);
  }

  changeSelectedBucket(bucket: Bucket) {
    this.selectedBucket = bucket;
    this.needsSaving =
      this.transaction.bucketId !== this.selectedBucket?.id ||
      (!this.transaction.bucketId && !!this.transaction.suggestedBucketIds);
    this.selectedBucketChange.emit(bucket);
  }

  onSaveBucket(bucket: Bucket) {
    const transactionModel = {
      ...this.transaction,
      bucketId: bucket.id,
    };
    this.needsSaving = false;

    if (this.transaction.selectedBucket) transactionModel.bucketId = this.transaction.selectedBucket.id;

    return this.transactionDataSource.dispatch({
      type: 'EDIT_TRANSACTION',
      value: transactionModel,
    });
  }
}
