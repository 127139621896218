<div class="spend-summary" *ngIf="bucket">
  <span *ngIf="spent !== false">
    <span [ngClass]="{ bold: spent < 0, green: spent < 0 }">{{
      unsignedBucketTotal | currency: 'USD':'symbol':'1.0-2'
    }}</span>
    /
    {{ bucket.amount | currency: 'USD':'symbol':'1.0-2' }}
    <ng-container *ngIf="bucket?.fund?.goal > 0">
      <ng-container *ngIf="monthsLeft < 12 && monthsLeft > 0"> ({{ monthsLeft }} months)</ng-container>
      <ng-container *ngIf="monthsLeft >= 12 && monthsLeft < 960">
        ({{ yearsLeft }} {{ yearsLeft === 1 ? 'year' : 'years' }})</ng-container
      >
    </ng-container>
  </span>

  <span *ngIf="earned !== false">
    {{ earned | currency: 'USD':'symbol':'1.0-2' }} / {{ bucket.amount | currency: 'USD':'symbol':'1.0-2' }}
  </span>
  <ng-content></ng-content>
</div>
