<div class="page">
  <form class="edit-bucket" #editBucketForm="ngForm" [formGroup]="formGroup" autocomplete="off">
    <div class="flex-row">
      <mat-form-field class="no-padding" floatLabel="auto" appearance="outline" hideRequiredMarker="true">
        <mat-label>Budget Name</mat-label>
        <input matInput name="name" type="text" formControlName="name" maxlength="24"
          [disabled]="bucket.archivedMonth || !(month.isThisMonthCurrent$ | async)" required />
      </mat-form-field>
    </div>

    <div class="flex-row" *ngIf="bucket.type !== 'INCOME'">
      <mat-form-field class="edit-bucket-goal no-padding" floatLabel="always" appearance="outline">
        <mat-label>Goal Amount</mat-label>
        <span matPrefix>$&nbsp;</span>
        <input formControlName="goal" matInput placeholder="0.00" type="number" min="0.00" step="0.00" name="goal"
          [disabled]="bucket.archivedMonth || !(month.isThisMonthCurrent$ | async)" />
      </mat-form-field>
    </div>

    <div class="flex-row actions" *ngIf="!bucket.archivedMonth && (month.isThisMonthCurrent$ | async)">
      <button *ngIf="canDeleteBucket(bucket)" mat-stroked-button color="warn" type="button"
        (click)="deleteBucket(bucket)">
        Delete
      </button>
      <button *ngIf="!canDeleteBucket(bucket)" mat-stroked-button color="warn" type="button"
        (click)="confirmArchiveBucket(bucket)">
        Archive/Delete
      </button>
    </div>
    <div class="flex-row" *ngIf="bucket.archivedMonth">
      <button mat-stroked-button color="warn" type="button" (click)="unarchiveBucket(bucket)">
        Unarchive
      </button>
    </div>

    <div class="note" *ngIf="!bucket.archivedMonth && !(month.isThisMonthCurrent$ | async)">
      Note: Budget settings can only be updated in the current month.
    </div>
  </form>
</div>