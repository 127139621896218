<div *ngIf="bucket" class="bucket-component-wrapper">
  <div *ngIf="!bucket.isSetup; else bucketComponentContent" class="bucket-setup">
    <app-bucket-setup-toolbar [bucket]="bucket"></app-bucket-setup-toolbar>
    <div class="bucket-setup-graphic">
      <img src="assets/images/setupbudgets.svg" alt="lady setting up her budgets" />
    </div>
    <div class="bucket-setup-content">
      <p *ngIf="bucket.type === 'BUDGET'">Set your monthly budget amount.</p>
      <p *ngIf="bucket.type === 'INCOME'">Set your expected monthly income amount.</p>
      <button class="bucket-setup-delete" mat-button color="warn" (click)="deleteBucket()" [disabled]="deleting">
        <ng-container *ngIf="!deleting">Nevermind, delete this budget</ng-container>
        <ng-container *ngIf="deleting">Deleting...</ng-container>
      </button>
    </div>
  </div>

  <ng-template #bucketComponentContent>
    <app-bucket-summary [bucket]="bucket"></app-bucket-summary>
    <mat-tab-group mat-stretch-tabs class="bucket-list-panel" [(selectedIndex)]="selectedIndex">
      <mat-tab class="bucket-list-panel-list">
        <ng-template mat-tab-label> <mat-icon>list</mat-icon> </ng-template>

        <div class="bucket-list-panel-wrapper">
          <app-bucket-transaction-list [bucket]="bucket" (bucketUpdated)="update($event)"></app-bucket-transaction-list>
        </div>
      </mat-tab>

      <mat-tab *ngIf="!bucket.archivedMonth" class="bucket-list-panel-add">
        <ng-template mat-tab-label> <mat-icon>add</mat-icon> </ng-template>
        <ng-template matTabContent>
          <div class="bucket-list-panel-wrapper">
            <app-add-transaction [bucket]="bucket" (transactionAdded)="update($event)"></app-add-transaction>
          </div>
        </ng-template>
      </mat-tab>

      <ng-container *ngIf="month.isThisMonthCurrent$ | async">
        <mat-tab *ngIf="bucket.fund && !bucket.archivedMonth" class="bucket-list-panel-exchange">
          <ng-template mat-tab-label> <mat-icon svgIcon="exchange"></mat-icon> </ng-template>
          <ng-template matTabContent>
            <div class="bucket-list-panel-wrapper">
              <app-transfer-budget [bucket]="bucket" (transferSuccess)="update($event)"></app-transfer-budget>
            </div>
          </ng-template>
        </mat-tab>
      </ng-container>

      <mat-tab class="bucket-list-panel-edit">
        <ng-template mat-tab-label> <mat-icon>settings</mat-icon> </ng-template>
        <ng-template matTabContent>
          <div class="bucket-list-panel-wrapper">
            <app-edit-bucket
              [bucket]="bucket"
              (bucketEdited)="update($event)"
              (bucketDeleted)="bucketDeleted()"
            ></app-edit-bucket>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
