import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Injector,
  InjectionToken,
  OnDestroy,
} from '@angular/core';
import { Bucket } from '../../types';
import { DrawerService } from '../../services/drawer.service';
import { BucketService } from '../../services/bucket.service';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import { CategoryService } from 'src/app/services/category.service';
import { switchMap, filter, debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-bucket-summary',
  templateUrl: './category-bucket-summary.component.html',
  styleUrls: ['./category-bucket-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryBucketSummaryComponent implements OnInit, OnDestroy {
  @Input() bucket: Bucket;
  @Input() reorder: boolean;
  saving = false;
  deleting = false;
  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    amount: new FormControl(0, [Validators.required, Validators.pattern('[0-9.]*')]),
  });

  subscription = new Subscription();

  constructor(
    private drawer: DrawerService,
    private categoryService: CategoryService,
    public bucketService: BucketService,
    public platform: Platform,
  ) {}

  ngOnInit() {
    this.formGroup.setValue({ amount: this.bucket.amount, name: this.bucket.name });
    this.formGroup.markAllAsTouched();
    this.subscription.add(
      this.formGroup.valueChanges
        .pipe(
          filter(() => this.formGroup.valid),
          debounceTime(200),
        )
        .subscribe(value => this.updateBucket(value)),
    );
  }

  openDrawer(bucket: Bucket) {
    this.drawer.openBucketDrawer(bucket.id);
  }

  toggleTransferOverlay() {
    this.bucket._transferOverlay = !this.bucket._transferOverlay;
    console.log(this.bucket);
  }

  deleteBucket(bucket: Bucket) {
    this.deleting = true;
    this.bucketService
      .deleteBucket$(bucket.id)
      .pipe(switchMap(() => this.categoryService.loadCategory$(bucket.monthYear)))
      .subscribe({ complete: () => (this.deleting = false) });
  }

  updateBucket({ amount, name }) {
    if (this.bucket.name === name && this.bucket.amount === amount) return;

    this.bucket.name = name;
    this.bucket.amount = amount;
    if (!this.bucket.amount) this.bucket.amount = 0;

    this.bucketService
      .editBucket$({ ...this.bucket })
      .subscribe({ complete: () => (this.saving = false), error: err => (this.saving = false) });
  }

  submitSetupBucket() {
    if (this.formGroup.invalid) return;

    this.bucket.name = this.formGroup.value.name;
    this.bucket.amount = this.formGroup.value.amount;
    if (!this.bucket.amount) this.bucket.amount = 0;
    this.saving = true;
    this.bucket.isSetup = true;
    this.bucketService.editBucket$({ ...this.bucket }).subscribe({
      complete: () => (this.saving = false),
      error: err => (this.saving = false),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
