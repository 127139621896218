import { Action } from '../../types';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_DESELECTED_TRANSACTION, EVENT_SELECTED_TRANSACTION } from '../../analytics/events';
import { TransactionDataSource } from '../transaction-data-source.class';

@Component({
  selector: 'app-sort-transactions-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sort-transactions-list.component.html',
  styleUrls: ['./sort-transactions-list.component.scss'],
})
export class SortTransactionsListComponent implements OnInit, OnDestroy {
  @Input() transactionDataSource: TransactionDataSource;

  constructor(private analytics: AnalyticsService) {}

  ngOnInit() {}

  handleToggle(row) {
    this.transactionDataSource
      .isSelected$(row)
      .pipe(take(1))
      .subscribe(isSelected => {
        if (isSelected) this.analytics.sendEvent(EVENT_DESELECTED_TRANSACTION);
        else this.analytics.sendEvent(EVENT_SELECTED_TRANSACTION);
        return this.transactionDataSource.toggleSelected({ isSelected: !isSelected, transaction: row });
      });
  }

  ngOnDestroy() {}
}
