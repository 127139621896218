import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bucket } from '../../types';
import { BucketService } from '../../services/bucket.service';
import { map, switchMap, share, shareReplay, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface ArchiveBucketData {
  bucket: Bucket;
  onSuccess: Function;
}

@Component({
  selector: 'app-archive-bucket-dialog',
  templateUrl: './archive-bucket-dialog.component.html',
  styleUrls: ['./archive-bucket-dialog.component.scss'],
})
export class ArchiveBucketDialogComponent implements OnInit {
  public balanceTransferBucket;
  balance: number;
  finishedLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ArchiveBucketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArchiveBucketData,
    private bucketService: BucketService,
  ) {
    this.bucketService
      .archiveBucketTransferredBalance(this.data.bucket.id)
      .pipe(map(res => res.balance))
      .pipe(take(1))
      .subscribe(balance => {
        this.balance = balance;
        this.finishedLoading = true;
      });
  }

  public availableBuckets$ = this.bucketService.nonArchivedBuckets$.pipe(
    map(buckets => buckets.filter(b => b.id != this.data.bucket.id)),
  );

  public archiveBucket(balanceTransferBucketId: string) {
    return this.bucketService
      .archiveBucket(this.data.bucket.id, balanceTransferBucketId)
      .pipe(
        map(() => {
          this.data.onSuccess();
          return this.dialogRef.close();
        }),
      )
      .subscribe();
  }

  public cancel() {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
