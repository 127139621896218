import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Observable, BehaviorSubject, from, of } from 'rxjs';
import { map, take, tap, filter, switchMap, shareReplay } from 'rxjs/operators';
import { CategoryService } from './category.service';
import { Bucket, Page } from '../types';
import { MonthService } from './month.service';
import { DataService } from './data.service';

type BucketData = {
  [s: string]: Bucket;
};
@Injectable({ providedIn: 'root' })
export class BucketService {
  _bucketData: BehaviorSubject<BucketData> = new BehaviorSubject(
    this.data.getLocalStorageByFilter((key: string) => key.startsWith('bucket')) || {},
  );
  bucketData$: Observable<BucketData> = from(this._bucketData);
  bucket$ = (bucketId: string, monthYear = this.month.monthYearFromDate()) =>
    this.bucketData$.pipe(
      map(bucketData => bucketData[`bucket/${bucketId}/${monthYear}`]),
      filter(b => !!b),
    );

  allBucketsRequest$: Observable<Page<Bucket>> = this.backend.request({
    type: 'get',
    apiRoute: `bucket`,
    params: { pageSize: 1000, includeArchived: true },
  });

  allCurrentMonthBuckets$: Observable<Bucket[]> = this.month.currentMonthYear$.pipe(
    switchMap(monthYear => this.categoryService.categories$(monthYear)),
    map(categories => categories.reduce((prev, curr) => [...prev, ...curr.buckets], [])),
  );

  nonArchivedBuckets$ = this.allCurrentMonthBuckets$.pipe(
    map(buckets =>
      buckets
        .filter(bucket => !bucket.archivedMonth)
        .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1)),
    ),
  );

  bucketsWithFunds$ = this.allCurrentMonthBuckets$.pipe(
    map(buckets => buckets.filter(bucket => bucket.fund && bucket.fund.balance > 0)),
  );

  constructor(
    private backend: BackendService,
    private categoryService: CategoryService,
    private data: DataService,
    private month: MonthService,
  ) {}
  loadAllBuckets() {
    this.categoryService.loadCategory$().subscribe();
  }
  getBucketById$(id) {
    return this.allCurrentMonthBuckets$.pipe(
      take(1),
      map(buckets => buckets.find(bucket => bucket.id === id)),
    );
  }

  fetchBucket$(bucketId: string, monthYear = this.month.monthYearFromDate()): Observable<Bucket> {
    return this.backend
      .request({
        type: 'get',
        apiRoute: `bucket/${bucketId}/${monthYear}`,
        params: { pageSize: 1000 },
      })
      .pipe(tap((bucket: Bucket) => this.setBucketData(bucket)));
  }

  loadBucket$(bucketId: string, monthYear = this.month.monthYearFromDate()) {
    this.fetchBucket$(bucketId, monthYear).subscribe();
    return this.bucket$(bucketId, monthYear);
  }

  setBucketData(bucket: Bucket) {
    this.bucketData$.pipe(take(1)).subscribe((bucketData: BucketData) => {
      bucketData[`bucket/${bucket.id}/${bucket.monthYear}`] = bucket;
      this._bucketData.next(bucketData);
      this.data.setLocalStorage(`bucket/${bucket.id}/${bucket.monthYear}`, bucket);
    });
  }

  addBucket(data): Observable<Bucket> {
    return this.backend.request({
      type: 'post',
      apiRoute: 'bucket',
      data,
    });
  }

  editBucket$(data, refreshBucket: boolean = true, refreshCategories: boolean = true) {
    return this.backend
      .request({
        type: 'update',
        apiRoute: 'bucket',
        data,
      })
      .pipe(
        tap(b => {
          if (refreshBucket) this.fetchBucket$(data.id, data.monthYear).subscribe();
          if (refreshCategories) this.categoryService.loadCategory$(data.monthYear).subscribe();
        }),
      );
  }

  deleteBucket$(bucketId: string): Observable<void> {
    return this.backend.request({
      type: 'update',
      apiRoute: `bucket/${bucketId}/delete`,
    });
  }

  archiveBucketTransferredBalance(bucketId: string): Observable<{ balance: number }> {
    return this.backend.request({
      type: 'get',
      apiRoute: `bucket/${bucketId}/archive`,
    });
  }

  archiveBucket(bucketId: string, transferBalanceBucketId: string = null): Observable<Bucket> {
    return this.backend.request({
      type: 'delete',
      apiRoute: `bucket/${bucketId}`,
      data: { transferBalanceBucketId },
    });
  }

  unarchiveBucket(bucketId: string): Observable<Bucket> {
    return this.backend.request({
      type: 'delete',
      apiRoute: `bucket/${bucketId}`,
      data: { unarchive: true },
    });
  }

  // Bucket Helper Functions
  isIncomeAndBehind(bucket: Bucket) {
    return bucket.type === 'INCOME' && bucket.fund.balance < 0;
  }

  isIncomeAndWithinPaycheck(bucket: Bucket) {
    return bucket.type === 'INCOME' && bucket.fund.balance < 0 && bucket.fund.balance >= bucket.amount * -1;
  }

  getBalance(bucket: Bucket) {
    if (bucket.type === 'INCOME') return Math.abs(bucket.fund.balance);
    return bucket.fund.balance;
  }
}
