import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items$: Observable<any[]>, filter): Observable<any[]> {
    if (!filter?.value) return items$;
    if (!filter.target)
      return items$.pipe(
        map(items => items.filter(item => item[filter.match].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)),
      );
    return items$.pipe(
      map(items =>
        items.reduce((prev, current) => {
          const targets = current[filter.target].filter(
            targetItem => targetItem[filter.match].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1,
          );
          if (targets.length) {
            const newArray = { ...current };
            newArray[filter.target] = targets;
            prev.push(newArray);
          }
          return prev;
        }, []),
      ),
    );

    // if (!items || !filter || !filter.value) {
    //   return items;
    // }
    // if (!filter.target) {
    //   return items.filter(
    //     item =>
    //       item[filter.match]
    //         .toLowerCase()
    //         .indexOf(filter.value.toLowerCase()) !== -1,
    //   );
    // }
    // return items.reduce((prev, current) => {
    //   const targets = current[filter.target].filter(
    //     targetItem =>
    //       targetItem[filter.match]
    //         .toLowerCase()
    //         .indexOf(filter.value.toLowerCase()) !== -1,
    //   );
    //   if (targets.length) {
    //     const newArray = { ...current };
    //     newArray[filter.target] = targets;
    //     prev.push(newArray);
    //   }
    //   return prev;
    // }, []);
  }
}
