import { Injectable } from '@angular/core';
import { BackendService } from '../services/backend.service';
import { Transaction, TransactionPage, TransactionParams, TransactionFilters } from '../types';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { DataService } from '../services/data.service';
import { tap, filter, startWith, take, switchMap } from 'rxjs/operators';
import { TransactionList } from '../transactions/transaction-list.class';
import { DateTime } from 'luxon';
import { UserService } from './user.service';

const PAGE_SIZE: number = 100;
@Injectable({ providedIn: 'root' })
export class TransactionService {
  transactionsRequest$ = (filter: TransactionFilters) => {
    return this.backend.request({
      type: 'get',
      apiRoute: 'transaction',
      params: {
        pageSize: PAGE_SIZE,
        ...this.createFilterParams(filter),
      },
    });
  };

  downloadTransactionsRequest$ = (filter: TransactionFilters) =>
    this.backend.download$({
      type: 'find',
      apiRoute: 'transaction/export',
      params: {
        ...this.createFilterParams(filter),
      },
    });

  downloadTransactions$: (filter: TransactionFilters) => Observable<any> = filter =>
    this.downloadTransactionsRequest$(filter);

  unbudgetedTransactionsRequest$ = this.backend.request({
    type: 'get',
    apiRoute: 'transaction',
    params: {
      pageSize: PAGE_SIZE,
      unbucketedOnly: true,
    },
  });

  unbudgetedTransactionsWithSuggestionsRequest$ = this.backend.request({
    type: 'get',
    apiRoute: 'transaction',
    params: {
      pageSize: PAGE_SIZE,
      unbucketedOnly: true,
      suggestedBucketIds: true,
    },
  });

  deletedTransactionsRequest$ = this.backend.request({
    type: 'get',
    apiRoute: 'transaction',
    params: {
      pageSize: PAGE_SIZE,
      deletedOnly: true,
    },
  });

  cachedTransactions: () => TransactionPage = () => {
    return this.data.getLocalStorage('transactions') || {};
  };

  fetchTransactions$: (transactionFilters: TransactionFilters) => Observable<TransactionPage> = filters =>
    this.transactionsRequest$(filters).pipe(tap(transactionPage => this.setTransactions(transactionPage)));

  setTransactions(transactionPage) {
    return this.data.setLocalStorage('transactions', transactionPage);
  }

  loadUnbudgetedTransactions$: () => Observable<TransactionPage> = () =>
    this.unbudgetedTransactionsRequest$.pipe(
      tap(transactionPage => this.setUnbudgetedTransactions(transactionPage)),
      startWith(this.data.getLocalStorage('unbudgetedTransactions') || []),
      filter(transactionPage => !!transactionPage),
    );

  loadUnbudgetedTransactionsWithSuggestions$: () => Observable<TransactionPage> = () =>
    this.unbudgetedTransactionsWithSuggestionsRequest$.pipe(
      tap(transactionPage => this.setUnbudgetedTransactions(transactionPage)),
      startWith(this.data.getLocalStorage('unbudgetedTransactions')),
      filter(transactionPage => !!transactionPage),
    );

  setUnbudgetedTransactions(transactionPage) {
    return this.data.setLocalStorage('unbudgetedTransactions', transactionPage);
  }

  loadDeletedTransactions$: () => Observable<TransactionPage> = () =>
    this.deletedTransactionsRequest$.pipe(
      tap(transactionPage => this.setDeletedransactions(transactionPage)),
      startWith(this.data.getLocalStorage('deletedTransactions')),
      filter(transactionPage => !!transactionPage),
    );

  setDeletedransactions(transactionPage) {
    return this.data.setLocalStorage('deletedTransactions', transactionPage);
  }

  editingInTransactionList$: BehaviorSubject<null | string> = new BehaviorSubject(null);

  constructor(private backend: BackendService, private data: DataService, public userService: UserService) {}

  loadTransactions(params: TransactionParams) {
    return this.backend.request({
      type: 'get',
      apiRoute: 'transaction',
      params,
    });
  }

  addTransaction(data) {
    return this.backend.request({
      type: 'post',
      apiRoute: 'transaction',
      data,
    });
  }

  updateTransaction(data: Transaction) {
    return this.backend.request({
      type: 'update',
      apiRoute: 'transaction',
      data,
    });
  }

  updateMultipleTransactions(data: {
    ids: string[];
    bucketId?: string;
    description?: string;
    labelIds?: string[];
  }): Observable<{ data: Transaction[] }> {
    return this.backend.request({ type: 'patch', apiRoute: 'transactions', data });
  }

  deleteTransaction(transactionId: string) {
    return this.backend.request({
      type: 'delete',
      apiRoute: `transaction/${transactionId}`,
    });
  }

  deleteTransfer(transferId: string) {
    return this.backend.request({ type: 'delete', apiRoute: `fundTransfer/${transferId}` });
  }

  undeleteTransaction(transactionId: string) {
    return this.backend.request({
      type: 'delete',
      apiRoute: `transaction/${transactionId}`,
      data: { delete: false },
    });
  }

  //TODO: Add more filters later
  matchesFilters(transaction: Transaction, filter: TransactionParams): boolean {
    const bucketIdFilter = !filter.bucketId || transaction.bucketId === filter.bucketId;
    const bucketIdsFilter =
      !filter.bucketIds || filter.bucketIds.length === 0 || filter.bucketIds.indexOf(transaction.bucketId) > -1;
    const unbucketedOnlyFilter = !filter.unbucketedOnly || transaction.bucketId == null;
    const deletedOnlyFilter = !filter.deletedOnly || transaction.deleted;
    const merchantFilter =
      !filter.merchant || transaction.merchant.toLowerCase().indexOf(filter.merchant.toLowerCase()) > -1;
    const monthYearFilter =
      !filter.monthYear || DateTime.fromISO(transaction.date).toFormat('yyyy-MM') === filter.monthYear;

    console.log(
      'matchesFilters',
      bucketIdFilter,
      bucketIdsFilter,
      unbucketedOnlyFilter,
      deletedOnlyFilter,
      merchantFilter,
      monthYearFilter,
    );

    return (
      bucketIdFilter &&
      bucketIdsFilter &&
      unbucketedOnlyFilter &&
      deletedOnlyFilter &&
      merchantFilter &&
      monthYearFilter
    );
  }

  createFilterParams(filter: TransactionFilters) {
    const params: Partial<TransactionFilters> = {};
    if (filter.labelIds.length > 0) params.labelIds = filter.labelIds;
    if (filter.deletedOnly) params.deletedOnly = true;
    if (filter.bucketIds.length > 0) params.bucketIds = filter.bucketIds;
    if (filter.unbucketedOnly) params.unbucketedOnly = true;
    if (filter.merchant) params.merchant = filter.merchant;

    params.suggestedBucketIds = true; // TODO: in the backend we might want to limit this to only transactions that are not already sorted
    return params;
  }
}
