import { Component, OnInit, Inject } from '@angular/core';
import { DrawerService } from 'src/app/services/drawer.service';
import { NgForm } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { take, map, switchMap } from 'rxjs/operators';
import { zip } from 'rxjs';
import { CustomAccount } from 'src/app/types';
import { DRAWER_DATA } from 'src/app/shared/injector-tokens';

@Component({
  selector: 'custom-account-drawer-wrapper',
  templateUrl: './custom-account-drawer-wrapper.component.html',
  styleUrls: ['./custom-account-drawer-wrapper.component.scss'],
})
export class CustomAccountDrawerWrapperComponent implements OnInit {
  ac$ = this.data.accountId
    ? zip(
        this.accountService.accountByAccountId$(this.data.accountId),
        this.accountService.credentialByAccountId$(this.data.accountId),
      ).pipe(map(([account, credential]) => ({ account, credential })))
    : null;
  title = this.data.accountId ? 'Edit Custom Account' : 'Add Custom Account';
  action = this.data.accountId ? 'Save Account' : 'Add Account';

  customAccount: Partial<CustomAccount> = {
    id: null,
    name: '',
    balance: {
      current: null,
    },
  };

  constructor(
    @Inject(DRAWER_DATA) public data: { accountId?: string },
    private drawer: DrawerService,
    private accountService: AccountService,
  ) {}

  ngOnInit() {
    if (this.data.accountId)
      this.ac$.pipe(take(1)).subscribe((ac) => {
        this.customAccount = {
          id: ac.account.id,
          name: ac.account.name,
          balance: { current: ac.account.balance.current },
        };
      });
  }

  submitCustomAccount(form: NgForm) {
    if (!form.valid) return;

    if (this.data.accountId) this.customAccount.id = this.data.accountId;

    this.accountService
      .customCredential$()
      .pipe(
        take(1),
        switchMap((credential) =>
          this.data.accountId
            ? this.accountService.editCustomAccount$(this.customAccount, credential.id)
            : this.accountService.addCustomAccount$(this.customAccount, credential.id),
        ),
      )
      .subscribe(() => this.drawer.closeDrawer());
  }

  deleteCustomAccount() {
    this.ac$
      .pipe(
        take(1),
        switchMap((ac) => this.accountService.deleteCustomAccount$({ id: this.data.accountId }, ac.credential.id)),
      )
      .subscribe(() => this.drawer.closeDrawer());
  }
}
