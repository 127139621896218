<div class="custom-account">
  <app-drawer-header [title]="title"> </app-drawer-header>
  <div class="custom-account-content">
    <p class="custom-account-info">
      <b>Note:</b> Custom accounts will not automatically adjust balances. You will need to update the custom account
      manually if your balance changes.
    </p>
    <form #customAccountForm="ngForm" (ngSubmit)="submitCustomAccount(customAccountForm)">
      <mat-form-field class="custom-account-input" floatLabel="auto" appearance="outline">
        <mat-label>Account Name</mat-label>
        <input matInput type="text" [(ngModel)]="customAccount.name" name="name" required />
      </mat-form-field>

      <mat-form-field class="custom-account-input" floatLabel="auto" appearance="outline">
        <mat-label>Account Balance</mat-label>
        <span matPrefix>$&nbsp;</span>
        <input matInput type="number" step="0.01" [(ngModel)]="customAccount.balance.current" name="balance" required />
      </mat-form-field>

      <div class="custom-account-actions" *ngIf="data.accountId; else createButton">
        <button
          class="custom-account-delete"
          *ngIf="data.accountId"
          mat-flat-button
          color="warn"
          type="button"
          (click)="deleteCustomAccount()"
        >
          Delete Account
        </button>
        <button class="custom-account-action" mat-flat-button color="primary" type="submit">
          {{ action }}
        </button>
      </div>

      <ng-template #createButton>
        <button class="custom-account-action" mat-flat-button color="primary" type="submit">
          {{ action }}
        </button>
      </ng-template>
    </form>
  </div>
</div>
