import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { Bucket } from '../../types';
import { BackendService } from '../../services/backend.service';
import { CategoryService } from '../../services/category.service';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_REORDERED_BUDGET } from '../../analytics/events';
import { DrawerService } from '../../services/drawer.service';
import { moveItemInArray, CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-category-bucket-list',
  templateUrl: './category-bucket-list.component.html',
  styleUrls: ['./category-bucket-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryBucketListComponent {
  @Input() categoryId: string;
  @Input() buckets: Bucket[];
  @Input() reorder: boolean;
  @Input() currentMonthYear: string;

  getConnectedList$ = this.categoryService
    .categories$()
    .pipe(map(categories => categories.map(category => category.id)));

  bucketTrackBy(index: number, bucket: any): string {
    return bucket.id;
  }

  bucketSortUpdate = (event: CdkDragDrop<Bucket[]>) => {
    if (event.previousContainer === event.container) {
      if (event.previousIndex === event.currentIndex) return;
      moveItemInArray(this.buckets, event.previousIndex, event.currentIndex);
    } else
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

    return this.backend
      .request({
        type: 'update',
        apiRoute: 'bucket/reorder',
        data: {
          id: event.item.data?.id,
          sortIndex: event.currentIndex,
          categoryId: event.container.id,
        },
      })
      .subscribe(() => this.analytics.sendEvent(EVENT_REORDERED_BUDGET));
  };

  constructor(
    private analytics: AnalyticsService,
    private backend: BackendService,
    private categoryService: CategoryService,
    private drawer: DrawerService,
  ) {}

  get activePanel() {
    return this.categoryService.activePanel;
  }

  isActivePanel(bucketId: string) {
    return this.activePanel === bucketId;
  }

  openDrawer(bucket: Bucket) {
    this.drawer.openBucketDrawer(bucket.id);
  }

  closeActivePanel(activePanel) {
    if (this.activePanel === activePanel) setTimeout(() => (this.categoryService.activePanel = false), 300);
  }

  findAncestorByClass(el, cls) {
    // tslint:disable-next-line:no-conditional-assignment
    while ((el = el.parentElement) && !el.classList.contains(cls)) {}
    return el;
  }
}
