import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';
import { MonthService } from '../../services/month.service';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { TransactionService } from '../../services/transaction.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showMonthSelector: boolean;
  goBackTo$ = this.route.data;
  impersonating$ = this.sessionService.session$.pipe(
    map(s => s && s.impersonatorId),
    shareReplay(1),
  );
  initials$ = this.userService.user$.pipe(map(user => user.firstName.charAt(0) + user.lastName.charAt(0)));
  hasNewSortFeature$ = this.sessionService.hasEnabledFeature$('new-sort');
  transactionButtonRoute$ = this.hasNewSortFeature$.pipe(
    map(hasNewSortFeature => (hasNewSortFeature ? '/transactions' : '/transactions/sort')),
  );

  constructor(
    public nav: NavigationService,
    public userService: UserService,
    public sessionService: SessionService,
    public month: MonthService,
    private route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
  ) {}

  ngOnInit() {}

  toggleSidenav() {
    this.nav.sideNav = !this.nav.sideNav;
    if (this.nav.sideNav && this.bottomSheet._openedBottomSheetRef) this.bottomSheet._openedBottomSheetRef.dismiss();
  }

  get currentMonthYear$() {
    return this.month.currentMonthYear$;
  }

  navigateTo(location: string[]) {
    if (this.bottomSheet._openedBottomSheetRef) this.bottomSheet._openedBottomSheetRef.dismiss();
    return this.nav.navigateTo(location);
  }

  logout() {
    this.userService.logoutRequest$.subscribe();
  }
}
