import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialog } from '../../types';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialog,
  ) {}

  onConfirm() {
    this.data.onConfirm();
    this.dialogRef.close();
  }

  onClose() {
    if (this.data.onClose) this.data.onClose();
    this.dialogRef.close();
  }
}
