<div *ngIf="!reorder && bucket.isSetup; else noButton" class="category-bucket-summary"
  [ngClass]="{ income: bucket.type === 'INCOME', setup: !bucket.isSetup }">
  <button style="width: 100%;" class="mat-elevation-z0 category-bucket-summary-button" (click)="openDrawer(bucket)"
    mat-button>
    <ng-container *ngTemplateOutlet="bucketSummary"></ng-container>
  </button>
  <button class="category-bucket-summary-balance-button" (click)="toggleTransferOverlay()" mat-button>
    <ng-container *ngTemplateOutlet="balance"></ng-container>
  </button>
  <transfer-budget-balance-overlay *ngIf="bucket._transferOverlay" [bucket]="bucket" (hide)="toggleTransferOverlay()">
  </transfer-budget-balance-overlay>
</div>

<ng-template #noButton>
  <div class="category-bucket-summary no-button"
    [ngClass]="{ income: bucket.type === 'INCOME', setup: !bucket.isSetup }">
    <ng-container *ngTemplateOutlet="bucketSummary"></ng-container>
    <ng-container *ngTemplateOutlet="balance"></ng-container>
  </div>
</ng-template>

<ng-template #bucketSummary>
  <div class="category-bucket-summary-content" *ngIf="bucket.isSetup; else setupBucket">
    <div class="categegory-bucket-summary-progress">
      <app-bucket-progress class="category-bucket-summary-progress-bar" [bucket]="bucket"></app-bucket-progress>
    </div>

    <div class="category-bucket-summary-name">
      <div class="category-bucket-summary-title">{{ bucket.name }}</div>

      <app-category-bucket-summary-info [bucket]="bucket" class="category-bucket-summary-info">
        <span *ngIf="bucket.archivedMonth">&nbsp;- ARCHIVED</span>
      </app-category-bucket-summary-info>
    </div>
  </div>
</ng-template>

<ng-template #balance>
  <div class="category-bucket-summary-balance" *ngIf="bucket.isSetup && !reorder">
    <app-balance-amount [bucket]="bucket"></app-balance-amount>
    <ng-container *ngIf="bucket?.fund?.goal">
      <span class="category-bucket-summary-goal">of {{ bucket.fund.goal | currency: 'USD':'symbol':'1.0-2' }}</span>
    </ng-container>

    <ng-container *ngIf="bucketService.isIncomeAndBehind(bucket)">
      <span class="category-bucket-summary-goal">expected</span>
    </ng-container>
  </div>
</ng-template>

<ng-template #setupBucket>
  <form class="category-bucket-summary-setup-form" #bucketSetupForm="ngForm" [formGroup]="formGroup"
    (ngSubmit)="submitSetupBucket()">
    <div class="category-bucket-summary-setup-title">{{ bucket.name }}</div>
    <mat-form-field class="category-bucket-summary-setup-name no-padding" floatLabel="auto" appearance="outline"
      hideRequiredMarker="true">
      <mat-label>Budget Name</mat-label>
      <span matPrefix>$&nbsp;</span>
      <input matInput type="text" formControlName="name" name="name" autocomplete="off" />
    </mat-form-field>
    <div class="category-bucket-summary-setup-row">
      <mat-form-field class="category-bucket-summary-setup-amount no-padding" floatLabel="always" appearance="outline"
        hideRequiredMarker="true">
        <mat-label>Monthly Amount</mat-label>
        <span matPrefix>$&nbsp;</span>
        <input matInput formControlName="amount" type="number" min="0.00" step="0.01" placeholder="0.00"
          autocomplete="off" name="amount" />
      </mat-form-field>
      <button class="category-bucket-summary-setup-save" type="submit" mat-icon-button color="accent"
        aria-label="save budget">
        <mat-icon *ngIf="!saving">check</mat-icon>
        <mat-spinner diameter="24" *ngIf="saving"></mat-spinner>
      </button>
      <button class="category-bucket-summary-setup-save" mat-icon-button type="button" color="accent"
        aria-label="delete budget" (click)="deleteBucket(bucket)">
        <mat-icon *ngIf="!deleting">delete</mat-icon>
        <mat-spinner diameter="24" *ngIf="deleting"></mat-spinner>
      </button>
    </div>
  </form>
  <div class="spacer"></div>
  <button class="category-bucket-summary-setup-open" type="button" mat-icon-button color="accent"
    (click)="openDrawer(bucket)" aria-label="open budget in drawer">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</ng-template>