<div class="account-details">
  <p>
    Balance:
    <span [ngClass]="{ bad: negativeBalance }">
      <span *ngIf="negativeBalance">(</span>{{ account.balance.current | currency: 'USD':'symbol':'1.2-2'
      }}<span *ngIf="negativeBalance">)</span>
    </span>
  </p>

  <p>Type: {{ account.type | titlecase }}</p>
  <p *ngIf="account.officialName">Account Details: {{
    account.officialName
    }}
  </p>

  <form *ngIf="account && credential" #updateAccountDetailsForm="ngForm"
    (ngSubmit)="addAccountDetailsSave(updateAccountDetailsForm)" autocomplete="off">
    <div class="flex-row">
      <mat-form-field floatLabel="auto" appearance="outline">
        <mat-label>Account Nickname</mat-label>
        <input matInput name="nickname" type="text" [(ngModel)]="accountModel.nickname" />
      </mat-form-field>
    </div>

    <div class="flex-row">
      <mat-form-field floatLabel="auto" appearance="outline">
        <mat-label>Start Date for syncing transactions</mat-label>
        <input name="syncStartDate" matInput [minDate]="minimumDate$ | async" [min]="minimumDate$ | async"
          [matDatepicker]="picker" [disabled]="accountModel.syncDisabled" [(ngModel)]="accountModel.syncStartDate" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error> Cannot sync before {{ minimumDate$ | async | date: 'MM/dd/yyyy' }} </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-row">
      <mat-slide-toggle name="syncDisabled" [(ngModel)]="accountModel.syncDisabled">
        Disable syncing for this account
      </mat-slide-toggle>
    </div>
    <div class="flex-row account-details-actions">
      <button mat-flat-button [disabled]="!updateAccountDetailsForm?.valid || saving" type="submit" color="primary">
        Save
      </button>
    </div>
  </form>
</div>