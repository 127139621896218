import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ActiveElement, Chart, ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import { ChartLegendItem } from '../chart-legend/chart-legend.component';

export interface ChartDataInput {
  label: string;
  amount: number;
}

interface DoughnutDataSet extends ChartDataInput {
  color: string;
  percentage: number;
}

@Component({
  selector: 'doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit, OnChanges {
  @Input() data: ChartDataInput[];
  @ViewChild('ctx', { static: true }) chartContextRef: ElementRef;
  @Input() public showToolTips = true;
  @Input() public showLegend = true;
  @Input() public showTitle = true;
  @Input() public height = 250;
  @Input() public width = 250;
  @Input() public preview = false;
  @Input() public title = '';
  @Input() public emptyTitle = '';
  private chart: Chart;
  public hoveredIndex: number | null = null;
  public legendItems: ChartLegendItem[] = [];
  private chartColors = ['#21CDAC', '#28A7DB', '#AC21CD', '#FE4A49', '#FFBA49'];
  constructor(private ref: ChangeDetectorRef) {}

  get total(): number {
    return this.data?.reduce((acc, curr) => acc + curr.amount, 0);
  }

  ngOnInit(): void {
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }

  private configureData(): DoughnutDataSet[] {
    const total = this.data.map(d => d.amount).reduce((a, b) => a + b, 0);
    return this.data.map((d, i) => ({
      ...d,
      color: this.chartColors[i % this.chartColors.length],
      percentage: d.amount / total,
    }));
  }

  private buildDatasets(data): ChartData {
    return {
      datasets: [
        {
          data: data.map(d => d.amount),
          hoverBorderWidth: this.preview ? 1 : 2,
          borderWidth: this.preview ? 1 : 2,
          hoverOffset: this.preview ? 0 : 4,
          borderColor: '#f2f2f2',
          hoverBorderColor: '#f2f2f2',
          backgroundColor: data.map(d => d.color),
        },
      ],
      labels: data.map(d => d.label),
    };
  }

  private buildLegendItems(data: DoughnutDataSet[]) {
    return data.map(d => ({
      label: `${d.label}: ${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 4,
      }).format(d.amount)}`,
      color: d.color,
      hovered: false,
    }));
  }

  updateChart() {
    if (!this.chart?.data) {
      return this.initChart();
    }

    const _data = this.configureData();
    this.legendItems = this.buildLegendItems(_data);
    this.chart.data = this.buildDatasets(_data);
    this.chart.update();
  }

  public onHover(event: ChartEvent, elements: ActiveElement[], chart: Chart) {
    const index = elements[0]?.index ?? null;

    if (this.hoveredIndex !== index) {
      this.hoveredIndex = index;
      this.ref.markForCheck();
    }
  }

  public initChart() {
    if (!this.data) return;
    const _data = this.configureData();
    this.legendItems = this.buildLegendItems(_data);
    const ctx = this.chartContextRef.nativeElement;

    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: this.buildDatasets(_data),
      options: {
        layout: {
          padding: this.preview ? 0 : 6,
        },
        animation: this.preview ? false : undefined,
        responsive: true,
        maintainAspectRatio: false,
        cutout: '70%',
        onHover: this.onHover.bind(this),
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: !this.preview && this.showToolTips,
            padding: 8,
            callbacks: {
              label: function (context) {
                var label = context.label || '';

                if (context.raw) {
                  const formattedAmount = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: 3,
                  }).format(context.raw as number);
                  const percentage = Math.round(
                    ((context.raw as number) / _data.reduce((acc, curr) => acc + curr.amount, 0)) * 100,
                  );
                  return `${label}: ${formattedAmount} (${percentage}%)`;
                }
                return label;
              },
            },
          },
        },
      },
    } as ChartConfiguration<'doughnut'>);
  }
}
