<ng-container *ngIf="buckets$ | async; let buckets">
  <form
    class="edit-transaction"
    (ngSubmit)="submitUpdate(transactionModel)"
    autocomplete="off"
    #editTransactionForm="ngForm"
    *ngIf="!isMultiple; else editMultiple"
  >
    <div *ngIf="transactionModel?.showSplit; else editTransactionFormContent">
      <app-split-transaction
        [(transaction)]="transactionModel"
        [buckets]="buckets"
        (splitTransaction)="submitUpdate($event)"
      ></app-split-transaction>
    </div>

    <ng-template #editTransactionFormContent>
      <mat-form-field class="merchant" floatLabel="auto" appearance="outline">
        <mat-label>Merchant Name</mat-label>
        <input matInput type="text" [(ngModel)]="transactionModel.merchant" name="merchant" required />
      </mat-form-field>

      <mat-form-field floatLabel="auto" appearance="outline">
        <mat-label>Date</mat-label>
        <input matInput type="date" [(ngModel)]="transactionModel.date" name="date" required />
      </mat-form-field>

      <div class="flex-row">
        <mat-form-field class="edit-transaction-amount" floatLabel="auto" appearance="outline">
          <mat-label>Transaction Amount</mat-label>
          <span class="prefix" matPrefix>$</span>
          <input
            matInput
            type="number"
            name="amount"
            min="0.01"
            step="0.01"
            [(ngModel)]="transactionModel.amount"
            pattern="^[0-9]+(\.[0-9][0-9]?)?$"
            [disabled]="transactionModel.parent"
            required
          />
        </mat-form-field>

        <button
          class="edit-transaction-split-button"
          *ngIf="!transactionModel.parent"
          mat-stroked-button
          color="accent"
          type="button"
          (click)="transactionModel.showSplit = true"
        >
          Split <mat-icon class="material-icons">call_split</mat-icon>
        </button>

        <button
          class="edit-transaction-edit-split-button"
          *ngIf="transactionModel.parent"
          mat-stroked-button
          color="accent"
          type="button"
          (click)="transactionModel.showSplit = true"
        >
          Edit Split <mat-icon class="material-icons">call_split</mat-icon>
        </button>
      </div>

      <mat-radio-group
        *ngIf="bucket$ | async; let bucket"
        [disabled]="transactionModel.parent"
        [(ngModel)]="transactionModel.type"
        name="type"
        required
      >
        <mat-radio-button name="type" value="EXPENSE"> Spent </mat-radio-button>
        <mat-radio-button name="type" value="INCOME"> Received </mat-radio-button>
      </mat-radio-group>

      <mat-radio-group
        *ngIf="!(bucket$ | async)"
        [disabled]="transactionModel.parent"
        [(ngModel)]="transactionModel.type"
        name="type"
        required
      >
        <mat-radio-button name="type" value="EXPENSE"> Spent </mat-radio-button>
        <mat-radio-button name="type" value="INCOME"> Received </mat-radio-button>
      </mat-radio-group>

      <app-select-bucket-typeahead
        [buckets]="buckets"
        [(selectedBucket)]="selectedBucket"
      ></app-select-bucket-typeahead>

      <mat-form-field class="description no-padding" floatLabel="auto" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          rows="3"
          placeholder="More information about this transaction..."
          [(ngModel)]="transactionModel.description"
          name="description"
        ></textarea>
      </mat-form-field>

      <app-label-typeahead [(selectedLabelIds)]="transactionModel.labelIds"></app-label-typeahead>

      <div class="flex-row edit-transaction-actions">
        <button
          mat-icon-button
          color="warn"
          class="edit-transaction-delete"
          *ngIf="!transactionModel?.parent && !transactionModel?.deleted"
          type="button"
          [disabled]="loading"
          (click)="transactionUpdatedEvent.emit({ type: 'DELETE_TRANSACTIONS', value: [transactions[0]] })"
        >
          <mat-icon class="material-icons">delete</mat-icon>
        </button>
        <ng-container *ngIf="transactionModel.deleted">
          <button
            mat-icon-button
            type="button"
            (click)="transactionUpdatedEvent.emit({ type: 'RESTORE_TRANSACTIONS', value: transactions })"
          >
            <mat-icon class="material-icons">replay</mat-icon>
          </button>
        </ng-container>
        <div class="spacer"></div>
        <button
          mat-stroked-button
          type="button"
          [disabled]="loading"
          (click)="transactionUpdatedEvent.emit({ type: 'CANCEL' })"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="primary"
          type="submit"
          class="edit-transaction-save"
          *ngIf="!transactionModel.deleted"
          [disabled]="!editTransactionForm.form.valid || loading"
        >
          Update
        </button>
      </div>
    </ng-template>
  </form>

  <ng-template #editMultiple>
    <form class="edit-transactions" (ngSubmit)="submitMultiUpdate(multi)" autocomplete="off" #editMultipleForm="ngForm">
      <div class="flex-row">
        <mat-form-field class="merchant" floatLabel="auto" appearance="outline">
          <mat-label>Merchant Name</mat-label>
          <input matInput type="text" [(ngModel)]="multi.merchant" name="merchant" />
        </mat-form-field>
      </div>

      <div class="flex-row">
        <app-select-bucket-typeahead
          [buckets]="buckets"
          [(selectedBucket)]="selectedBucket"
        ></app-select-bucket-typeahead>
      </div>

      <div class="flex-row">
        <mat-form-field class="description" floatLabel="auto" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            rows="3"
            placeholder="More information about this transaction..."
            [(ngModel)]="multi.description"
            name="description"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="flex-row" class="labels">
        <app-label-typeahead [(selectedLabelIds)]="multi.labelIds"></app-label-typeahead>
      </div>

      <div class="flex-row edit-transaction-actions">
        <button
          mat-icon-button
          color="warn"
          class="edit-transaction-delete"
          *ngIf="canDelete(transactions)"
          type="button"
          [disabled]="loading"
          (click)="transactionUpdatedEvent.emit({ type: 'DELETE_TRANSACTIONS', value: transactions })"
        >
          <mat-icon class="material-icons">delete</mat-icon>
        </button>
        <button
          *ngIf="canRestore(transactions)"
          mat-icon-button
          type="button"
          (click)="transactionUpdatedEvent.emit({ type: 'RESTORE_TRANSACTIONS', value: transactions })"
        >
          <mat-icon class="material-icons">replay</mat-icon>
        </button>

        <div class="spacer"></div>
        <button
          mat-stroked-button
          type="button"
          [disabled]="loading"
          (click)="transactionUpdatedEvent.emit({ type: 'CANCEL' })"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="primary"
          type="submit"
          class="edit-transaction-save"
          [disabled]="!editMultipleForm.form.valid || loading"
        >
          Update
        </button>
      </div>
    </form>
  </ng-template>
</ng-container>
