import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { Bucket, Transaction } from 'src/app/types';
import { byCount } from 'src/util/helpers';

@Component({
  selector: 'bucket-selector',
  templateUrl: './bucket-selector.component.html',
  styleUrls: ['./bucket-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BucketSelectorComponent implements OnInit, OnChanges {
  @Input() transaction: Transaction;
  @Input() transactions: Transaction[];
  @Input() selectedBucket: Bucket;
  @Input() needsSaving: boolean;
  @Input() buckets: Bucket[];
  @Output() changeSelectedBucket = new EventEmitter<Bucket>();
  @Output() saveBucket = new EventEmitter<Bucket>();
  @Output() changeSuggestedBuckets = new EventEmitter<Bucket[]>();

  searchKeys = ['name'];
  searchValue = '';

  suggestedBuckets = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    const newSuggestedBuckets = this.getSuggestedBuckets();
    if (
      this.suggestedBuckets.length &&
      newSuggestedBuckets.length &&
      this.suggestedBuckets[0].id === newSuggestedBuckets[0].id
    )
      return;
    this.suggestedBuckets = newSuggestedBuckets;
    this.changeSuggestedBuckets.emit(this.suggestedBuckets);
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  onDropdownClick(event: Event) {
    this.stopPropagation(event);
  }

  onKeyDown(event: Event) {
    this.stopPropagation(event);
  }

  onSave(event: Event) {
    this.stopPropagation(event);
    this.saveBucket.emit(this.selectedBucket);
  }

  getBucketsFromIds(ids: string[] = []) {
    if (!this.buckets) return [];
    return ids.map(id => this.buckets.find(bucket => bucket.id === id)).splice(0, 3);
  }

  getSuggestedBuckets() {
    if (!this.transactions && !this.transaction?.suggestedBucketIds?.length) return [];
    if (this.transaction?.suggestedBucketIds) return this.getBucketsFromIds(this.transaction.suggestedBucketIds);

    if (this.transactions?.length) {
      if (this.transactions.length === 1 && this.transactions[0].suggestedBucketIds?.length)
        return this.getBucketsFromIds(this.transactions[0].suggestedBucketIds).slice(0, 3);
      const suggestions = this.transactions.reduce((acc, transaction) => {
        if (!transaction.suggestedBucketIds) return acc;
        return [...acc, ...transaction.suggestedBucketIds];
      }, []);
      return this.getBucketsFromIds(byCount(suggestions)).slice(0, 3);
    }

    return [];
  }

  selectBucket(bucket: Bucket) {
    this.changeSelectedBucket.emit(bucket);
  }

  trackByFn(index: number, item: Bucket) {
    return item.name;
  }
}
