<div class="budget-in-sync">
  <button
    class="budget-in-sync-transfer"
    mat-flat-button
    (click)="drawerService.openUnbudgetedDrawer()"
    [color]="unbudgeted > 0 ? 'primary' : 'warn'"
  >
    <span class="budget-in-sync-title">{{ unbudgeted | currency: 'USD':'symbol':'1.2-2' }} available balance</span>

    <span *ngIf="unbudgeted < 0" class="budget-in-sync-action">Fix</span>
    <span *ngIf="unbudgeted > 0" class="budget-in-sync-action">Transfer</span>
  </button>

  <button
    (click)="drawerService.openDocumentationDrawer('available-balance')"
    class="budget-in-sync-info"
    mat-flat-button
    [color]="unbudgeted > 0 ? 'primary' : 'warn'"
  >
    <mat-icon>info</mat-icon>
  </button>
</div>
