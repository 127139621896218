<div class="credential" *ngIf="credential && credential.accounts.length > 0">
  <mat-card [ngClass]="{ error: credential.deauthenticated }">
    <mat-card-title class="credential-title">
      <img
        *ngIf="credential.institution?.logo"
        class="credential-title-logo"
        [src]="'data:image/png;base64,' + credential?.institution?.logo"
      />
      <span class="credential-title-text"> {{ credential.institution?.name }} </span>

      <div class="spacer"></div>
      <button
        *ngIf="credential.deauthenticated && !credential.reauthenticating"
        mat-flat-button
        (click)="reauthenticate(credential)"
        color="accent"
      >
        Reauthenticate
      </button>
      <mat-spinner *ngIf="credential.reauthenticating" [strokeWidth]="2" [diameter]="24"></mat-spinner>
      <button
        *ngIf="credential.type !== 'CUSTOM'"
        class="credential-menu-button"
        mat-icon-button
        [matMenuTriggerFor]="credentialMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #credentialMenu="matMenu">
        <button mat-menu-item (click)="confirmRemoveCredential(credential)">
          <mat-icon>delete</mat-icon>
          <span>Remove Account</span>
        </button>
      </mat-menu>
    </mat-card-title>
    <div class="credential-account-list">
      <div *ngFor="let account of credential.accounts; last as isLast">
        <app-account [account]="account"></app-account>
        <div *ngIf="!isLast" class="credential-account-list-break"></div>
      </div>
    </div>
  </mat-card>
</div>
