<div class="categories-pie-chart-drawer-wrapper">
  <app-drawer-header [title]="drawerTitle$ | async">
    <div class="category-balance" *ngIf="categoryService.categoryBalance$(data.categoryId) | async; let balance">
      <span class="category-balance-label"> Balance: </span>
      <span class="category-balance-amount" [ngClass]="{ good: balance > 0, bad: balance < 0 }">
        {{ balance | currency: 'USD':'symbol':'1.2-2' }}
      </span>
    </div>
  </app-drawer-header>
  <div class="categories-pie-chart-drawer-content">
    <div class="drawer-section" *ngIf="chartData$ | async; let chartData">
      <doughnut-chart class="chart" type="doughnut" [width]="200" [height]="200" [title]="'Spending Breakdown'"
        emptyTitle="No spending data for this month" [data]="chartData.actual">
      </doughnut-chart>
      <doughnut-chart class="chart" type="doughnut" [width]="200" [height]="200" [title]="'Budget Breakdown'"
        [data]="chartData.expected" emptyTitle="No budget data to show">
      </doughnut-chart>
    </div>
  </div>
</div>