import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { BucketService } from '../../services/bucket.service';
import { MonthService } from '../../services/month.service';
import { filter, debounceTime } from 'rxjs/operators';
import { Bucket } from '../../types';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_UPDATED_BUDGET } from '../../analytics/events';
import { MatDialog } from '@angular/material/dialog';
import { ArchiveBucketDialogComponent } from '../../bucket/archive-bucket-dialog/archive-bucket-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-bucket',
  templateUrl: './edit-bucket.component.html',
  styleUrls: ['./edit-bucket.component.scss'],
})
export class EditBucketComponent implements OnInit, OnDestroy {
  @Input() bucket: Bucket;
  @Output() bucketEdited = new EventEmitter();
  @Output() bucketDeleted = new EventEmitter();

  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    goal: new FormControl(0, Validators.pattern('[0-9.]*')),
  });
  loading = false;
  subscription = new Subscription();

  constructor(
    private analytics: AnalyticsService,
    private bucketService: BucketService,
    private dialog: MatDialog,
    public month: MonthService,
  ) {}

  ngOnInit() {
    this.formGroup.setValue({ name: this.bucket.name, goal: this.bucket.fund.goal });
    this.subscription.add(
      this.formGroup.valueChanges
        .pipe(
          filter(() => this.formGroup.valid),
          debounceTime(200),
        )
        .subscribe(value => this.editBucketFormSubmit(value)),
    );
  }

  editBucketFormSubmit({ goal, name }) {
    const bucket = this.bucket;
    if (this.bucket.fund.goal === goal && this.bucket.name === name) return;
    if (this.formGroup.invalid) return;
    this.loading = true;
    const editedBucket = { ...bucket, name, fund: { ...bucket.fund, goal } };
    this.bucketService.editBucket$(editedBucket).subscribe(() => {
      this.loading = false;
      this.analytics.sendEvent(EVENT_UPDATED_BUDGET);
    });
  }

  canDeleteBucket(bucket: Bucket): boolean {
    return bucket.createdMonth === this.month.monthYearFromDate() && bucket.transactions.totalRecords === 0;
  }

  confirmArchiveBucket(bucket) {
    this.dialog.open(ArchiveBucketDialogComponent, {
      width: '600px',
      data: { bucket, onSuccess: () => this.bucketEdited.emit({ success: true }) },
    });
  }

  deleteBucket(bucket) {
    this.bucketService.deleteBucket$(bucket.id).subscribe(() => this.bucketDeleted.emit({ success: true }));
  }

  archiveBucket(bucket) {
    this.bucketService.archiveBucket(bucket.id).subscribe(() => {
      this.bucketEdited.emit({ success: true });
    });
  }

  unarchiveBucket(bucket) {
    this.bucketService.unarchiveBucket(bucket.id).subscribe(() => {
      this.bucketEdited.emit({ success: true });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
