import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from '../../dialog-templates/form-dialog/form-dialog.component';
import { BackendService } from '../../services/backend.service';
import { NgForm } from '@angular/forms';

import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_SENT_FEEDBACK, EVENT_FEEDBACK_CANCELED, EVENT_FEEDBACK_OPENED } from '../../analytics/events';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  @HostListener('click', ['$event.target'])
  onClick() {
    this.openFeedbackDialog();
  }

  constructor(private analytics: AnalyticsService, private dialog: MatDialog, private backend: BackendService) {}

  openFeedbackDialog() {
    this.analytics.sendEvent(EVENT_FEEDBACK_OPENED);

    this.dialog.open(FormDialogComponent, {
      data: {
        formType: 'textArea',
        title: 'Question or comment?',
        message: `We are working hard to give you a great budgeting experience.
        If you're having trouble with something or see something that could be better, please let us know!`,
        form: {
          maxlength: false,
        },
        confirmColor: 'primary',
        onSubmit: (form: NgForm) => this.sendFeedback(form),
        onCancel: () => this.analytics.sendEvent(EVENT_FEEDBACK_CANCELED),
      },
    });
  }

  sendFeedback(form: NgForm) {
    this.backend
      .request({
        type: 'post',
        apiRoute: 'feedback',
        timeoutMillis: 10000,
        data: {
          message: form.value.inputValue,
        },
      })
      .subscribe(() => this.analytics.sendEvent(EVENT_SENT_FEEDBACK));
  }
}
