<div class="add-bucket">
  <form #addBucketForm="ngForm" (ngSubmit)="addBucketFormSubmit(addBucketForm)" autocomplete="off">
    <mat-form-field floatLabel="auto" appearance="outline" hideRequiredMarker="true">
      <mat-label>Select Category</mat-label>
      <mat-select class="add-bucket-id" [(ngModel)]="bucket.categoryId" name="categoryId" required>
        <mat-option *ngFor="let category of categorySelect$ | async" [value]="category.id">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="add-bucket-name" floatLabel="auto" appearance="outline" hideRequiredMarker="true">
      <mat-label>Budget Name</mat-label>
      <input matInput name="name" type="text" [(ngModel)]="bucket.name" maxlength="24" required />
    </mat-form-field>

    <mat-radio-group class="add-bucket-type" [(ngModel)]="bucket.type" name="type" required>
      <mat-label>Budget Type</mat-label>
      <mat-radio-button name="type" value="BUDGET"> Spending </mat-radio-button>
      <mat-radio-button name="type" value="INCOME"> Income </mat-radio-button>
    </mat-radio-group>

    <mat-form-field class="add-bucket-amount" floatLabel="auto" appearance="outline" hideRequiredMarker="true">
      <mat-label>Monthly Amount</mat-label>
      <span matPrefix>$&nbsp;</span>
      <input matInput type="number" min="0.00" step="0.01" [(ngModel)]="bucket.amount" name="amount" required />
    </mat-form-field>

    <mat-form-field *ngIf="bucket.type !== 'INCOME'" class="add-bucket-goal" floatLabel="auto" appearance="outline"
      hideRequiredMarker="true">
      <mat-label>Goal Amount</mat-label>
      <span matPrefix>$&nbsp;</span>
      <input matInput type="number" min="0.00" step="0.01" [(ngModel)]="bucket.fund.goal" name="fundGoal" />
    </mat-form-field>

    <div class="flex-row">
      <div class="spacer"></div>
      <button mat-flat-button color="primary" type="submit">
        Add Budget
      </button>
    </div>
  </form>
</div>