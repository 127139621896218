<div class="page">
  <ng-container *ngIf="(accountService.totalAccounts$ | async) > 0; else noAccounts">
    <div class="flex-row accounts-page-actions">
      <div class="spacer"></div>
      <button mat-flat-button color="accent" (click)="openCustomAccount()">
        Add Custom Account
      </button>
      <button mat-flat-button color="primary" (click)="openPlaid()" [disabled]="openingPlaid">
        {{ openingPlaid ? 'Loading...' : 'Add Account' }}
      </button>
    </div>
    <div *ngIf="accountService.creatingCredential" class="loading-accounts">
      <mat-spinner [strokeWidth]="2" [diameter]="40"></mat-spinner>
    </div>
    <app-credential
      *ngFor="let credential of (accountService.credentials$ | async)?.data"
      [credential]="credential"
    ></app-credential>
  </ng-container>

  <ng-template #noAccounts>
    <mat-card class="no-accounts">
      <div class="no-accounts-graphic">
        <img src="assets/images/emptycard.svg" alt="credit card graphic" />
      </div>
      <p>Add your financial accounts to sync transactions.</p>

      <div class="no-accounts-actions">
        <button mat-flat-button color="accent" (click)="openCustomAccount()">
          Add Custom Account
        </button>
        <button mat-flat-button color="primary" (click)="openPlaid()" [disabled]="openingPlaid">
          {{ accountService.creatingCredential ? 'Adding...' : 'Add Account' }}
        </button>
      </div>
    </mat-card>
  </ng-template>
</div>
