<mat-toolbar class="drawer-header">
  <div class="flex-row">
    <div class="drawer-header-content">
      <h2 *ngIf="title" class="drawer-header-title">{{ title }}</h2>
      <ng-content></ng-content>
    </div>
    <div class="spacer"></div>
    <button class="drawer-header-close" mat-icon-button (click)="close()">
      <mat-icon class="material-icons">close</mat-icon>
    </button>
  </div>
</mat-toolbar>
