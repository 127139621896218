import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Bucket } from 'src/app/types';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'transfer-budget-balance-overlay',
  templateUrl: './transfer-budget-balance-overlay.component.html',
  styleUrls: ['./transfer-budget-balance-overlay.component.scss'],
})
export class TransferBudgetBalanceOverlayComponent implements OnInit {
  @Input() bucket: Bucket;
  @Output() hide = new EventEmitter();

  constructor(private categoryService: CategoryService) {}

  close: EventEmitter<any>;

  tryClose() {
    if (this.close) this.close.emit();
  }

  ngOnInit(): void {
    // delaying closing to avoid instant click outside close
    setTimeout(() => {
      this.close = this.hide;
    }, 100);
  }

  onTransferSuccess(event) {
    this.categoryService.loadCategory$(this.bucket.monthYear).subscribe();
  }
}
