import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AddBucketComponent } from './add-bucket/add-bucket.component';
import { BucketPageSummaryComponent } from './bucket-page-summary/bucket-page-summary.component';
import { BucketComponent } from './bucket/bucket.component';
import { EditBucketComponent } from './edit-bucket/edit-bucket.component';
import { BucketSummaryComponent } from './bucket-summary/bucket-summary.component';
import { TransactionsModule } from '../transactions/transactions.module';
import { BucketTransactionListComponent } from '../shared/bucket-transaction-list/bucket-transaction-list.component';
import { BucketSetupToolbarComponent } from './bucket-setup-toolbar/bucket-setup-toolbar.component';

@NgModule({
  imports: [CommonModule, SharedModule, TransactionsModule],
  declarations: [
    AddBucketComponent,
    BucketPageSummaryComponent,
    BucketComponent,
    EditBucketComponent,
    BucketSummaryComponent,
    BucketTransactionListComponent,
    BucketSetupToolbarComponent,
  ],
  exports: [BucketComponent, AddBucketComponent, BucketTransactionListComponent],
  providers: [],
})
export class BucketsModule {}
