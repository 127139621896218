import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NavigationService } from '../services/navigation.service';
import { UserService } from '../services/user.service';
import { User } from '../types';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private nav: NavigationService, private userService: UserService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.user$.pipe(
      map(user => user && user.superAdmin),
      tap(isSuperAdmin => {
        if (!isSuperAdmin) this.nav.navigateTo(['/budgets']);
      }),
    );
  }
}
