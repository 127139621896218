import { Component, OnInit, Input } from '@angular/core';
import { Account } from '../../types';
import { DrawerService } from '../../services/drawer.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  @Input() account: Account;
  constructor(private drawer: DrawerService) {}

  get negativeBalance(): boolean {
    return this.account.type === 'loan' || this.account.type === 'credit';
  }

  openAccountDetails() {
    return this.account.type === 'custom'
      ? this.drawer.openEditCustomAccountDrawer(this.account.id)
      : this.drawer.openAccountDrawer(this.account.id);
  }

  ngOnInit() {}
}
