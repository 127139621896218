<mat-form-field class="bucket-typeahead" floatLabel="auto" appearance="outline">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-chip-list #bucketChipList>
    <mat-chip
      *ngFor="let bucketId of selectedBucketIds"
      selectable="true"
      removable="true"
      (removed)="remove(bucketId)"
    >
      {{ (bucketMap$ | async)[bucketId].name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #bucketInput
      aria-label="Search Budgets"
      [(ngModel)]="currentBucketText"
      (input)="inputChanged()"
      [matAutocomplete]="bucketAuto"
      [matChipInputFor]="bucketChipList"
      matChipInputAddOnBlur="false"
    />
  </mat-chip-list>
  <mat-autocomplete autoActiveFirstOption #bucketAuto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let bucket of filteredBuckets$ | async" [value]="bucket.name">
      {{ bucket.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
