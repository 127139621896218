<button class="close" mat-icon-button (click)="close()">
  <mat-icon>close</mat-icon>
</button>

<div class="dialog">
  <div class="dialog__graphic">
    <img src="assets/images/stripe-modal-card.png" alt="credit card graphic" />
  </div>
  <ng-container *ngIf="data.options?.newUpgrade">
    <h2 class="dialog__subscribing">Yay! By subscribing, you continue to recieve:</h2>
    <ul class="dialog__list">
      <li class="dialog__list--item">Automatic syncing with your accounts</li>
      <li class="dialog__list--item">Easy sorting for every transaction</li>
      <li class="dialog__list--item">Reports that will help you maintain accurate budgets</li>
      <li class="dialog__list--item">And so much more</li>
    </ul>
  </ng-container>

  <mat-divider class="divider"></mat-divider>

  <h2 class="dialog__card-info">Card Information</h2>
  <h4 class="dialog__per-month">$5 / month</h4>

  <form (submit)="handleForm($event)">
    <div #cardElement></div>

    <p class="dialog__errors">{{ cardErrors }}</p>

    <div class="dialog__submit-row">
      <p *ngIf="data.options?.newUpgrade" class="trial-notice">You won't be charged until your trial ends (30 days).</p>
      <div>
      <button *ngIf="data.options?.newUpgrade" class="submit__add" mat-flat-button color="primary">Add Card</button>
      <button *ngIf="!data.options?.newUpgrade" class="submit__update" mat-flat-button color="primary">Update Card</button>
    </div>
    </div>
  </form>
</div>
