<div class="bucket-summary-wrapper">
  <div class="bucket-summary">
    <app-bucket-progress [bucket]="bucket"></app-bucket-progress>
    <mat-form-field
      class="centered-input no-padding"
      *ngIf="bucket.type === 'INCOME'"
      floatLabel="auto"
      appearance="outline"
      hideRequiredMarker="true"
    >
      <mat-label>Earned</mat-label>
      <input matInput name="earned" type="number" [ngModel]="bucketTotal" disabled />
      <span matPrefix>$</span>
    </mat-form-field>
    <mat-form-field
      class="centered-input no-padding"
      *ngIf="bucket.type !== 'INCOME'"
      floatLabel="auto"
      appearance="outline"
      hideRequiredMarker="true"
    >
      <mat-label>Spent</mat-label>
      <input matInput name="spent" type="number" [ngModel]="bucketTotal" disabled />
      <span matPrefix>$</span>
    </mat-form-field>

    <mat-form-field class="centered-input no-padding" floatLabel="auto" appearance="outline" hideRequiredMarker="true">
      <mat-label *ngIf="bucket.type !== 'INCOME'">Budget <mat-icon svgIcon="pencil"></mat-icon></mat-label>
      <mat-label *ngIf="bucket.type === 'INCOME'">Expected <mat-icon svgIcon="pencil"></mat-icon></mat-label>
      <input
        matInput
        name="budget"
        type="number"
        [ngModel]="budgetAmount"
        (ngModelChange)="onBudgetAmountChange($event)"
        (focus)="onBudgetAmountFocus()"
        (focusout)="onBudgetAmountFocusOut()"
        autocomplete="off"
        [disabled]="bucket.archivedMonth"
      />
      <span class="prefix" matPrefix>$</span>
    </mat-form-field>
  </div>
</div>
