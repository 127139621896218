<mat-accordion displayMode="flat">
  <ul class="categories-list" cdkDropList (cdkDropListDropped)="categorySortUpdate($event)">
    <li *ngFor="let category of categories; trackBy: categoryTrackBy" [id]="category.id" class="categories-list-item"
      cdkDrag cdkDropListGroup [cdkDragData]="category"
      [cdkDragDisabled]="!reordering || category.id === 'UNCATEGORIZED'" [ngClass]="{
        uncategorized: category?.id === 'UNCATEGORIZED'
      }">
      <mat-card *ngIf="reordering || category?.id !== 'UNCATEGORIZED' || category.buckets.length"
        [ngClass]="{ reordering: reordering }">
        <mat-card-title>
          <span class="categories-list-name" *ngIf="!category.rename">{{ category.name }}</span>
          <form class="categories-list-rename-form" *ngIf="category.rename" (ngSubmit)="updateCategoryName(category)"
            autocomplete="off">
            <mat-form-field floatLabel="auto" appearance="outline">
              <mat-label>Group Name</mat-label>
              <input matInput name="name" [(ngModel)]="category.newCategoryName" maxlength="24" />
            </mat-form-field>
            <button type="submit" mat-button>Save</button>
          </form>
          <div *ngIf="category.rename" class="spacer"></div>
          <div *ngIf="!category.id && !reordering" class="space"></div>
          <ng-container *ngIf="category?.id !== 'UNCATEGORIZED' && !reordering && !category.rename">
            <button *ngIf="category.buckets.length > 1" class="categories-list-chart" mat-icon-button
              (click)="drawerService.openCategoriesPieChartDrawer(category.id)">
              <mat-icon svgIcon="donut-chart"></mat-icon>
            </button>
            <button mat-icon-button class="categories-list-edit" [matMenuTriggerFor]="categoryMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </ng-container>
          <button class="cancel-editing" mat-icon-button *ngIf="category.rename" (click)="category.rename = false">
            <mat-icon>close</mat-icon>
          </button>
          <mat-menu #categoryMenu="matMenu">
            <button mat-menu-item (click)="openAddCategoryDialog()">
              <mat-icon>playlist_add</mat-icon>
              <span>Add Group</span>
            </button>
            <button mat-menu-item (click)="drawerService.openAddBucketDrawer(category.id)">
              <mat-icon>add</mat-icon>
              <span>Add Budget</span>
            </button>
            <button mat-menu-item (click)="category.rename = true; category.newCategoryName = category.name">
              <mat-icon svgIcon="pencil"></mat-icon>
              <span>Rename Group</span>
            </button>
            <button mat-menu-item (click)="categoryService.reordering = true">
              <mat-icon>reorder</mat-icon>
              <span>Reorder Budgets</span>
            </button>

            <button mat-menu-item (click)="confirmDeleteCategory(category)">
              <mat-icon>delete</mat-icon>
              <span>Delete Group</span>
            </button>
          </mat-menu>

          <mat-icon class="categories-list-drag-icon category-drag-handle" cdkDragHandle
            *ngIf="reordering && category?.id !== 'UNCATEGORIZED'">reorder</mat-icon>
        </mat-card-title>
        <app-category-bucket-list [categoryId]="category.id" [reorder]="reordering" [buckets]="category.buckets"
          [currentMonthYear]="currentMonthYear$ | async"></app-category-bucket-list>
      </mat-card>
    </li>
  </ul>
</mat-accordion>