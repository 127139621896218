import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MonthService } from '../../../services/month.service';
import { switchMap, shareReplay, tap, map, take } from 'rxjs/operators';
import { DrawerService } from '../../../services/drawer.service';
import { BucketService } from '../../../services/bucket.service';
import { Subscription, Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Bucket } from '../../../types';
import { DRAWER_DATA } from 'src/app/shared/injector-tokens';

@Component({
  selector: 'app-bucket-drawer-wrapper',
  templateUrl: './bucket-drawer-wrapper.component.html',
  styleUrls: ['./bucket-drawer-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BucketDrawerWrapperComponent implements OnInit {
  editName: boolean;

  bucket$ = this.month.currentMonthYear$.pipe(
    switchMap((month) => {
      return this.bucketService.loadBucket$(this.data.bucketId, month);
    }),
  );

  constructor(
    @Inject(DRAWER_DATA) private data: any,
    public bucketService: BucketService,
    private month: MonthService,
    public drawerService: DrawerService,
  ) {}

  ngOnInit() {}

  renameBucket(form: NgForm, bucket: Bucket) {
    bucket.name = form.value.newBucketName;
    this.bucketService.editBucket$(bucket).subscribe();
    this.editName = false;
  }
}
