// Dev Environment

export const environment = {
  environment: 'dev',
  GA_TRACKING_ID: 'UA-115615290-1',
  TRACKING_TAGS: ['GTM-56Q3WPS'],
  production: false,
  hmr: false,
  restUrl: 'https://dev.budgets.money',
  fbAppID: '1366231500132303',
  plaidPublicKey: 'cad10e18733cf2e2d25b7a369a79d9',
  plaidEnvironment: 'sandbox',
  serviceWorker: false,
  stripeKey: 'pk_test_NWAAzgKtdomdRd7aiJf1LZRR',
  logrocket: 'false',
  localStorage: false,
  hideUsersFromLogrocket: [
    'jaruesink@gmail.com',
    'derek.wene@gmail.com',
    'jackie.steen@gmail.com',
    'jackie.ruesink@gmail.com',
  ],
};
