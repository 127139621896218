<div *ngIf="ac$ | async; let ac">
  <app-drawer-header>
    <div class="account-title">
      <img
        class="account-title-logo"
        *ngIf="ac.credential?.institution?.logo"
        [src]="'data:image/png;base64,' + ac.credential?.institution?.logo"
      />
      <h2 class="account-title-text">{{ ac.account.nickname || ac.account.name }}</h2>
    </div>
  </app-drawer-header>
  <app-account-details
    [account]="ac.account"
    [credential]="ac.credential"
    (accountSaved)="closeDrawer()"
  ></app-account-details>
</div>
