import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountsRoutingModule } from './accounts-routing.module';
import { AccountsPageComponent } from './accounts-page/accounts-page.component';
import { SharedModule } from '../shared/shared.module';

import { AccountsListComponent } from './accounts-list/accounts-list.component';
import { CredentialComponent } from './credential/credential.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountComponent } from './account/account.component';

@NgModule({
  imports: [CommonModule, AccountsRoutingModule, SharedModule],
  declarations: [
    AccountsPageComponent,
    AccountsListComponent,
    CredentialComponent,
    AccountDetailsComponent,
    AccountComponent,
  ],
  exports: [AccountDetailsComponent, CredentialComponent],
})
export class AccountsModule {}
