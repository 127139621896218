import { Action, Transaction, TransactionPage, Bucket } from '../../types';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransactionService } from '../../services/transaction.service';
import { BucketService } from '../../services/bucket.service';
import { map, tap, take } from 'rxjs/operators';

type TransactionModel = Transaction & {
  showSplit?: boolean;
};

@Component({
  selector: 'app-edit-transactions',
  templateUrl: './edit-transactions.component.html',
  styleUrls: ['./edit-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTransactionsComponent implements OnInit {
  @Input() transactions: Array<Transaction>;
  @Output() transactionUpdatedEvent = new EventEmitter<Action>();

  transactionModel: TransactionModel;
  selectedBucket: Bucket;
  multi = {
    merchant: '',
    bucketId: '',
    description: '',
    labelIds: [],
  };
  loading = false;
  buckets$ = this.bucketService.nonArchivedBuckets$;
  bucket$ = this.buckets$.pipe(map(buckets => buckets.find(bucket => bucket.id === this.transactions[0].bucketId)));

  constructor(private bucketService: BucketService, private transactionService: TransactionService) {}

  ngOnInit() {
    if (!this.isMultiple) {
      const transaction = this.transactions[0];
      this.transactionModel = { ...transaction };
      this.bucket$.pipe(take(1)).subscribe(bucket => {
        this.selectedBucket = bucket;
      });
    }
  }

  get isMultiple(): boolean {
    return this.transactions.length > 1;
  }

  submitUpdate(transactionModel: Transaction) {
    transactionModel.bucketId = this.selectedBucket ? this.selectedBucket.id : null;
    this.loading = true;
    delete this.transactionModel.showSplit;
    return this.transactionUpdatedEvent.emit({
      type: 'EDIT_TRANSACTION',
      value: transactionModel,
    });
  }

  submitMultiUpdate(multi) {
    this.loading = true;

    const value: { ids: string[]; merchant?: string; bucketId?: string; description?: string; labelIds?: string[] } = {
      ids: this.transactions.map(transaction => transaction.id),
    };
    if (multi.merchant) value.merchant = multi.merchant;
    if (this.selectedBucket) value.bucketId = this.selectedBucket.id;
    if (multi.description) value.description = multi.description;
    if (multi.labelIds.length) value.labelIds = multi.labelIds;

    return this.transactionUpdatedEvent.emit({ type: 'EDIT_MANY_TRANSACTIONS', value });
  }

  canDelete(transactions: Transaction[]) {
    return !transactions.some(transaction => !!(transaction.parent || transaction.deleted));
  }

  canRestore(transactions: Transaction[]) {
    return !transactions.some(transaction => !transaction.deleted);
  }
}
