import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Bucket } from '../../types';
import { FormControl } from '@angular/forms';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';
import Fuse from 'fuse.js';

@Component({
  selector: 'app-select-bucket-typeahead',
  templateUrl: './select-bucket-typeahead.component.html',
  styleUrls: ['./select-bucket-typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBucketTypeaheadComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label = 'Select Budget';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() buckets: Bucket[];
  @Input() selectedBucket: Bucket;
  @Input() options: {
    showFundBalance?: boolean;
  } = {};
  @Output() selectedBucketChange: EventEmitter<Bucket> = new EventEmitter();

  Fuse: Fuse<Bucket>;
  control = new FormControl();
  valueChanges$ = this.control.valueChanges;
  filteredBuckets$: Observable<{}[]>;
  _sub: Subscription;
  constructor() {}

  ngOnInit() {
    this.setupFuzzySearch();

    this._sub = this.valueChanges$
      .pipe(map(value => this.buckets.find(bucket => bucket.name === value)))
      .subscribe(bucket => {
        this.updateControlErrors(bucket);
        this.selectedBucketChange.emit(bucket ? bucket : undefined);
      });

    this.filteredBuckets$ = this.valueChanges$.pipe(
      map(val => this.filter(val)),
      map((filterList: { item: Bucket; ref: string }[]) =>
        filterList[0]?.item ? filterList.map(({ item }) => item) : filterList,
      ),
      startWith(this.buckets), // needed to initialize the component in the template
      shareReplay(1),
    );

    this.updateValueWithSelectedBucket();
  }

  setupFuzzySearch() {
    this.Fuse = new Fuse(this.buckets, {
      keys: ['name'],
      shouldSort: true,
      threshold: 0.6,
    });
  }

  ngOnChanges() {
    this.updateValueWithSelectedBucket();
  }

  updateValueWithSelectedBucket() {
    if (this.selectedBucket) return this.control.setValue(this.selectedBucket.name);

    this.control.setValue('');
    this.control.setErrors(null);
  }

  updateControlErrors(bucket: Bucket) {
    if (bucket) return this.control.setErrors(null);
    if (!this.required && this.control.value === '') return this.control.setErrors(null);
    this.control.setErrors({ invalid: true });
  }

  optionSelected({ option: { value } }) {
    const selectedBucket = this.buckets.find(bucket => bucket.name === value);
    this.selectedBucketChange.emit(selectedBucket);
  }

  filter(val: string): {}[] {
    if (val === '') return this.buckets;
    return this.Fuse.search(val);
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
