import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DrawerService } from '../../services/drawer.service';
import { CdkDragMove } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-bottom-sheet-drawer',
  templateUrl: './bottom-sheet-drawer.component.html',
  styleUrls: ['./bottom-sheet-drawer.component.scss'],
})
export class BottomSheetDrawerComponent {
  dragPosition: { x: number; y: number };
  dragDistance: number;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetDrawerComponent>,
    private drawerService: DrawerService,
  ) {}

  // close() {
  //   this.drawerService.closeDrawer();
  // }

  // dragMoved(event: CdkDragMove) {
  //   this.dragDistance = event.distance.y;
  //   if (event.distance.y > 40) {
  //     this.close();
  //     this.resetDrag();
  //   }
  // }

  // dragEnded($event) {
  //   if ($event.distance.x < 40) this.resetDrag();
  // }

  // resetDrag() {
  //   this.dragDistance = 0;
  //   this.dragPosition = { x: 0, y: 0 };
  // }
}
