import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Transaction, Bucket } from 'src/app/types';
import { TransactionDataSource } from '../transaction-data-source.class';
import { EditTransactionsDialogComponent } from '../edit-transactions-dialog/edit-transactions-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'edit-transaction-list-item',
  templateUrl: './edit-transaction-list-item.component.html',
  styleUrls: ['./edit-transaction-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditTransactionListItemComponent implements OnInit {
  @Input() transaction: Transaction;
  @Input() transactionDataSource: TransactionDataSource;
  @Output() closeEditTransaction = new EventEmitter();

  transactionModel: Transaction;
  loading: boolean;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.transactionModel = { ...this.transaction };
  }

  selectedBucketChange(bucket: Bucket) {
    this.transaction.selectedBucket = bucket;
  }

  onTransactionUpdatedEvent(action) {
    if (action.type === 'CANCEL') return this.closeEditTransaction.emit();
    this.transactionDataSource.dispatch(action);
    this.closeEditTransaction.emit();
  }

  submitUpdate(transactionModel: Transaction) {
    this.loading = true;

    if (this.transaction.selectedBucket) this.transactionModel.bucketId = this.transaction.selectedBucket.id;

    return this.onTransactionUpdatedEvent({
      type: 'EDIT_TRANSACTION',
      value: transactionModel,
    });
  }

  openEditDialog() {
    this.transactionDataSource.clearSelected();
    this.transactionDataSource.toggleSelected({ isSelected: true, transaction: this.transaction });
    this.dialog.open(EditTransactionsDialogComponent, {
      data: {
        transactionDataSource: this.transactionDataSource,
      },
      autoFocus: false,
    });
  }
}
