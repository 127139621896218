<button
  mat-button
  class="account-wrapper"
  [ngClass]="{ disabled: account.syncDisabled }"
  (click)="openAccountDetails()"
>
  <div class="account">
    <div class="account-summary">
      <div class="account-summary-name">
        {{ account.nickname || account.name }}
      </div>
      <div class="account-summary-mask" *ngIf="account.mask">
        {{ account.mask }} <span class="account-disabled" *ngIf="account.syncDisabled">(Syncing disabled)</span>
      </div>
    </div>
    <div [ngClass]="{ bad: negativeBalance }" class="account-balance">
      <span *ngIf="negativeBalance">(</span>
      <span>{{ account.balance.current | currency: 'USD':'symbol':'1.2-2' }}</span>
      <span *ngIf="negativeBalance">)</span>
    </div>
  </div>
</button>
