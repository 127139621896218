<mat-tab-group
  *ngIf="!options.hideTransferIn && !options.hideTransferOut; else singleOption"
  color="primary"
  backgroundColor="primary"
  mat-stretch-tabs
  class="transfer-budget-tabs"
>
  <mat-tab label="Transfer In">
    <form
      class="transfer-budget-tab-form"
      #transferInForm="ngForm"
      (ngSubmit)="transferBudget('in', transferInForm)"
      autocomplete="off"
    >
      <app-select-bucket-typeahead
        [buckets]="availableBuckets$ | async"
        [(selectedBucket)]="selectedBucket"
        [options]="{ showFundBalance: true }"
        [required]="true"
      ></app-select-bucket-typeahead>

      <mat-form-field class="add-bucket-amount" floatLabel="always" appearance="outline">
        <mat-label>Transfer Amount</mat-label>
        <span matPrefix>$&nbsp;</span>
        <input
          matInput
          type="number"
          min="0.01"
          step="0.01"
          [max]="maxTransfer$ | async"
          [(ngModel)]="transfer.amount"
          name="amount"
          placeholder="0.00"
          required
        />
      </mat-form-field>
      <button mat-stroked-button color="primary" type="submit" [disabled]="loading">Transfer In</button>
    </form>
  </mat-tab>

  <mat-tab class="transfer-budget-tab" label="Transfer Out">
    <form
      class="transfer-budget-tab-form"
      #transferOutForm="ngForm"
      (ngSubmit)="transferBudget('out', transferOutForm)"
      autocomplete="off"
    >
      <app-select-bucket-typeahead
        [buckets]="availableBuckets$ | async"
        [(selectedBucket)]="selectedBucket"
        [options]="{ showFundBalance: true }"
        [required]="true"
      ></app-select-bucket-typeahead>
      <mat-form-field class="add-bucket-amount" floatLabel="always" appearance="outline">
        <mat-label>Transfer Amount</mat-label>
        <span matPrefix>$&nbsp;</span>
        <input
          matInput
          type="number"
          min="0.01"
          step="0.01"
          [max]="maxTransfer$ | async"
          [(ngModel)]="transfer.amount"
          name="amount"
          placeholder="0.00"
          required
        />
      </mat-form-field>
      <button mat-stroked-button color="primary" type="submit" [disabled]="loading">Transfer Out</button>
    </form>
  </mat-tab>
</mat-tab-group>

<ng-template #singleOption>
  <form
    *ngIf="options.hideTransferOut"
    #transferInForm="ngForm"
    (ngSubmit)="transferBudget('in', transferInForm)"
    autocomplete="off"
  >
    <app-select-bucket-typeahead
      [buckets]="availableBuckets$ | async"
      [(selectedBucket)]="selectedBucket"
      [options]="{ showFundBalance: true }"
      [required]="true"
    ></app-select-bucket-typeahead>

    <mat-form-field class="add-bucket-amount" floatLabel="auto" appearance="outline">
      <mat-label>Transfer Amount</mat-label>
      <span matPrefix>$&nbsp;</span>
      <input
        matInput
        type="number"
        min="0.01"
        step="0.01"
        [max]="maxTransfer$ | async"
        [(ngModel)]="transfer.amount"
        name="amount"
        required
      />
    </mat-form-field>
    <button mat-stroked-button color="primary" type="submit" [disabled]="loading">Transfer In</button>
  </form>

  <form
    *ngIf="options.hideTransferIn"
    #transferOutForm="ngForm"
    (ngSubmit)="transferBudget('out', transferOutForm)"
    autocomplete="off"
  >
    <app-select-bucket-typeahead
      [buckets]="availableBuckets$ | async"
      [(selectedBucket)]="selectedBucket"
      [options]="{ showFundBalance: true }"
      [required]="true"
    ></app-select-bucket-typeahead>
    <mat-form-field class="add-bucket-amount" floatLabel="auto" appearance="outline">
      <mat-label>Transfer Amount</mat-label>
      <span matPrefix>$&nbsp;</span>
      <input
        matInput
        type="number"
        min="0.01"
        step="0.01"
        [max]="maxTransfer$ | async"
        [(ngModel)]="transfer.amount"
        name="amount"
        required
      />
    </mat-form-field>
    <button mat-stroked-button color="primary" type="submit" [disabled]="loading">Transfer Out</button>
  </form>
</ng-template>
