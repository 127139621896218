import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DrawerService } from '../../../services/drawer.service';
import { CategoryService } from '../../../services/category.service';
import { ChartService } from '../../../services/chart.service';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { of } from 'rxjs';
import { DRAWER_DATA } from 'src/app/shared/injector-tokens';
import isEqual from 'lodash.isequal';

@Component({
  selector: 'app-categories-pie-chart-drawer-wrapper',
  templateUrl: './categories-pie-chart-drawer-wrapper.component.html',
  styleUrls: ['./categories-pie-chart-drawer-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesPieChartDrawerWrapperComponent implements OnInit {
  chartData$ = this.categoryService.currentMonthCategoryPage$.pipe(
    map(categoryPage => {
      const expected = this.chartService.categoryChartExpected(categoryPage, this.data.categoryId);
      const actual = this.chartService.categoryChartActual(categoryPage, this.data.categoryId);
      return {
        expected: expected.some(e => e.amount > 0) ? expected : null,
        actual: actual.some(a => a.amount > 0) ? actual : null,
      };
    }),
    distinctUntilChanged((a, b) => isEqual(a, b)),
  );

  drawerTitle$ =
    this.data.categoryId && this.data.categoryId !== 'undefined'
      ? this.categoryService.currentMonthCategoryPage$.pipe(
          map(categoryPage => categoryPage.data.find(category => category.id === this.data.categoryId)),
          filter(category => !!category),
          map(category => `${category.name}`),
        )
      : of('Monthly Report');

  constructor(
    @Inject(DRAWER_DATA) public data: { categoryId?: string },
    public drawerService: DrawerService,
    public categoryService: CategoryService,
    private chartService: ChartService,
  ) {}

  ngOnInit() {}
}
