import { Component, OnInit, Input } from '@angular/core';
import { MonthService } from '../../services/month.service';
import { NavigationService } from '../../services/navigation.service';
import { AnalyticsService } from '../../services/analytics.service';
import {
  EVENT_PREVIOUS_MONTH_SELECTED,
  EVENT_TODAYS_MONTH_SELECTED,
  EVENT_NEXT_MONTH_SELECTED,
} from '../../analytics/events';
import { SessionService } from '../../services/session.service';
import { map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-month-selector',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss'],
})
export class MonthSelectorComponent implements OnInit {
  @Input() public currentMonthYear: string;

  public startMonthYear$ = this.session.session$.pipe(
    filter(session => !!session),
    map(session => session.account.startMonthYear),
  );

  monthSelector: boolean;
  constructor(
    private analytics: AnalyticsService,
    public nav: NavigationService,
    public month: MonthService,
    private session: SessionService,
  ) {}

  ngOnInit() {}

  previousMonth() {
    const previousMonthYear = this.month.findMonthYear(this.currentMonthYear, -1);
    this.nav.navigateToMonthYear(previousMonthYear);
    this.analytics.sendEvent(EVENT_PREVIOUS_MONTH_SELECTED);
  }

  todaysMonth() {
    const todaysMonthYear = this.month.monthYearFromDate();
    this.nav.navigateToMonthYear(todaysMonthYear);
    this.analytics.sendEvent(EVENT_TODAYS_MONTH_SELECTED);
  }

  nextMonth() {
    const nextMonthYear = this.month.findMonthYear(this.currentMonthYear, 1);
    this.nav.navigateToMonthYear(nextMonthYear);
    this.analytics.sendEvent(EVENT_NEXT_MONTH_SELECTED);
  }
}
