import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScriptService {
  constructor() {}

  public loadStripeSource(): Observable<any> {
    return this.loadBySource('https://js.stripe.com/v3/');
  }

  loadBySource(source: string): Observable<any> {
    return Observable.create(observer => {
      const scripts = document.getElementsByTagName('script');
      let isFound = false;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].getAttribute('src') === source) {
          isFound = true;
          observer.next();
          observer.complete();
        }
      }
      if (!isFound) {
        const node = document.createElement('script');
        node.src = source;
        node.type = 'text/javascript';
        node.async = false;
        document.getElementsByTagName('head')[0].appendChild(node);
        node.addEventListener('load', () => {
          console.log('loaded script: ', source);
          observer.next();
          observer.complete();
        });
      }
    });
  }
}
