import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesPageComponent } from './categories-page/categories-page.component';
import { CategoriesListComponent } from './categories-list/categories-list.component';
import { SharedModule } from '../shared/shared.module';
import { CategoryBucketListComponent } from './category-bucket-list/category-bucket-list.component';
import { FilterPipe, SortPipe } from '../pipes';
import { CategoryBucketSummaryInfoComponent } from './category-bucket-summary-info/category-bucket-summary-info.component';
import { CategoriesPageSummaryComponent } from './categories-page-summary/categories-page-summary.component';
import { CategoryBucketSummaryComponent } from './category-bucket-summary/category-bucket-summary.component';
import { BudgetInSyncComponent } from './budget-in-sync/budget-in-sync.component';
import { BudgetOverUnderComponent } from './budget-over-under/budget-over-under.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    CategoriesPageComponent,
    CategoriesListComponent,
    CategoryBucketListComponent,
    FilterPipe,
    SortPipe,
    CategoryBucketSummaryInfoComponent,
    CategoriesPageSummaryComponent,
    CategoryBucketSummaryComponent,
    BudgetInSyncComponent,
    BudgetOverUnderComponent,
  ],
  exports: [CategoriesPageComponent],
  providers: [CategoriesListComponent],
})
export class CategoriesModule {}
