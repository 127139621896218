import { Injectable, ComponentFactoryResolver, Type, Injector } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from './navigation.service';
export interface DrawerComponentData {
  type: Type<any>;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  navOpen = false;
  bottomSheetOpen = false;
  _currentComponent: ReplaySubject<DrawerComponentData> = new ReplaySubject(1);
  currentComponent$: Observable<DrawerComponentData> = this._currentComponent.asObservable();
  dragDistance = 0;
  constructor(private router: Router, private route: ActivatedRoute, private nav: NavigationService) {}

  private setDrawerParams(drawerParams: any) {
    const params = this.nav.params;

    const updatedParams: any = Object.keys(params)
      .filter(key => !key.includes('drawer'))
      .reduce((obj, key) => {
        if (params[key]) obj[key] = params[key];
        return obj;
      }, {});

    console.log('updatedParams', updatedParams);
    const urlWithoutRouteParams = this.router.url.split(';')[0];
    console.log('where to?', urlWithoutRouteParams);

    // this.router.navigateByUrl([{ ...updatedParams, ...drawerParams }], { relativeTo: this.route })
    this.router.navigate([urlWithoutRouteParams, { ...updatedParams, ...drawerParams }]);
  }

  openResolveExpectedIncomeMismatch() {
    this.setDrawerParams({ drawer: 'expected-income-mismatch' });
  }

  openAddBucketDrawer(categoryId?: string) {
    this.setDrawerParams({ drawer: 'new-budget', drawerCategoryId: categoryId });
  }

  openBucketDrawer(bucketId: string) {
    this.setDrawerParams({ drawerBudgetId: bucketId, drawer: 'budget' });
  }

  openAccountDrawer(accountId: string) {
    this.setDrawerParams({ drawerAccountId: accountId, drawer: 'account-details' });
  }

  openUnbudgetedDrawer() {
    this.setDrawerParams({ drawer: 'available' });
  }

  openCategoriesPieChartDrawer(categoryId?: string) {
    this.setDrawerParams({ drawer: 'categories-chart', drawerCategoryId: categoryId });
  }

  openAddCustomAccountDrawer() {
    this.setDrawerParams({ drawer: 'custom-account' });
  }

  openEditCustomAccountDrawer(accountId: string) {
    this.setDrawerParams({ drawer: 'custom-account', drawerAccountId: accountId });
  }

  openDocumentationDrawer(slug: string) {
    this.setDrawerParams({ drawer: 'documentation', drawerSlug: slug });
  }

  closeDrawer() {
    this.setDrawerParams({});
  }
}
