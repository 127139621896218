import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Transaction, Bucket } from '../../types';
// import * as clamp from 'clamp-js';
import { ConfirmDialogComponent } from '../../dialog-templates/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TransactionDataSource } from '../transaction-data-source.class';
import { BucketService } from '../../services/bucket.service';
import { map, take } from 'rxjs/operators';
import { TransactionService } from 'src/app/services/transaction.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-transaction-list-item',
  templateUrl: './transaction-list-item.component.html',
  styleUrls: ['./transaction-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.2s ease-out', style({ height: 241, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 241, opacity: 1 }),
        animate('.1s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class TransactionListItemComponent implements OnDestroy {
  @Input() transaction: Transaction;
  @Input() transactionDataSource: TransactionDataSource;
  @Input() isSelected: boolean;
  @Output() selectionToggled: EventEmitter<{ isSelected: boolean; transaction: Transaction }> = new EventEmitter();
  @ViewChild('element') element: ElementRef;

  editing = false;

  isEditing$ = this.transactionService.editingInTransactionList$.pipe(map(value => value === this.transaction.id));

  constructor(
    private dialog: MatDialog,
    private bucketService: BucketService,
    private transactionService: TransactionService,
  ) {}

  // ngAfterViewInit() {
  // const nativeElement = this.element.nativeElement;
  // if (nativeElement) {
  //   const merchantName = nativeElement.querySelector('.transaction-list-item-merchant');
  //   const accountDetails = nativeElement.querySelector('.transaction-list-item-account');
  //   // // console.log(merchantName, accountDetails);
  //   // if (merchantName) clamp(merchantName, { clamp: 2 });
  //   // if (accountDetails) clamp(accountDetails, { clamp: 1 });
  // }
  // }

  toggleTransactionListEditing() {
    this.transactionService.editingInTransactionList$.pipe(take(1)).subscribe(value => {
      if (value === this.transaction.id) this.transactionService.editingInTransactionList$.next(null);
      else this.transactionService.editingInTransactionList$.next(this.transaction.id);
    });
  }

  closeTransactionListEditing() {
    this.transactionService.editingInTransactionList$.pipe(take(1)).subscribe(value => {
      if (value === this.transaction.id) this.transactionService.editingInTransactionList$.next(null);
    });
  }

  transactionSelectionToggled({ source, value }) {
    this.selectionToggled.emit({ isSelected: source.checked, transaction: value });
  }

  whyLocked(reason: string) {
    const reasons = {
      ARCHIVED_BUCKET: 'This transaction is locked, because it is in an archived budget.',
    };
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: reasons[reason],
        confirmColor: 'primary',
        onConfirm: () => {},
      },
    });
  }

  openDeleteTransferModal(transfer: Transaction) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this transfer?',
        cancelText: 'Cancel',
        confirmText: 'Delete',
        confirmColor: 'warn',
        onConfirm: () => this.transactionDataSource.dispatch({ type: 'DELETE_TRANSFERS', value: [this.transaction] }),
      },
    });
  }

  selectedBucketChange(bucket: Bucket) {
    this.transaction.selectedBucket = bucket;
  }

  ngOnDestroy() {
    this.closeTransactionListEditing();
  }
}
