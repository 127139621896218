<mat-toolbar *ngIf="selectedCount > 0">
  <div class="flex-row">
    <div class="title">{{ selectedCount }} selected</div>
    <div class="spacer"></div>
    <mat-spinner *ngIf="transactionList.loading.reloading" [strokeWidth]="2" [diameter]="40"></mat-spinner>
    <div *ngIf="!transactionList.loading.reloading" class="actions">
      <button mat-icon-button type="button" (click)="clearSelected()"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
