<section class="sidenav" *ngIf="userService.user$ | async; let user">
  <mat-toolbar class="sidenav-header">
    <span class="sidenav-username">{{ user.name }}</span>
    <div class="spacer"></div>
    <button mat-icon-button [matMenuTriggerFor]="sideNavMenu">
      <mat-icon aria-label="settings options">more_vert</mat-icon>
    </button>
  </mat-toolbar>
  <div class="sidenav-content">
    <button mat-button (click)="navigateTo(['/transactions/sort'])">Sort Transactions</button>
    <button mat-button (click)="navigateTo(['/transactions'])">View Transactions</button>
    <button mat-button (click)="navigateTo(['/accounts'])">View Accounts</button>
    <button mat-button *ngIf="!user.completedTutorial" (click)="navigateTo(['/getting-started'])">
      Getting Started
    </button>
    <button mat-button *ngIf="user.superAdmin" (click)="navigateTo(['/admin'])">Admin</button>
  </div>
</section>
<mat-menu #sideNavMenu="matMenu">
  <button mat-menu-item (click)="navigateTo(['/account/settings'])">
    <mat-icon>settings</mat-icon>
    <span>Account Settings</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>directions_run</mat-icon>
    <span>Sign Out</span>
  </button>
</mat-menu>
