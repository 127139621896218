import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { BucketService } from '../../services/bucket.service';
import { Bucket } from '../../types';
import { TransactionService } from '../../services/transaction.service';
import { CategoryService } from '../../services/category.service';
import { TransactionDataSource } from '../../transactions/transaction-data-source.class';
import { MonthService } from '../../services/month.service';
import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-bucket-transaction-list',
  templateUrl: './bucket-transaction-list.component.html',
  styleUrls: ['./bucket-transaction-list.component.scss'],
})
export class BucketTransactionListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public bucket: Bucket;

  hideTransactionList: boolean;
  public transactionDataSource: TransactionDataSource;
  private transactionChangesSub: Subscription;

  constructor(
    public bucketService: BucketService,
    private transactionService: TransactionService,
    public categoryService: CategoryService,
    private monthService: MonthService,
    public accountService: AccountService,
  ) {}

  private setupDataSource(data: TransactionDataSource) {
    this.transactionDataSource = data;
    if (this.transactionChangesSub) {
      this.transactionChangesSub.unsubscribe();
    }
    this.transactionChangesSub = data.transactionsLoading$
      .pipe(
        switchMap(() =>
          this.monthService.currentMonthYear$.pipe(
            take(1),
            switchMap(month => this.categoryService.loadCategory$(month)),
          ),
        ),
      )
      .subscribe();
  }

  ngOnInit() {
    this.hideTransactionList = !this.bucket || this.bucket.transactions.totalRecords === 0;
    this.setupDataSource(new TransactionDataSource(this.bucket, this.transactionService, this.bucketService));
  }

  ngOnChanges() {
    this.hideTransactionList = !this.bucket || this.bucket.transactions.totalRecords === 0;
    this.setupDataSource(new TransactionDataSource(this.bucket, this.transactionService, this.bucketService));
  }

  ngOnDestroy() {
    if (this.transactionChangesSub) {
      this.transactionChangesSub.unsubscribe();
    }
  }
}
