<button class="upgrade-card-wrapper" mat-button (click)="handlePayment()" [disabled]="loading">
  <mat-card class="upgrade-card">
    <div class="upgrade-card-row">
      <div class="upgrade-card-content">
        <div class="upgrade-card-title">Add a Payment Method</div>

        <span *ngIf="trialEndsInDays > 0">Your trial ends in {{ trialEndsInDays }} days.</span>
        <span *ngIf="trialEndsInDays == 0">Your trial is ending today.</span>
        <ng-container *ngIf="trialEndsInDays && trialEndsInDays >= 0">
          Adding a payment method now allows us to continue syncing your transactions when your trial is finished.
        </ng-container>
        <span *ngIf="!trialEndsInDays || trialEndsInDays < 0"
          >Your trial has ended. Upgrade now to continue syncing your transactions.</span
        >
      </div>
      <div class="upgrade-card-actions">
        <mat-icon aria-hidden="true" inline="true">add</mat-icon>
      </div>
    </div>
  </mat-card>
</button>
