import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { UserService } from '../services/user.service';
import { Observable, of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { AuthGuard } from './auth.guard';
@Injectable()
export class GettingStartedGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router, private authGuard: AuthGuard) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard.canActivate(next, state).pipe(
      switchMap(canActivate => {
        if (!canActivate) return of(false);
        return this.userService.user$.pipe(
          map(user => {
            if (user && !user.completedTutorial) this.router.navigate(['getting-started']);
            return user && user.completedTutorial;
          }),
        );
      }),
    );
  }
}
