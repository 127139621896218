import { Component, OnInit, Inject } from '@angular/core';
import { AccountService } from '../../../services/account.service';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs';
import { DRAWER_DATA } from 'src/app/shared/injector-tokens';
import { DrawerService } from 'src/app/services/drawer.service';

@Component({
  selector: 'app-account-details-drawer-wrapper',
  templateUrl: './account-details-drawer-wrapper.component.html',
  styleUrls: ['./account-details-drawer-wrapper.component.scss'],
})
export class AccountDetailsDrawerWrapperComponent implements OnInit {
  ac$ = zip(
    this.accountService.accountByAccountId$(this.data.accountId),
    this.accountService.credentialByAccountId$(this.data.accountId),
  ).pipe(map(([account, credential]) => ({ account, credential })));

  constructor(
    @Inject(DRAWER_DATA) private data: any,
    private accountService: AccountService,
    private drawerService: DrawerService,
  ) {}

  closeDrawer() {
    this.drawerService.closeDrawer();
  }

  ngOnInit() {}
}
