<mat-toolbar class="nav" [color]="(impersonating$ | async) ? 'warn' : 'primary'">
  <div class="flex-row">
    <ng-container
      *ngIf="(sessionService.session$ | async) && !nav.currentRoute.startsWith('/getting-started'); else justLogo">
      <ng-container *ngIf="!showMonthSelector; else monthSelector">
        <button class="nav-home" type="button" mat-icon-button routerLinkActive="nav-active" routerLink="/budgets"
          [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon class="material-icons" svgIcon="logo"></mat-icon>
        </button>
        <button class="nav-sort" type="button" mat-icon-button routerLinkActive="nav-active"
          [routerLinkActiveOptions]="{ exact: true }" [routerLink]="transactionButtonRoute$ | async">
          <mat-icon class="material-icons" svgIcon="sort"></mat-icon>
          <ng-container *ngIf="sessionService.unsortedTransactionsCount$ | async; let unsortedTransactionsCount">
            <span *ngIf="unsortedTransactionsCount > 0 && unsortedTransactionsCount < 10" class="nav-sort-count">{{
              unsortedTransactionsCount
              }}</span>
            <span *ngIf="unsortedTransactionsCount >= 10" class="nav-sort-count">9+</span>
          </ng-container>
        </button>
        <button *ngIf="!(hasNewSortFeature$ | async)" class="nav-transactions" type="button" mat-icon-button
          routerLinkActive="nav-active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/transactions">
          <mat-icon class="material-icons" svgIcon="list"></mat-icon>
        </button>
        <button class="nav-accounts" type="button" mat-icon-button routerLinkActive="nav-active"
          [routerLinkActiveOptions]="{ exact: true }" routerLink="/accounts">
          <mat-icon class="material-icons" svgIcon="money-check"></mat-icon>
          <ng-container
            *ngIf="sessionService.deauthenticatedCredentialsCount$ | async; let deauthenticatedCredentialsCount">
            <span *ngIf="deauthenticatedCredentialsCount > 0 && deauthenticatedCredentialsCount < 10"
              class="nav-accounts-count">{{ deauthenticatedCredentialsCount }}</span>
            <span *ngIf="deauthenticatedCredentialsCount >= 10" class="nav-accounts-count">9+</span>
          </ng-container>
        </button>

        <div class="spacer"></div>

        <ng-container *ngIf="currentMonthYear$ | async; let currentMonthYear">
          <button *ngIf="month.showSelector" mat-stroked-button class="nav-date" (click)="showMonthSelector = true">
            {{ month.formatMonthYear(currentMonthYear, 'MMM yy') }}
          </button>
        </ng-container>

        <button class="nav-profile" type="button" mat-icon-button [matMenuTriggerFor]="profileMenu">
          <div class="nav-profile-circle">{{ initials$ | async }}</div>
          <!-- <mat-icon class="material-icons" svgIcon="profile"></mat-icon> -->

          <mat-menu #profileMenu="matMenu">
            <button mat-menu-item (click)="navigateTo(['/account/settings'])">
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>
            <app-feedback>
              <button mat-menu-item>
                <mat-icon svgIcon="envelope"></mat-icon>
                <span>Message Us</span>
              </button>
            </app-feedback>
            <button mat-menu-item (click)="logout()">
              <mat-icon svgIcon="transfer-out"></mat-icon>
              <span>Sign Out</span>
            </button>
            <button mat-menu-item *ngIf="(userService.user$ | async).superAdmin" (click)="navigateTo(['/admin'])">
              <mat-icon svgIcon="cogs"></mat-icon>
              <span>Admin</span>
            </button>
            <button type="button" mat-menu-item *ngIf="impersonating$ | async" (click)="userService.endImpersonation()">
              <mat-icon class="material-icons">exit_to_app</mat-icon>
              End impersonation
            </button>
          </mat-menu>
        </button>
      </ng-container>
    </ng-container>
    <ng-template #monthSelector>
      <button class="nav-home" type="button" mat-icon-button [disableRipple]="true">
        <mat-icon class="material-icons" svgIcon="logo"></mat-icon>
      </button>
      <div class="spacer"></div>
      <app-month-selector [currentMonthYear]="currentMonthYear$ | async"></app-month-selector>
      <button mat-icon-button (click)="showMonthSelector = false">
        <mat-icon>close</mat-icon>
      </button>
    </ng-template>
    <ng-template #justLogo>
      <button class="nav-home" type="button" mat-icon-button [disableRipple]="true">
        <mat-icon class="material-icons" svgIcon="logo"></mat-icon>
      </button>

      <h1 class="nav-title">{{ nav.title }}</h1>
    </ng-template>
  </div>
</mat-toolbar>