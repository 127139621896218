import { Pipe, PipeTransform } from '@angular/core';
import Fuse from 'fuse.js';

@Pipe({
  name: 'fuzzy',
  pure: false,
})
export class FuzzyPipe implements PipeTransform {
  transform(items: any[], keys: string[], term: string): any {
    if (!term) return items;

    const fuseSearch = new Fuse(items, {
      keys,
      shouldSort: true,
      threshold: 0.6,
    });

    const results = fuseSearch.search(term).map(({ item }) => item);
    return results;
  }
}
