import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendService } from '../../services/backend.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-account-dialog',
  templateUrl: './reset-account-dialog.component.html',
  styleUrls: ['./reset-account-dialog.component.scss'],
})
export class ResetAccountDialogComponent implements OnInit {
  password = null;
  badPassword = false;
  loading = false;
  syncStartDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ResetAccountDialogComponent>,
    public backend: BackendService,
    public userService: UserService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const now = new Date();
    this.syncStartDate = new Date(now.getFullYear(), now.getMonth(), 1);
  }

  resetAccount(password) {
    this.loading = true;
    this.backend
      .request({ type: 'post', apiRoute: 'account/reset', data: { password, deleteBuckets: true } })
      .subscribe(
        res =>
          this.userService.refreshSession$.subscribe(() => {
            this.router.navigate(['/getting-started']);
            this.dialogRef.close();
          }),
        err => {
          console.log(err);
          this.loading = false;
          this.badPassword = err.error.errors[0] === 'Incorrect password';
        },
      );
  }

  openResetBalancesDialog() {
    this.dialogRef.close();
    this.data.openResetBalancesDialog();
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
