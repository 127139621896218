import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { tap, filter, map, take } from 'rxjs/operators';
import { RouteData } from '../types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticsService } from './analytics.service';
import { MonthService } from './month.service';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  previousRoute: string;
  currentRoute = this.router.url;
  routes$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(route => this.router.url),
  );
  private _currentParams: ReplaySubject<Params> = new ReplaySubject(1);
  currentParams$ = this._currentParams.asObservable();
  sideNav: boolean;
  title = 'Budgets';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
    private month: MonthService,
  ) {
    this.routes$
      .pipe(
        map(nextRoute => {
          const indexOfParams = nextRoute.indexOf(';');
          if (indexOfParams > -1) return nextRoute.substr(0, indexOfParams);
          return nextRoute;
        }),
        tap(nextRoute => {
          if (this.currentRoute !== nextRoute)
            this.previousRoute = this.currentRoute === '/' ? '/budgets' : this.currentRoute;
        }),
      )
      .subscribe(currentRoute => {
        this.currentRoute = currentRoute;
        console.log('Route Change - Previous: ', this.previousRoute, 'Current: ', this.currentRoute);
      });
  }

  setParams(params: Params) {
    this._currentParams.next(params);
  }

  get params(): Params {
    let route = this.route;
    let params = {};
    while (route.firstChild) {
      params = { ...route.firstChild.snapshot.params };
      route = route.firstChild;
    }
    return params;
  }

  get routeData(): RouteData {
    let route = this.route;
    let data = {};
    while (route.firstChild) {
      data = { ...route.firstChild.snapshot.data };
      route = route.firstChild;
    }
    return data;
  }

  goBack() {
    this.month.currentMonthYear$.pipe(take(1)).subscribe(monthYear => {
      console.log('route data', this.routeData);
      if (this.routeData.goBackTo) return this.router.navigateByUrl(this.routeData.goBackTo);
      return this.previousRoute ? this.navigateToMonthYear(monthYear, this.previousRoute) : this.goHome();
    });
  }

  goHome() {
    this.route.params.subscribe(params => {
      this.router.navigate(['/budgets', { ...params }]);
    });
  }

  navigateTo(location: string[]) {
    this.router.navigate(location);
  }

  navigateToMonthYear(monthYear: string, location?: string) {
    console.log(this.route);
    const newParams = { ...this.params };
    newParams.month = monthYear;
    if (monthYear === this.month.monthYearFromDate()) {
      delete newParams.month;
    }

    return location
      ? this.router.navigate([location, newParams])
      : this.router.navigate([newParams], { relativeTo: this.route });
  }

  getChildRoute(route) {
    while (route.firstChild) route = route.firstChild;
    return route;
  }
}
