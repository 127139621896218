import { Component, OnInit, Input } from '@angular/core';
import { Credential } from '../../types';
import { AnalyticsService } from '../../services/analytics.service';
import { AccountService } from '../../services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog-templates/confirm-dialog/confirm-dialog.component';
import { EVENT_TRIED_DELETING_ACCOUNT } from '../../analytics/events';

@Component({
  selector: 'app-credential',
  templateUrl: './credential.component.html',
  styleUrls: ['./credential.component.scss'],
})
export class CredentialComponent implements OnInit {
  @Input() credential: Credential;
  refreshing: boolean = false;
  loadingPlaid: boolean = false;
  constructor(private analytics: AnalyticsService, private accountService: AccountService, private dialog: MatDialog) {}

  ngOnInit() {}

  confirmRemoveCredential(credential) {
    this.analytics.sendEvent(EVENT_TRIED_DELETING_ACCOUNT);
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `Are you sure you want to delete this account?`,
        confirmColor: 'warn',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        onConfirm: () => this.accountService.deleteCredential$(credential).subscribe(),
      },
    });
  }

  reauthenticate(credential) {
    this.loadingPlaid = true;
    this.accountService
      .initializePlaidLink$({
        path: '/accounts',
        credentialId: credential.id,
        onLoad: () => {
          this.loadingPlaid = false;
        },
      })
      .subscribe(plaid => {
        plaid.open();
      });
  }
}
