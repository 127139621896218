import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { SessionService } from './session.service';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  constructor(private backend: BackendService, private session: SessionService, private data: DataService) {}

  setNotificationToken(token: string): void {
    this.data.setLocalStorage('notificationToken', token, { persist: true });
    this.session.session$
      .pipe(
        filter(session => !!session),
        switchMap(session => {
          console.log('calling update mobile token endpoint');
          return this.backend.request({ apiRoute: 'user/notificationToken', type: 'update', data: { token } });
        }),
      )
      .subscribe();
  }

  getAuthorizationHeader(): string {
    return this.session.storedSession.jwt;
  }
}
