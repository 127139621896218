<div class="documentation-drawer">
  <app-drawer-header [title]="title$ | async"> </app-drawer-header>
  <div class="documentation-drawer-body">
    <a class="documentation-drawer-link" href="https://www.budgets.money/{{ slug$ | async }}" target="_blank">
      <button type="button" mat-icon-button aria-label="link out to documentation" color="accent">
        <mat-icon aria-label="none">
          launch
        </mat-icon>
      </button>
    </a>
    <documentation *ngIf="content$ | async; let content" [content]="content"></documentation>
  </div>
</div>