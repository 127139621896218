<div
  class="transaction-list-item-content"
  [ngClass]="{
    'no-account': !transaction.plaidAccount
  }"
>
  <div class="transaction-list-item-content-row-top">
    <span class="transaction-list-item-merchant">
      {{ transaction.merchant }}
    </span>

    <span [ngClass]="{ pending: transaction?.bankMeta?.pending }">
      <mat-icon *ngIf="transaction?.bankMeta?.pending">schedule</mat-icon>

      <span class="transaction-list-item-amount" [ngClass]="{ good: transaction.netValue > 0 }">
        {{ transaction.amount | currency }}
      </span>
    </span>
  </div>
  <div class="transaction-list-item-content-row-bottom">
    <ng-container
      *ngIf="
        needsSaving ||
          !transaction.bucketId ||
          (transactionService.editingInTransactionList$ | async) === transaction.id;
        else withoutBucketSelector
      "
    >
      <span class="transaction-list-item-bucket">
        <bucket-selector
          [transaction]="transaction"
          [buckets]="buckets$ | async"
          [selectedBucket]="transaction.selectedBucket"
          [needsSaving]="needsSaving"
          (changeSelectedBucket)="changeSelectedBucket($event)"
          (saveBucket)="onSaveBucket($event)"
        ></bucket-selector>
      </span>
    </ng-container>

    <ng-template #withoutBucketSelector>
      <span class="transaction-list-item-bucket">
        <span class="transaction-list-item-bucket"
          >{{ (bucketById$(transaction.bucketId) | async)?.name }}
          <ng-container *ngIf="transaction.description"> - </ng-container>
        </span>
      </span>
      <span class="transaction-list-item-description" *ngIf="transaction.description">
        {{ transaction.description }}
      </span>
    </ng-template>

    <span *ngIf="transaction.plaidAccount" class="transaction-list-item-account">
      <span class="transaction-list-item-account-name">{{
        transaction.plaidAccount.nickname || transaction.plaidAccount.officialName || transaction.plaidAccount.name
      }}</span>
      <span class="transaction-list-item-account-number" *ngIf="transaction.plaidAccount.mask"
        >| {{ transaction.plaidAccount.mask }}</span
      >
    </span>
  </div>
</div>
