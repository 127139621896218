import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { BackendService } from '../../services/backend.service';
import { CategoryService } from '../../services/category.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog-templates/confirm-dialog/confirm-dialog.component';
import { MonthService } from '../../services/month.service';
import { AnalyticsService } from '../../services/analytics.service';
import {
  EVENT_REORDERED_CATEGORY,
  EVENT_RENAMED_CATEGORY,
  EVENT_DELETED_CATEGORY,
  EVENT_DELETED_CATEGORY_CANCELED,
} from '../../analytics/events';
import { Category, CategoryPage } from '../../types';
import { switchMap } from 'rxjs/operators';
import { DrawerService } from '../../services/drawer.service';
import { FormDialogComponent } from '../../dialog-templates/form-dialog/form-dialog.component';
import { NgForm } from '@angular/forms';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesListComponent {
  @Input() categories: Category[];
  @Input() reordering: boolean;

  categorySortUpdate = (event: CdkDragDrop<Category[]>) => {
    if (event.previousIndex === event.currentIndex) return;
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    this.backend
      .request({
        type: 'update',
        apiRoute: 'category/reorder',
        data: {
          id: event.item.data.id,
          sortIndex: event.currentIndex,
        },
      })
      .subscribe(() => this.analytics.sendEvent(EVENT_REORDERED_CATEGORY));
  };

  constructor(
    private analytics: AnalyticsService,
    private backend: BackendService,
    public categoryService: CategoryService,
    private dialog: MatDialog,
    private month: MonthService,
    public drawerService: DrawerService,
  ) {}

  categoryTrackBy(index: number, category: any) {
    return category.id;
  }

  updateCategoryName(category) {
    category.name = category.newCategoryName;
    category.rename = false;
    this.backend
      .request({
        type: 'update',
        apiRoute: 'category',
        data: {
          id: category.id,
          name: category.newCategoryName,
        },
      })
      .subscribe(() => this.analytics.sendEvent(EVENT_RENAMED_CATEGORY));
  }

  confirmDeleteCategory(category: Category) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `Are you sure you want to delete the category <b>${category.name}</b>?${
          category.buckets.length > 0 ? `<br><br>Your budgets will be moved to Uncategorized.` : ''
        }`,
        cancelText: 'Cancel',
        confirmText: 'Delete',
        confirmColor: 'warn',
        onConfirm: () => this.deleteCategory(category.id),
        onClose: () => this.analytics.sendEvent(EVENT_DELETED_CATEGORY_CANCELED),
      },
    });
  }

  deleteCategory(id) {
    return this.backend
      .request({ type: 'delete', apiRoute: `category/${id}` })
      .pipe(switchMap(() => this.categoryService.loadCategory$()))
      .subscribe(() => {
        this.analytics.sendEvent(EVENT_DELETED_CATEGORY);
      });
  }

  getBudgeted(category: CategoryPage) {
    return category.budget;
  }

  get currentMonthYear$() {
    return this.month.currentMonthYear$;
  }

  openAddCategoryDialog() {
    this.dialog.open(FormDialogComponent, {
      data: {
        title: 'Create a new group.',
        formType: 'textInput',
        submitText: 'Add',
        form: {
          placeholder: 'Add New Group',
          maxlength: '24',
        },
        onSubmit: (form: NgForm) => this.addNewCategory(form.value.inputValue),
      },
    });
  }

  addNewCategory(name: string) {
    this.backend
      .request({
        type: 'post',
        apiRoute: 'category',
        data: { name },
      })
      .pipe(switchMap(() => this.categoryService.loadCategory$()))
      .subscribe();
  }
}
