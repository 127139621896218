import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { Transaction, Bucket } from '../../types';

@Component({
  selector: 'app-split-transaction',
  templateUrl: './split-transaction.component.html',
  styleUrls: ['./split-transaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SplitTransactionComponent implements OnInit {
  @Output() splitTransaction = new EventEmitter<Transaction | boolean>();
  @Output() transactionChange = new EventEmitter<any>();

  @Input() get transaction() {
    return this.transactionValue;
  }
  @Input() buckets: Bucket[];

  transactionValue: any;

  set transaction(val) {
    this.transactionValue = val;
    this.transactionChange.emit(this.transactionValue);
  }

  constructor() {}

  ngOnInit() {
    if (!this.transaction.children)
      this.transaction.children = this.transaction.parent ? this.transaction.parent.children : [];
    if (this.transaction.children.length < 1) this.addSplit();
  }

  get originalTransactionAmount() {
    return this.transaction.parent ? this.transaction.parent.amount : this.transaction.amount;
  }

  get children(): Array<Transaction> {
    if (!this.transaction.children) return [{ ...this.transaction }];
    return this.transaction.children;
  }

  saveSplit() {
    const children = this.children.map(child => {
      delete child.parent;
      return child;
    });
    this.transaction.showSplit = false;
    if (this.transaction.parent) {
      if (children.length === 1) {
        const newParent = {
          ...this.transaction.parent,
          ...children[0],
          id: this.transaction.parent.id,
        };
        return this.splitTransaction.emit(newParent);
      }
      this.transaction.parent.children = children;
      return this.splitTransaction.emit(this.transaction.parent);
    }
    this.transaction.children = children;
    return this.splitTransaction.emit(this.transaction);
  }

  cancelSplit() {
    this.transaction.showSplit = false;
  }

  percent(child) {
    return this.roundNumber((child.amount / this.originalTransactionAmount) * 100);
  }

  get totalAmount(): number {
    return this.children.reduce((acc, curr) => parseFloat((curr.amount + acc).toFixed(2)), 0);
  }

  get totalPercent(): number {
    return parseFloat(
      ((this.children.reduce((acc, curr) => curr.amount + acc, 0) / this.originalTransactionAmount) * 100).toFixed(2),
    );
  }

  get diff(): number {
    return this.totalAmount - this.originalTransactionAmount;
  }

  setValueFromPercent(i, percent: number) {
    this.transaction.children[i].amount = this.roundNumber((this.originalTransactionAmount * percent) / 100);
  }

  addSplit() {
    const newChild = { ...this.transaction };
    delete newChild.children;
    delete newChild.id;
    if (this.totalAmount < this.originalTransactionAmount) {
      newChild.amount = this.roundNumber(this.originalTransactionAmount - this.totalAmount);
    } else if (this.children.length > 0) newChild.amount = 0;
    this.transaction.children.push(newChild);
  }

  deleteSplit(i) {
    this.transaction.children.splice(i, 1);
  }

  roundNumber(num) {
    return Math.round(num * 100) / 100;
  }

  getBucket(id) {
    return this.buckets.find(bucket => bucket.id === id);
  }
}
