import { Component, OnInit, Inject } from '@angular/core';
import { CategoryService } from '../../../services/category.service';
import { DrawerService } from '../../../services/drawer.service';
import { DRAWER_DATA } from 'src/app/shared/injector-tokens';

@Component({
  selector: 'app-unbudgeted-drawer-wrapper',
  templateUrl: './unbudgeted-drawer-wrapper.component.html',
  styleUrls: ['./unbudgeted-drawer-wrapper.component.scss'],
})
export class UnbudgetedDrawerWrapperComponent implements OnInit {
  constructor(
    @Inject(DRAWER_DATA) private data: any,
    public categoryService: CategoryService,
    private drawer: DrawerService,
  ) {}

  ngOnInit() {}

  handleTransferSuccess(event) {
    this.categoryService.loadCategory$().subscribe();
  }

  getInitialAmount(categoryPage) {
    return Math.abs(categoryPage.unbudgeted?.unbudgeted);
  }
}
