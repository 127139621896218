import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ReplaySubject, Observable, from } from 'rxjs';
import { TransactionPage } from '../../types';
import { TransactionList } from '../transaction-list.class';
import { BucketService } from '../../services/bucket.service';
import { NavigationService } from '../../services/navigation.service';
import { TransactionService } from '../../services/transaction.service';

@Component({
  selector: 'app-unbudgeted-transactions-view',
  templateUrl: './unbudgeted-transactions-view.component.html',
  styleUrls: ['./unbudgeted-transactions-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnbudgetedTransactionsViewComponent implements OnInit {
  _transactionPage: ReplaySubject<TransactionPage> = new ReplaySubject(1);
  transactionPage$: Observable<TransactionPage> = from(this._transactionPage);
  transactionList: TransactionList = new TransactionList(this.transactionPage$, this.transactionService, {
    selectable: true,
  });
  constructor(
    public bucketService: BucketService,
    private nav: NavigationService,
    private transactionService: TransactionService,
  ) {}

  ngOnInit() {
    this.nav.title = 'transactions';
    this.transactionService.loadUnbudgetedTransactions$().subscribe((transactions: TransactionPage) => {
      this._transactionPage.next(transactions);
    });
  }

  handleTransactionListEvents(action) {
    this.transactionList.reduce(action).subscribe(transactionPage => this._transactionPage.next(transactionPage));
  }
}
