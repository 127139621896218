<mat-toolbar *ngIf="transactionDataSource?.selectedTransactions$ | async; let selectedTransactions">
  <div class="flex-row">
    <p>
      {{ selectedTransactions.length }} transaction<span *ngIf="selectedTransactions.length !== 1">s</span>
      selected
    </p>
    <div class="spacer"></div>
    <button type="button" mat-icon-button [disabled]="selectedTransactions.length < 1" (click)="editTransactions()">
      <mat-icon svgIcon="pencil"></mat-icon>
    </button>
  </div>
</mat-toolbar>
<div class="sort-transactions-page" *ngIf="transactionDataSource">
  <app-sort-transactions-list
    [transactionDataSource]="transactionDataSource"
    *ngIf="(transactionDataSource.transactions$ | async)?.length > 0; else noTransactions"
  ></app-sort-transactions-list>
  <div>
    <app-sort-transactions-form
      [transactionDataSource]="transactionDataSource"
      [buckets]="buckets$ | async"
    ></app-sort-transactions-form>
    <button class="test-button" *ngIf="env === 'dev'" mat-button type="button" (click)="addUnbudgetedTransaction()">
      Add Test Transaction
    </button>
  </div>
</div>

<ng-template #noTransactions>
  <div class="empty-state">
    <div class="empty-state-title"><mat-icon>check_circle_outline</mat-icon></div>
    <div class="empty-state-content">We will keep syncing.<br />Check again soon for more transactions to sort.</div>
  </div>
</ng-template>
