import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { TransactionService } from '../../services/transaction.service';
import { TransactionPage, User, Action } from '../../types';
import { ReplaySubject, Observable, from, Subscription } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { BucketService } from '../../services/bucket.service';
import { NavigationService } from '../../services/navigation.service';
import { DateTime } from 'luxon';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../services/analytics.service';
import { EVENT_SELECTED_TRANSACTIONS_DELETED } from '../../analytics/events';
import { EditTransactionsDialogComponent } from '../edit-transactions-dialog/edit-transactions-dialog.component';
import { TransactionDataSource } from '../transaction-data-source.class';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-sort-transactions-page',
  templateUrl: './sort-transactions-page.component.html',
  styleUrls: ['./sort-transactions-page.component.scss'],
})
export class SortTransactionsPageComponent implements OnInit, OnDestroy {
  sorting = false;
  private subscription: Subscription = new Subscription();
  env = environment.environment;
  transactionDataSource: TransactionDataSource;

  constructor(
    public dialog: MatDialog,
    private analytics: AnalyticsService,
    private bucketService: BucketService,
    private nav: NavigationService,
    public transactionService: TransactionService,
    private userService: UserService,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.nav.title = 'sort';
    this.bucketService.loadAllBuckets();
    this.subscription.add(
      this.transactionService.loadUnbudgetedTransactionsWithSuggestions$().subscribe(transactionsPage => {
        this.transactionDataSource = new TransactionDataSource(
          transactionsPage,
          this.transactionService,
          this.bucketService,
          { withoutDateHeaders: true, loadFirstPageOnly: true },
        );
      }),
    );
    this.sessionService.session$.pipe(take(1)).subscribe(session => {
      if (session.enabledFeatures.includes('new-sort')) this.nav.navigateTo(['transactions']);
    });
  }

  handleTransactionListEvents(action) {
    if (action.type === 'DELETE_TRANSACTIONS') this.analytics.sendEvent(EVENT_SELECTED_TRANSACTIONS_DELETED);
    this.transactionDataSource.selectedTransactions$.pipe(take(1)).subscribe(selectedTransactions => {
      this.transactionDataSource.dispatch({ ...action, value: selectedTransactions });
    });
  }

  get buckets$() {
    return this.bucketService.nonArchivedBuckets$;
  }

  addUnbudgetedTransaction() {
    const date = DateTime.local().toFormat('yyyy-MM-dd');
    this.userService.user$
      .pipe(
        take(1),
        switchMap(user =>
          this.transactionService.addTransaction({
            amount: 1,
            createdByUserId: user.id,
            date,
            merchant: 'hello world',
            type: 'EXPENSE',
          }),
        ),
      )
      .subscribe(() => this.transactionDataSource.dispatch({ type: 'REFRESH_TRANSACTIONS' }));
  }

  editTransactions() {
    this.dialog.open(EditTransactionsDialogComponent, {
      data: {
        transactionDataSource: this.transactionDataSource,
      },
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
