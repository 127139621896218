import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Transaction, Action, Bucket } from '../../types';
import { TransactionList } from '../../transactions/transaction-list.class';
import { MatDialog } from '@angular/material/dialog';
import { EditTransactionsDialogComponent } from '../edit-transactions-dialog/edit-transactions-dialog.component';

@Component({
  selector: 'app-selected-transaction-toolbar',
  templateUrl: './selected-transaction-toolbar.component.html',
  styleUrls: ['./selected-transaction-toolbar.component.scss'],
})
export class SelectedTransactionToolbarComponent implements OnInit {
  @Input() type: string;
  @Input() buckets: Array<Bucket>;
  @Input() transactionList: TransactionList;
  @Output() transactionListEvent = new EventEmitter<Action>();

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  editTransactions() {
    this.dialog.open(EditTransactionsDialogComponent, {
      data: {
        buckets: this.buckets,
        transactionList: this.transactionList,
        transactionListEvent: this.transactionListEvent,
      },
    });
  }

  get selected() {
    return this.transactionList.selection.selected;
  }

  get selectedCount() {
    return this.selected.length;
  }

  clearSelected() {
    this.transactionList.selection.clear();
  }
}
