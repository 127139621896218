import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '../../services/session.service';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-configure-accounts-dialog',
  templateUrl: './configure-accounts-dialog.component.html',
  styleUrls: ['./configure-accounts-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigureAccountsDialogComponent {
  minimumDate$ = this.session.session$.pipe(
    map(session => DateTime.fromISO(session.account.startMonthYear).toJSDate()),
  );
  syncStartDate: Date;

  constructor(public dialogRef: MatDialogRef<ConfigureAccountsDialogComponent>, private session: SessionService) {
    const now = new Date();
    this.syncStartDate = new Date(now.getFullYear(), now.getMonth(), 1);
  }

  get syncStartDateIso(): string {
    return this.syncStartDate.toISOString();
  }
}
