import { Component, OnInit, Input, ViewEncapsulation, OnChanges, Output, EventEmitter } from '@angular/core';
import { DateTime } from 'luxon';
import { map, catchError } from 'rxjs/operators';
import { AnalyticsService } from '../../services/analytics.service';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { NgForm } from '@angular/forms';
import { Account, Credential } from '../../types';
import {
  EVENT_ENABLED_ACCOUNT_SYNCING,
  EVENT_DISABLED_ACCOUNT_SYNCING,
  EVENT_ADDED_ACCOUNT_NICKNAME,
} from '../../analytics/events';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountDetailsComponent implements OnInit, OnChanges {
  @Input() account: Account;
  @Input() credential: Credential;
  @Output() accountSaved = new EventEmitter();
  saving = false;

  minimumDate$ = this.session.session$.pipe(
    map(session => DateTime.fromISO(session.account.startMonthYear).toJSDate()),
  );
  nickname = '';
  accountModel: Partial<Account> = {};

  get negativeBalance(): boolean {
    return this.account.type === 'loan' || this.account.type === 'credit';
  }

  constructor(private analytics: AnalyticsService, private backend: BackendService, private session: SessionService) {}

  ngOnInit() {
    this.updateModel();
  }

  ngOnChanges() {
    this.updateModel();
  }

  addAccountDetailsSave(form: NgForm) {
    if (!form.value.syncStartDate) form.value.syncStartDate = this.account.syncStartDate;
    this.saving = true;
    this.backend
      .request({
        type: 'update',
        apiRoute: `credential/${this.credential.id}/account`,
        data: { id: this.account.id, ...form.value },
      })
      .subscribe({
        next: updatedAccount => {
          if (updatedAccount.nickname !== this.account.nickname) {
            this.account.nickname = updatedAccount.nickname;
            this.analytics.sendEvent(EVENT_ADDED_ACCOUNT_NICKNAME);
          }
          if (this.account.syncDisabled !== updatedAccount.syncDisabled) {
            if (updatedAccount.syncDisabled) this.analytics.sendEvent(EVENT_DISABLED_ACCOUNT_SYNCING);
            else this.analytics.sendEvent(EVENT_ENABLED_ACCOUNT_SYNCING);

            this.account.syncDisabled = updatedAccount.syncDisabled;
          }
          this.account.syncStartDate = updatedAccount.syncStartDate;
          this.accountSaved.emit(1);
        },
        error: err => (this.saving = false),
      });
  }

  buildSyncStartDate() {
    if (!this.account || !this.account.syncStartDate) return DateTime.fromJSDate(new Date()).toString();
    return DateTime.fromString(this.account.syncStartDate, 'yyyy-MM-dd').toString();
  }

  updateModel() {
    this.accountModel = { ...this.account, syncStartDate: this.buildSyncStartDate() };
  }
}
