<form #dialogForm="ngForm" [ngClass]="data.formType" (ngSubmit)="onSubmit(dialogForm)" autocomplete="off"
  [ngSwitch]="data.formType">

  <h4 mat-dialog-title *ngIf="data.title">
    {{ data.title }}
  </h4>



  <mat-dialog-content>
    <p class="message" *ngIf="data.message" [innerHTML]="data.message"></p>
    <mat-form-field *ngSwitchCase="'textInput'" floatLabel="auto" appearance="outline">
      <mat-label *ngIf="data.form.placeholder">{{ data.form.placeholder }}</mat-label>
      <input matInput name="inputValue" type="text" [placeholder]="data.form.placeholder"
        [(ngModel)]="data.form.inputValue" [attr.maxlength]="data.form.maxlength" required />
    </mat-form-field>
    <mat-form-field *ngSwitchCase="'textArea'" floatLabel="auto" appearance="outline">
      <mat-label *ngIf="data.form.placeholder">{{ data.form.placeholder }}</mat-label>
      <textarea matInput name="inputValue" type="text" [placeholder]="data.form.placeholder"
        [(ngModel)]="data.form.inputValue" [attr.maxlength]="data.form.maxlength" required></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="dialog-actions">
      <button type="button" (click)="onCancel()" mat-button>{{ data.cancelText || 'Cancel' }}</button>
      <button type="submit" mat-flat-button color="primary"
        [disabled]="!dialogForm.form.dirty || !dialogForm.form.valid">
        {{ data.submitText || 'Submit' }}
      </button>
    </div>
  </mat-dialog-actions>
</form>