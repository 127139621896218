import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnDestroy,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Bucket } from '../../types';
import { BucketService } from '../../services/bucket.service';
import { Subject, Observable, from, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-bucket-summary',
  templateUrl: './bucket-summary.component.html',
  styleUrls: ['./bucket-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BucketSummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() bucket: Bucket;
  budgetAmount: number;
  monthYear: string;
  _amountChanged: Subject<number> = new Subject();
  amountChanged$: Observable<number> = from(this._amountChanged).pipe(distinctUntilChanged(), debounceTime(200));
  amountChangedSubscription: Subscription;

  constructor(private bucketService: BucketService) {}

  ngOnInit() {
    this.budgetAmount = this.bucket.amount;
    this.monthYear = this.bucket.monthYear;
    this.amountChangedSubscription = this.amountChanged$
      .pipe(map(amount => (amount ? amount : 0)))
      // we must subscribe to this http request directly or the http request may be canceled when the amountChangedSubscription is destroyed
      .subscribe(amount => this.bucketService.editBucket$({ ...this.bucket, amount }).subscribe());
  }

  ngOnChanges() {
    if (this.bucket.monthYear !== this.monthYear) {
      this.budgetAmount = this.bucket.amount;
      this.monthYear = this.bucket.monthYear;
    }
  }

  onBudgetAmountFocus() {
    if (this.budgetAmount === 0) this.budgetAmount = null;
  }

  onBudgetAmountFocusOut() {
    if (this.budgetAmount === null) this.budgetAmount = 0;
  }

  get bucketTotal(): number {
    if (this.bucket.type === 'INCOME') return this.bucket.total;
    return this.bucket.total * -1;
  }

  onBudgetAmountChange(value: number) {
    this.budgetAmount = value;
    this._amountChanged.next(value);
  }

  ngOnDestroy() {
    this.amountChangedSubscription.unsubscribe();
  }
}
