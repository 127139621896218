import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { DateTime } from 'luxon';

@Directive({
  selector: '[minDate][formControlName],[minDate][formControl],[minDate][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinDateDirective, multi: true}]
})
export class MinDateDirective implements Validator {
  @Input()
  minDate: Date;
  
  validate(c: FormControl): {[key: string]: any} {
    let value = c.value;
    if(!this.minDate || !value) {
      return null;
    }
    return this.formatDate(value) < this.formatDate(this.minDate) ? {"minDate": true} : null;
  }

  private formatDate(date) {
    if(date instanceof Date) {
      return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
    }
    return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
  }
} 