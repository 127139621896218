import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { BackendService } from '../../services/backend.service';
import { Account } from '../../types';
import { AnalyticsService } from '../../services/analytics.service';
import {
  EVENT_ADDED_ACCOUNT_NICKNAME,
  EVENT_DISABLED_ACCOUNT_SYNCING,
  EVENT_ENABLED_ACCOUNT_SYNCING,
} from '../../analytics/events';
import { NgForm } from '@angular/forms';
import { SessionService } from '../../services/session.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss'],
})
export class AccountsListComponent {
  @Input() accounts: Account[];
  @Input() credentialId: string;

  minimumDate$ = this.session.session$.pipe(
    map(session => DateTime.fromISO(session.account.startMonthYear).toJSDate()),
  );
  nickname = '';
  expanded: string;
  accountModel: Partial<Account> = {};

  constructor(private analytics: AnalyticsService, private backend: BackendService, private session: SessionService) {}

  openAccount(account: Account) {
    this.accountModel = { ...account };
    this.expanded = account.id;
  }

  addAccountDetailsSave(credentialId: string, account: Account, form: NgForm) {
    delete this.expanded;
    if (!form.value.syncStartDate) form.value.syncStartDate = account.syncStartDate;
    this.backend
      .request({
        type: 'update',
        apiRoute: `credential/${credentialId}/account`,
        data: { id: account.id, ...form.value },
      })
      .subscribe(updatedAccount => {
        if (updatedAccount.nickname !== account.nickname) {
          account.nickname = updatedAccount.nickname;
          this.analytics.sendEvent(EVENT_ADDED_ACCOUNT_NICKNAME);
        }
        if (account.syncDisabled !== updatedAccount.syncDisabled) {
          if (updatedAccount.syncDisabled) this.analytics.sendEvent(EVENT_DISABLED_ACCOUNT_SYNCING);
          else this.analytics.sendEvent(EVENT_ENABLED_ACCOUNT_SYNCING);

          account.syncDisabled = updatedAccount.syncDisabled;
        }
        account.syncStartDate = updatedAccount.syncStartDate;
      });
  }
}
