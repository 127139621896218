import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { Label } from '../../types/label';
import { BucketService } from '../../services/bucket.service';
import { take, map, switchMap, delay, tap, shareReplay } from 'rxjs/operators';
import { Bucket } from '../../types';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-bucket-typeahead',
  templateUrl: './bucket-typeahead.component.html',
  styleUrls: ['./bucket-typeahead.component.scss'],
})
export class BucketTypeaheadComponent implements OnInit {
  @Input() selectedBucketIds: string[] = [];
  @Input() placeholder = 'Search Budgets';
  @Input() keepOpenOnSelect = false;
  @Output() selectedBucketIdsChange = new EventEmitter<string[]>();
  _filteredBuckets: ReplaySubject<Bucket[]> = new ReplaySubject(1);
  filteredBuckets$: Observable<Bucket[]> = this._filteredBuckets.asObservable();
  @ViewChild('bucketInput') bucketInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  currentBucketText = '';

  allBuckets$ = this.bucketService.allBucketsRequest$.pipe(
    map(page => page.data),
    shareReplay(1),
  );

  constructor(private bucketService: BucketService) {
    this.allBuckets$.pipe(take(1)).subscribe(l => this._filteredBuckets.next(l));
  }

  bucketMap$: Observable<{}> = this.allBuckets$.pipe(
    map(labels =>
      labels.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    ),
  );

  inputChanged() {
    this.allBuckets$.pipe(take(1)).subscribe(buckets => {
      this._filteredBuckets.next(
        buckets.filter(
          bucket =>
            (this.currentBucketText === '' ||
              bucket.name.toLowerCase().indexOf(this.currentBucketText.toLowerCase()) !== -1) &&
            this.selectedBucketIds.indexOf(bucket.id) === -1,
        ),
      );
    });
  }

  private addBucketId(bucketId: string): void {
    this.selectedBucketIds.push(bucketId);
    this.selectedBucketIdsChange.emit(this.selectedBucketIds);
  }

  private removeBucketId(bucketId: string) {
    const index = this.selectedBucketIds.indexOf(bucketId);
    if (index > -1) this.selectedBucketIds.splice(index, 1);
    this.selectedBucketIdsChange.emit(this.selectedBucketIds);
  }

  private clearInput() {
    this.currentBucketText = '';
    this.bucketInput.nativeElement.value = '';
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const selectedName = event.option.value.trim().toLowerCase();
    this.allBuckets$.pipe(take(1)).subscribe(buckets => {
      this.clearInput();
      const bucket = buckets.find(b => b.name.trim().toLowerCase() === selectedName);
      if (bucket && this.selectedBucketIds.indexOf(bucket.id) === -1) {
        this.addBucketId(bucket.id);
        this.inputChanged();
        if (this.keepOpenOnSelect) {
          setTimeout(() => this.autoTrigger.openPanel(), 0);
        }
      }
    });
  }

  remove(bucketId: string): void {
    if (bucketId) {
      this.removeBucketId(bucketId);
    }
  }

  ngOnInit() {}
}
