<div #element class="transaction-list-item-wrapper" [ngSwitch]="transaction._objectType">
  <div class="transaction-list-item transfer" *ngSwitchCase="'bucketFundTransfer'">
    <div class="flex-row">
      <button
        mat-icon-button
        class="transaction-list-item-transfer-delete"
        (click)="openDeleteTransferModal(transaction)"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <div class="transaction-content">
        <p class="transaction-list-item-transfer-info" *ngIf="transaction.netValue > 0">
          Transfer from {{ transaction.transferBucket.name }}
        </p>
        <p class="transaction-list-item-transfer-info" *ngIf="transaction.netValue < 0">
          Transfer to {{ transaction.transferBucket.name }}
        </p>

        <div class="transaction-list-item-amount" [ngClass]="{ good: transaction.netValue > 0 }">
          {{ transaction.amount | currency }}
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'transaction'" class="transaction-list-item transaction">
    <mat-button-toggle
      class="transaction-list-item-select"
      type="button"
      [checked]="isSelected"
      [value]="transaction"
      [disabled]="transaction.locked?.value"
      (change)="transactionSelectionToggled($event)"
    >
      <mat-icon *ngIf="!transaction.locked?.value && isSelected" class="transaction-list-item-checkbox">
        check_box
      </mat-icon>
      <mat-icon *ngIf="!transaction.locked?.value && !isSelected" class="transaction-list-item-checkbox">
        check_box_outline_blank
      </mat-icon>
      <!-- <mat-checkbox
        *ngIf="!transaction.locked?.value"
        [checked]="isSelected"
        class="transaction-list-item-checkbox"
      ></mat-checkbox> -->

      <button
        mat-icon-button
        disableRipple="true"
        class="transaction-list-item-locked"
        *ngIf="transaction.locked?.value"
        (click)="whyLocked(transaction.locked.reason)"
      >
        <mat-icon>lock</mat-icon>
      </button>
    </mat-button-toggle>
    <button
      class="transaction-list-item-button"
      disableRipple="true"
      mat-button
      (click)="toggleTransactionListEditing()"
    >
      <mat-icon class="transaction-list-item-expand">expand_more</mat-icon>

      <transaction-list-item-content
        [transaction]="transaction"
        [transactionDataSource]="transactionDataSource"
        (selectedBucketChange)="selectedBucketChange($event)"
      ></transaction-list-item-content>
    </button>

    <edit-transaction-list-item
      *ngIf="isEditing$ | async"
      [@inOutAnimation]
      (closeEditTransaction)="closeTransactionListEditing()"
      [transaction]="transaction"
      [transactionDataSource]="transactionDataSource"
    >
    </edit-transaction-list-item>
  </div>
</div>
