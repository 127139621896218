import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DrawerService } from 'src/app/services/drawer.service';
import { GhostPost } from 'src/app/types/documentation';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('documentation') documentationRef: ElementRef<Element>;
  @Input() public content: GhostPost;

  links: NodeListOf<HTMLAnchorElement>;

  constructor(private drawerService: DrawerService, private session: SessionService) {}

  ngAfterViewInit() {
    const documentationElement = this.documentationRef.nativeElement;
    documentationElement.innerHTML = this.content.html;
    this.setupLinks(documentationElement);
  }

  setupLinks(documentationElement: Element) {
    this.links = documentationElement.querySelectorAll('a');
    this.links.forEach(a => {
      a.addEventListener('click', this.onLinkClick.bind(this));
      a.href = this.addJWTToLinks(a.href);
    });
  }

  addJWTToLinks(href) {
    if (!href.includes('browser.budgets.money')) return href;
    const searchString = href.split('?')[1];
    const params = new URLSearchParams(searchString);
    params.set('jwt', this.session.storedSession.jwt);
    return `${href}?${params.toString()}`;
  }

  onLinkClick(event) {
    const searchString = 'https://www.budgets.money/';
    if (!event.target.href.includes(searchString)) return false;

    // Required to prevent href
    event.preventDefault();

    const searchStringLength = searchString.length;
    const startIndex = event.target.href.indexOf(searchString) + searchStringLength;
    const lastIndex = event.target.href.indexOf('/', startIndex);
    const slug = event.target.href.substring(startIndex, lastIndex === -1 ? event.target.href.length : lastIndex);
    this.drawerService.openDocumentationDrawer(slug);
  }

  ngOnDestroy() {
    this.links.forEach(a => a.removeEventListener('click', this.onLinkClick.bind(this)));
  }
}
