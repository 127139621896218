import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ScriptService } from './script.service';
import { shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PlaidService {
  private loadPlaid$ = this.script
    .loadBySource('https://cdn.plaid.com/link/v2/stable/link-initialize.js')
    .pipe(shareReplay(1));

  constructor(private script: ScriptService) {}

  initializePlaid$(options?: Partial<PlaidCreateConfig> & { token: string }): Observable<PlaidLinkHandler> {
    const defaultOptions: Omit<PlaidCreateConfig, 'token'> = {
      clientName: 'Budgets',
      env: environment.plaidEnvironment as PlaidEnvironment,
      product: ['transactions'],
      // Optional, use webhooks to get transaction and error updates
      onLoad() {
        // Optional, called when Link loads
      },
      onSuccess: (publicToken, metadata) => {
        // Send the public_token to your app server.
        // The metadata object contains info about the institution the
        // user selected and the account ID, if the Account Select view
        // is enabled.
      },
      onExit(err, metadata) {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error prior to exiting.
        }
        // metadata contains information about the institution
        // that the user selected and the most recent API request IDs.
        // Storing this information can be helpful for support.
      },
    };

    return this.loadPlaid$.pipe(map(() => Plaid.create({ ...defaultOptions, ...options })));
  }
}
