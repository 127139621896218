import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor() {}
  isAndroidApp = /Budgets-Android/.test(navigator.userAgent);
  isIosApp = /Budgets-ios/.test(navigator.userAgent);
  canHaveBottombar = (this.isAndroidApp || this.isIosApp) && window.innerWidth >= 600;
}
