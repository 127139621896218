import { Component, OnInit, Inject } from '@angular/core';
import { DrawerService } from '../../../services/drawer.service';
import { DRAWER_DATA } from 'src/app/shared/injector-tokens';

@Component({
  selector: 'app-add-bucket-drawer-wrapper',
  templateUrl: './add-bucket-drawer-wrapper.component.html',
  styleUrls: ['./add-bucket-drawer-wrapper.component.scss'],
})
export class AddBucketDrawerWrapperComponent implements OnInit {
  categoryId: string;
  constructor(@Inject(DRAWER_DATA) private data: any, public drawerService: DrawerService) {
    this.categoryId = this.data.categoryId;
  }

  ngOnInit() {}
}
