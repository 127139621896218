export const appIcons = [
  'logo',
  'sort',
  'list',
  'money-check',
  'profile',
  'piggy-bank',
  'credit-card',
  'money-bill',
  'facebook-f',
  'cogs',
  'exchange',
  'circle-arrow-up',
  'pencil',
  'question-circle',
  'transfer-out',
  'transfer-in',
  'envelope',
  'donut-chart',
];

export const budgetIcons = [
  'salary-duotone',
  'other-income-duotone',
  'apartment-duotone',
  'home-duotone',
  'shopping-basket-duotone',
  'utensils-duotone',
  'glass-cheers-duotone',
  'coffee-togo-duotone',
  'car-duotone',
  'bus-duotone',
  'baby-carriage-duotone',
  'paw-duotone',
  'bath-duotone',
  'tshirt',
  'dumbbell-duotone',
  'box-full-duotone',
  'user-friends-duotone',
  'mobile-duotone',
  'popcorn-duotone',
  'university-duotone',
  'cars-duotone',
  'credit-card-duotone',
  'bank-duotone',
  'church-duotone',
  'gifts-duotone',
  'donate-duotone',
  'first-aid-duotone',
  'car-garage-duotone',
  'rings-wedding-duotone',
  'phone-laptop-duotone',
  'baby-duotone',
  'globe-duotone',
  'graduation-cap-duotone',
  'chart-line-duotone',
  'coins-duotone',
];
