import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DrawerService } from '../../services/drawer.service';
import { CategoryPage } from '../../types';

@Component({
  selector: 'app-budget-over-under',
  templateUrl: './budget-over-under.component.html',
  styleUrls: ['./budget-over-under.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetOverUnderComponent implements OnInit {
  @Input() categoryPage: CategoryPage;

  get amount() {
    return Math.abs(this.categoryPage.budgeted.INCOME - this.categoryPage.budgeted.BUDGET);
  }

  constructor(public drawerService: DrawerService) {}

  ngOnInit() {}
}
