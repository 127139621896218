import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { AccountService } from '../../services/account.service';
import { DrawerService } from 'src/app/services/drawer.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-accounts-page',
  templateUrl: './accounts-page.component.html',
  styleUrls: ['./accounts-page.component.scss'],
})
export class AccountsPageComponent implements OnInit {
  openingPlaid = false;
  constructor(
    public accountService: AccountService,
    private nav: NavigationService,
    private drawer: DrawerService,
    private route: ActivatedRoute,
  ) {}
  ngOnInit() {
    this.nav.title = 'accounts';
    this.accountService.refreshAccountData$().subscribe();
    if (this.route.snapshot.queryParamMap.get('oauth_state_id')) {
      this.openPlaid(window.location.href);
    }
  }

  openPlaid(receivedRedirectUri?: string) {
    this.openingPlaid = true;
    this.accountService.initializePlaidLink$({ receivedRedirectUri }).subscribe(plaid => {
      plaid.open();
      setTimeout(() => (this.openingPlaid = false), 1000);
    });
  }

  openCustomAccount() {
    this.drawer.openAddCustomAccountDrawer();
  }
}
