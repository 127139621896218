import { Injectable } from '@angular/core';
import events from '../analytics/events';
import * as LogRocket from 'logrocket';
import { User } from '../types';

interface DataLayerWindow extends Window {
  dataLayer: any;
}
declare let window: DataLayerWindow;

interface gtagEvent {
  event: string;
  category: string;
  label: string;
  value?: number;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor() {}

  sendEvent(eventLabel: string) {
    const { event: eventName, category: eventCategory, value: eventValue } = events[eventLabel];
    return this.gtag({
      event: `bud.${eventName}`,
      eventName,
      eventCategory,
      eventLabel,
      eventValue,
    });
  }

  setUser(user: User) {
    if (user) {
      const { id, ...rest } = user;
      console.log('user being set: ', id, user.email);
      LogRocket.identify(id, { ...rest });
    }
  }

  private gtag(...args: any[]) {
    console.log('analytics sent', ...args);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(...args);
  }
}
